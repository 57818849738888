import React, { useContext, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Dropdown, Input, Menu } from "antd";
import { useAllClasses } from "../../../../queryHooks";
import { ReactComponent as DownCircle } from "../../../../static/images/clipIcon/downCircle.svg";
import { useMutation } from "react-query";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";

export const exportPdf = async ({ olddata, setLoading }) => {
  const instance = await getAxiosTokenInstance();

  let data = {
    collegeId: olddata.collegeId,
    classId: olddata.classId,
    year: olddata.year
  }
  console.log(data);

  try {
    const resp = await instance.post(`/college/reportGeneration/idCardPrinting`, data, {
      responseType: "blob",
    });

    if (resp.data.statusCode === 400) {
      setLoading(false)
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    setLoading(false)
    throw new Error(

      error?.response?.data?.message || error.message || "API Error"
    );
  }
};




export const IdCardPrinting = ({ onClose }) => {


  const { collegeId } = useContext(FirebaseContext);



  const exportPdfMutation = useMutation(exportPdf);

  const [loading, setLoading] = useState(false);


  const onExportExam = async (year) => {
    setLoading(true)

    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let olddata = {
        collegeId,
        year
      };


      link.download = 'Exam Reg Suport';
      const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
      setLoading(false)

      onClose();

    } catch (e) { }
  };


  const onTemplateExport = (event) => {
    event?.preventDefault();
    const target = event?.currentTarget;
    const year = target?.acc1?.value
    onExportExam(year);
  };







  const FormField = ({ labelString, type, placeholder, ...props }) => {
    return (
      <div className={classNames.formField}>
        <label>{labelString}</label>
        <Input type={type} placeholder={placeholder} required {...props} />
      </div>
    );
  };
  return (
    <div className={classNames.container}>

      {loading ? <LoadingAnimation dark /> :
        <form className={classNames.modal} onSubmit={onTemplateExport}>

          <div className={classNames.listContainer}>

            <div className={classNames.list}>

              <label className={classNames.heading}>Admission Number</label>
              
              <FormField
                name="acc1"
                type={'text'}
                multyline={true}
                placeholder="Admission No"
                className={classNames.list1}
                style={{
                  width: "840px",
                  height: "150px",
                  font: 24,

                }}
              />
            </div>
            <button className={classNames.button} type="submit">
              Download
            </button>
          </div>
          <div className={`${classNames.imageContainer} ${classNames.columnDisplay}`}>
            <Button className={classNames.closeButton} onClick={onClose}>
              Close
            </Button>
          </div>
        </form>}
    </div>
  );
};
