import axios from "axios";
import { NECTOTS_API_URL } from "../config";

const getAxiosInstance = () =>
  axios.create({
    baseURL: NECTOTS_API_URL,
  });

const getAxiosTokenInstance = async () => {
  const token = localStorage.getItem("userToken");

  return axios.create({
    baseURL: NECTOTS_API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export { getAxiosInstance, getAxiosTokenInstance };
