import React, { useContext, useEffect, useState } from "react";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import LoadingAnimation from "../../components/LoadingAnimation";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useAccountsHeads, useAllClasses } from "../../queryHooks";
import { getFeeStructure } from "../../pages/college/ApiServices";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars";
import { v4 as uuidv4 } from "uuid";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { Header, Main } from "../../NecttosComp/Layout/Layout";

const createRadomId = (prefix = "") => {
  const uuid = uuidv4()?.toUpperCase();
  const parsedUuid = uuid.replace("-", "");
  return `${prefix ? `${prefix}-` : ""}${parsedUuid}`;
};

const feesTypess = [
  { name: "Goverment Fees", key: "TuitionFee" },
  { name: "Examination Fees", key: "ExamFee" },
  { name: "Other Fees", key: "Others" },
];
const years = [
  { name: "First Year", key: "1y" },
  { name: "Second Year", key: "2y" },
  { name: "Third Year", key: "3y" },
  { name: "Fourth year", key: "4y" },
  { name: "Fifth Year", key: "5y" },
  { name: "Year not specified", key: "yearNotSpecified" },
];
function sumWithKey(data, key) {
  return data?.reduce((x, y) => x + y[key], 0);
}

function FeeStructure({ onClose, classData, userId, loadFee }) {
  const { collegeId, user } = useContext(FirebaseContext);
  const allClasses = useAllClasses({ needAll: true, classOnly: false });
  const classList = allClasses?.data || [];
  const accounts = useAccountsHeads();
  const mapFeeAcounts = accounts?.data?.subAccounts || [];
  const mapMainAccounts = accounts?.data?.mainAccounts || [];
  const feeAccountMap = new Map(mapFeeAcounts?.map((account) => [account.value, account]));
  const mainAccountMap = new Map(mapMainAccounts?.map((account) => [account.value, account]));
  const [isLoading, setIsLoading] = useState(false);
  const [stateConst, setStateConst] = useState({ list: { dues: [] } });
  const [classId, setClassId] = useState(classData?.classId);
  const [classIdCopy, setClassIdCopy] = useState();

  const [state, setState] = useState({
    _id: "",
    title: "",
  });

  const [state2, setState2] = useState({});

  let { data } = useQuery(["getFeeStructure4345", classId, classData?.classId], getFeeStructure(collegeId, classId), { keepPreviousData: true });
  let { data: data2 } = useQuery(["getFeeStructure23454", classIdCopy], getFeeStructure(collegeId, classIdCopy), { keepPreviousData: true });

  useEffect(() => {
    if (state?.dueId) {
      const feeAccount = feeAccountMap.get(state?.dueId);
      const mainAccount = mainAccountMap.get(feeAccount?.mainId);
      if (feeAccount && mainAccount) setState2({ ...state2, title: feeAccount?.label, mainTitle: mainAccount?.label });
    }
  }, [state?.dueId]);

  useEffect(() => {
    if (state2?.title) {
      setState({ ...state, title: state2.title, mainTitle: state2.mainTitle });
    }
  }, [state2?.title]);
  const dataVal = { list: { dues: [] } };

  const setConst = () => {
    if (!data?.list?.dues) {
      setStateConst({ list: { dues: [] } });
      setState((prev) => ({
        ...prev,
        yearTitle: "First Year",
        year: "1y",
        "1y": true,
        "2y": true,
        "3y": true,
        "4y": true,
      }));
      return;
    }
    const updatedDues = data?.list?.dues.map((item) => {
      const feeAccount = feeAccountMap.get(item?.accountId);
      const mainAccount = mainAccountMap.get(feeAccount?.mainId);
      return {
        ...item,
        subAccountName: feeAccount?.label,
        mainAccountName: mainAccount?.label,
      };
    });
    setStateConst({ list: { dues: updatedDues } });
    setState((prev) => ({
      ...prev,
      yearTitle: "First Year",
      year: "1y",
      "1y": true,
      "2y": true,
      "3y": true,
      "4y": true,
    }));
  };

  useEffect(() => {
    const data = mapFeeAcounts.find((x) => x.value === state?.dueid);
    if (data) {
      setState((prevState) => ({ ...prevState, amount: parseInt(data?.amount) }));
    }
  }, [state.dueid]);

  const copyConst = () => {
    setStateConst({ ...data2 });
    setState((prev) => ({
      ...prev,
      yearTitle: "First Year",
      ["1y"]: true,
      ["2y"]: true,
      ["3y"]: true,
      ["4y"]: true,
    }));
  };

  const feesClearence = async () => {
    if (state?._id?.length > 20) {
      stateConst.list.dues.forEach((x) => {
        if (x._id === state?._id) {
          x.feesType = state?.feesType;
          x.year = state?.feesYear;
          x.amount = parseInt(state?.amount);
          x.balance = parseInt(state?.amount);
          x.dueDate = state?.date;
          x.accountId = state?.dueId;
          x.title = state?.title;
          x.subAccountName = state?.title;
          x.mainAccountName = state?.mainTitle;
        }
      });
      setState({
        yearTitle: "First Year",
        ["1y"]: true,
        ["2y"]: true,
        ["3y"]: true,
        ["4y"]: true,
      });
    } else {
      stateConst.list.dues.push({
        feeType: state?.feesType,
        year: state?.feesYear,
        amount: parseInt(state?.amount),
        paid: 0,
        balance: parseInt(state?.amount),
        dueDate: new Date(state?.date),
        accountId: state?.dueId,
        title: state?.title,
        subAccountName: state?.title,
        mainAccountName: state?.mainTitle,
        _id: createRadomId("FD"),
        createdOn: new Date(),
        createdBy: user?.uid,
      });
      setState({
        feesType: state?.feesType,
        feesYear: state?.feesYear,
        date: state?.date,
        yearTitle: "First Year",
        ["1y"]: true,
        ["2y"]: true,
        ["3y"]: true,
        ["4y"]: true,
      });
    }
    setStateConst({ ...stateConst });
  };

  const deleteItems = async (_id) => {
    setStateConst((prevState) => {
      const newDues = prevState.list.dues.filter((due) => due._id !== _id);
      return { ...prevState, list: { dues: newDues } };
    });
  };

  const onAddFees = async () => {
    try {
      setIsLoading(true);
      let filterData = [];
      years?.forEach((item) => {
        if (state[item.key]) {
          filterData.push(item.key);
        }
      });
      const instance = await getAxiosTokenInstance();
      let resp;
      resp = await instance.post("/college/action/postFeeStructure", {
        ...(loadFee ? { dues: stateConst?.list?.dues?.filter((x) => filterData.includes(x.year)) } : { dues: stateConst?.list?.dues }),
        collegeId,
        loadFee,
        userId,
        classId: classId,
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setClassId("");
        setState({
          ...state,
          feesType: "",
          titleOfFee: "",
          description: "",
          dueId: "",
          isStudent: true,
          amount: "",
          dueDate: "",
          date: "",
          recieptNo: "",
          _id: "",
        });
        onClose();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message || "API Error");
    } finally {
      setIsLoading(false);
    }
  };

  let feeDataStructure = [
    {
      title: "First Year",
      value: stateConst?.list?.dues?.filter((x) => x.year === "1y"),
      sum: sumWithKey(
        stateConst?.list?.dues?.filter((x) => x.year === "1y"),
        "amount"
      ),
      year: "1y",
    },
    {
      title: "Second Year",
      value: stateConst?.list?.dues?.filter((x) => x.year === "2y"),
      sum: sumWithKey(
        stateConst?.list?.dues?.filter((x) => x.year === "2y"),
        "amount"
      ),
      year: "2y",
    },
    {
      title: "Third Year",
      value: stateConst?.list?.dues?.filter((x) => x.year === "3y"),
      sum: sumWithKey(
        stateConst?.list?.dues?.filter((x) => x.year === "3y"),
        "amount"
      ),
      year: "3y",
    },
    {
      title: "Fourth Year",
      value: stateConst?.list?.dues?.filter((x) => x.year === "4y"),
      sum: sumWithKey(
        stateConst?.list?.dues?.filter((x) => x.year === "4y"),
        "amount"
      ),
      year: "4y",
    },
    {
      title: "Fifth Year",
      value: stateConst?.list?.dues?.filter((x) => x.year === "5y"),
      sum: sumWithKey(
        stateConst?.list?.dues?.filter((x) => x.year === "5y"),
        "amount"
      ),
      year: "5y",
    },
    {
      title: "Year not Specified",
      value: stateConst?.list?.dues?.filter((x) => x.year === undefined),
      sum: sumWithKey(
        stateConst?.list?.dues?.filter((x) => x.year === undefined),
        "amount"
      ),
      year: undefined,
    },
  ];

  const updateAllDueDates = () => {
    const dataForClear = stateConst.list.dues;
    dataForClear.forEach((item) => {
      if (item.year === state?.year) {
        item.dueDate = new Date(state?.newDueDate)?.toUTCString();
      }
    });

    setStateConst({ list: { dues: dataForClear } });
  };

  return (
    <Main title="Fees Structure Management" width="100%" height="100%">
      <Header>
        <div className="flex-grow ml-[200px]">
          <div className="flex justify-center  flex-wrap gap-2 w-full">
            {feeDataStructure?.map((yearData, index) => (
              <div className="flex items-center p-1 bg-gray-100 rounded-lg m-1 mx-6">
                <label className="mr-2 m-1">
                  <input
                    type="checkbox"
                    className="h-5 w-5 text-blue-600"
                    checked={state[yearData.year]} // Using checked for controlled component
                    onChange={() => setState((prev) => ({ ...prev, yearTitle: yearData.title, year: yearData.year, [yearData.year]: !prev[yearData.year] }))}
                  />
                </label>
                <div className="text-blue-700 cursor-pointer px-2 hover:text-blue-800" onClick={() => setState((prev) => ({ ...prev, yearTitle: yearData.title, year: yearData.year, [yearData.year]: !prev[yearData.year] }))}>
                  {yearData?.title}: {yearData?.sum}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex">
          <Button
            width="100%"
            color="#fcb603"
            onClick={() => {
              setState("");
              setStateConst("");
            }}
          >
            Clear
          </Button>
          <Button width="100px" type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>
      <div className="flex flex-col md:flex-row w-full h-full overflow-y-auto">
        <div className="w-full md:w-1/5 p-4">
          {loadFee ? (
            <div style={{ fontSize: 20, margin: 10 }}>{classData.className}</div>
          ) : (
            <>
              <Input width="100%" didntShowKey fieldName="Select Class to Show Fee Structure" optionDisplay="className" optionKey="_id" state={classId} setState={setClassId} options={classList} type="drop" />
            </>
          )}
          <Button
            width="100%"
            color="#007BFF"
            onClick={() => {
              setConst();
            }}
          >
            Show Fee (SET)
          </Button>
          <Input width="100%" didntShowKey fieldName="Copy Fee Structure from Other Class" optionDisplay="className" optionKey="_id" state={classIdCopy} setState={setClassIdCopy} options={classList} type="drop" />
          <Button
            width="100%"
            color="#357574"
            onClick={() => {
              copyConst();
            }}
          >
            Copy Now
          </Button>
          <div className="">
            {state?.year && (
              <>
                <div style={{ fontSize: 15, marginTop: "25%", marginLeft: "3%" }}>Change All {state?.yearTitle} Dues Date</div>
                <div>
                  <Input width="100%" fieldName="New Due Date" returnKey="newDueDate" state={state} setState={setState} type="date" />
                  <Button color="green" onClick={updateAllDueDates}>
                    Update All {state?.yearTitle} Due Dates
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-full md:w-3/5 p-4">
          <Scrollbars>
            {feeDataStructure.map(
              (yearData, index) =>
                state[yearData.year] && (
                  <div key={index} style={{ width: "100%" }}>
                    <div className="bg-white rounded-lg mt-5">
                      <h2 className="text-lg font-semibold mb-2 ms-[7%] mt-2 text-[#000000fd]">
                        {yearData.title} : {yearData.sum}
                      </h2>
                    </div>
                    <div className="self-center">
                      <div className="overflow-x-auto">
                        <table className="min-w-[100%] justify-center self-center">
                          <thead>
                            <tr>
                              <th style={{ fontSize: "15px" }} className="text-center text-white  bg-black w-[10%] p-2">
                                Sl.No.
                              </th>
                              <th style={{ fontSize: "15px" }} className="text-center text-white  bg-black  w-[15%] p-2">
                                Heads
                              </th>
                              <th style={{ fontSize: "15px" }} className="text-center text-white  bg-black w-[20%] p-2">
                                Title
                              </th>
                              <th style={{ fontSize: "15px" }} className="text-center text-white  bg-black  w-[15%] p-2">
                                Amount
                              </th>
                              <th style={{ fontSize: "15px" }} className="text-center text-white  bg-black  w-[20%] p-2">
                                Due Date
                              </th>
                              <th style={{ fontSize: "15px" }} className="text-center text-white  bg-black  w-[20%] p-2">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {yearData?.value?.map((item, idx) => (
                              <tr key={idx} className={`hover:bg-white ${item.elective === "Discontinued" ? "bg-red-300" : "bg-white"}`}>
                                <td style={{ fontSize: "15px" }} className="p-2 bg-white w-[10%]">
                                  {idx + 1}
                                </td>
                                <td style={{ fontSize: "15px" }} className="p-2 bg-white w-[15%]">
                                  {item.mainAccountName?.slice(0, 12)}
                                </td>
                                <td style={{ fontSize: "15px" }} className="p-2 bg-white w-[20%]">
                                  {item.subAccountName}
                                </td>
                                <td style={{ fontSize: "15px" }} className="p-2 bg-white w-[15%]">
                                  {item.amount}
                                </td>
                                <td style={{ fontSize: "15px" }} className="p-2 bg-white w-[20%]">
                                  {moment(item.dueDate).format("DD-MM-YY")}
                                </td>
                                <td style={{ fontSize: "15px" }} className="p-2 bg-white w-[20%]">
                                  <div className="flex gap-2">
                                    <Button width="100%" color="#FF0000" onClick={() => deleteItems(item._id)} className="text-red-500 hover:text-red-700">
                                      Delete
                                    </Button>
                                    <Button
                                      width="100%"
                                      color="#007BFF"
                                      onClick={() => {
                                        setState((prevState) => ({
                                          ...prevState,
                                          feesType: item.feeType,
                                          feesTypeval: item.feeType,
                                          dueId: item.accountId,
                                          _id: item._id,
                                          feesYear: item.year,
                                          feesYearval: item.year,
                                          dueIdval: item.title,
                                          amount: item.amount,
                                          date: moment(item.dueDate).format("YYYY-MM-DD"),
                                          [item.year]: prevState[item.year],
                                        }));
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )
            )}
          </Scrollbars>
        </div>
        <div className="w-full md:w-1/5 p-4">
          {Array.isArray(stateConst?.list?.dues) && stateConst?.list?.dues?.length > -1 && (
            <>
              <div className="bg-indigo-600 ms-1 rounded-lg flex items-center justify-center">
                <h2 className="text-lg font-semibold mt-2 text-white">Add Dues Form</h2>
              </div>
              <Input width="100%" fieldName="Select Fees Type" returnKey="feesType" optionDisplay="name" optionKey="key" state={state} setState={setState} options={feesTypess} type="drop" />
              <Input width="100%" fieldName="Select Fees Year" returnKey="feesYear" optionDisplay="name" optionKey="key" state={state} setState={setState} options={years} type="drop" />
              <Input width="100%" fieldName="Due Date" returnKey="date" state={state} setState={setState} type="date" />

              <Input width="100%" fieldName="Select Account " returnKey="dueId" needCheck={true} didntShowKey optionDisplay="label" optionKey="value" state={state} setState={setState} options={mapFeeAcounts} type="drop" />
              <Input width="100%" fieldName="Amount" returnKey="amount" state={state} setState={setState} type="number" />
              <Button
                width="100%"
                type="save"
                onClick={() => {
                  feesClearence();
                }}
                children="Add Due"
              />
            </>
          )}
        </div>
      </div>
      <div className="self-end">
        <Button
          width="200px"
          type="save"
          onClick={() => {
            onAddFees();
          }}
        >
          Final Submit
        </Button>
      </div>
      {isLoading && (
        <div>
          <LoadingAnimation />
        </div>
      )}
    </Main>
  );
}
export default FeeStructure;
