import React, { useEffect, useState } from 'react';
import Register from './Register';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import ApplicationOpen from './ApplicationOpen';
import Button from '../../NecttosComp/Button/Button';
import { useParams } from 'react-router-dom';

const getCollegeDetails = async ({ queryKey }: any) => {
  const [_key, collegeId] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
      params: { collegeId },
    });

    if (data?.statusCode === 200) {
      return data.collegeData;
    } else {
      throw new Error(data?.message || 'Failed to fetch college details.');
    }
  } catch (error) {
    throw new Error('API Error');
  }
};

const NirmalaCollege: React.FC = () => {
  const initCollegeId = 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F';
  const [successLogin, setLogin] = useState<boolean>(false);
  const [internet, setInternet] = useState<boolean>(navigator.onLine);
  const [apply, setApply] = useState<boolean>(false);
  const { data: collegeData } = useQuery([`collegeData`, initCollegeId], getCollegeDetails);
  const [login, setLoginAll] = useState<boolean>(false);
  const params: { id: string; type: string } = useParams();

  useEffect(() => {
    const goOnline = () => {
      setInternet(true);
    };
    const goOffline = () => {
      setInternet(false);
    };
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  console.log({ internet });

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) setLogin(true);
    else setLogin(false);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleLogout3 = () => {
    setApply(false);
  };

  const CommonHeader = ({ iconColor, headerText }: any) => (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" className={`h-16 w-16 text-${iconColor}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <h1 className="text-xl font-semibold text-gray-800 mt-4">{headerText}</h1>
    </div>
  );

  const CommonMessages = () => (
    <>
      <p className="text-center text-gray-600 mt-2">Thank you for your interest in advancing your education with us!</p>
      <p className="text-center text-gray-600 mt-2">"Education is not the filling of a pail, but the lighting of a fire." — William Butler Yeats</p>
      <p className="text-center text-gray-600 mt-2">Your journey towards academic excellence starts now. Fill out the application form to take the next step in your educational career.</p>
      <p className="text-center text-gray-600 mt-4 font-medium">We appreciate your eagerness to join our community. Together, we'll strive for success in your academic pursuits!</p>
    </>
  );

  const ApplicationForm = ({ type }: any) => (
    <>
      <div className="flex flex-col items-center p-6 max-w-2xl mx-auto bg-white rounded-lg shadow-md">
        <CommonHeader iconColor={type === 'UG' ? 'green-500' : 'green-500'} headerText={type === 'UG' ? 'UG Application Form Is Now Open!' : 'PG Application Form Is Now Open!'} />
        <CommonMessages />
        <div className="flex flex-wrap justify-around mt-4">
          {type === 'UG' ? <Button width="100%" type="save" onClick={() => setApply(true)} children="UP Open Application Form" /> : <Button width="100%" type="save" onClick={() => setApply(true)} children="PG Application Form" />}
          <Button type="close" onClick={handleLogout} children="Logout" />
        </div>
      </div>
    </>
  );
  return !internet ? (
    <div className="text-gray-900 bg-gradient-to-r from-blue-500 to-blue-300 p-5 rounded-lg m-5 text-center shadow-md transition-all ease-in-out duration-300 font-nunito font-sans" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
      <strong className="block text-lg mb-2">You are offline.</strong>
      Please check your internet connection.
      <br />
      Thank you for your attempt.
      <br />
      Sincerely,
      <br />
      Nirmala College Muvatuppuzha!
    </div>
  ) : successLogin ? (
    <ApplicationOpen handleLogout={handleLogout} initCollegeId={initCollegeId} collegeData={collegeData} />
  ) : (
    <div className="flex flex-col justify-center items-center lg:w-full lg:h-screen bg-no-repeat bg-cover bg-center" style={{ backgroundImage: "url('https://nirmalacollege.ac.in/content/uploads/2024/03/nirmala-banner-3.jpg')" }}>
      <div
        className="flex justify-center min-h-screen overflow-scroll row"
        style={{
          backgroundImage: "url('https://nirmalacollege.ac.in/content/uploads/2024/03/nirmala-banner-3.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="bg-[#013e7f] h-12 shadow flex items-center justify-between">
          <div className="flex-1">
            <nav className="hidden md:flex space-x-10"></nav>
          </div>
          <div className="flex-1">
            <nav className="hidden md:flex justify-end space-x-10 p-3">
              <a href="/login" className="text-base font-medium text-white hover:text-gray-200">
                Login
              </a>
              <a href="https://nirmalacollege.ac.in/360-degree/" className="text-base font-medium text-white hover:text-gray-200">
                360°
              </a>
              <a href="https://nirmalacollege.ac.in/media-lab/" className="text-base font-medium text-white hover:text-gray-200">
                Media Lab
              </a>
              <a href="https://nirmalaalumni.org/index.htm" className="text-base font-medium text-white hover:text-gray-200">
                Alumni
              </a>
              <a href="https://nirmalacollege.ac.in/nirf/" className="text-base font-medium text-white hover:text-gray-200">
                NIRF
              </a>
            </nav>
          </div>
        </div>
        <div className="bg-white h-24 shadow">
          <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="https://nirmalacollege.ac.in/">
                  <img className="h-12 w-auto sm:h-16" src="https://nirmalacollege.ac.in/wp-content/uploads/2024/04/Logo-with-Autonomous.png" alt="Nirmala College Logo" />
                </a>
              </div>
              <nav className="hidden md:flex space-x-10">
                <a href="https://nirmalacollege.ac.in/nirmala-at-a-glance/about-nirmala-2/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  Nirmala at a Glance
                </a>
                <a href="https://nirmalacollege.ac.in/nirmala-at-a-glance/payment-policy-document/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  Payment Privacy Policy
                </a>
                <a href="https://nirmalacollege.ac.in/virtual-classroom/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  Virtual Classroom
                </a>
                <a href="https://nirmalacollege.ac.in/admission/" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                  Admission
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="w-full max-w-8xl mx-auto my-4 bg-opacity-60 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg overflow-scroll">
          <div className="lg:flex">
            <div className="lg:p-4 overflow-y-auto lg:w-1/3 bg-blue-900 bg-opacity-30 rounded-l-lg">
              <Register collegeData={collegeData} login={login} initCollegeId={initCollegeId} setLogin={setLogin} />
            </div>
            <div className="p-4 bg-blue-900 bg-opacity-30 lg:w-2/3 text-white rounded-r-lg">
              <h1 className="text-2xl lg:text-3xl text-shadow font-nunito font-bold mb-4 text-white text-center">Embark on Your Academic Journey</h1>
              <p className="font-nunito text-shadow font-bold text-white text-lg">{collegeData?.collegeName} Welcomes You!</p>
              <p className="mt-2 text-white text-shadow font-bold text-base text-justify">
                Your path to academic excellence and holistic development starts here. Explore our diverse range of programs and find one that aligns with your aspirations. Learn more about our application process, scholarships, and the vibrant campus life by visiting our website:
                <br />
                <a href={collegeData?.website} className="text-blue-200 hover:text-blue-300 hover:underline" target="_blank" rel="noopener noreferrer">
                  {/* {collegeData?.website} */}
                  {collegeData?.mcaWebsite ? collegeData?.mcaWebsite : collegeData?.website}
                </a>
                .
              </p>
              <p className="mt-2 text-base text-white text-shadow font-bold text-justify">Please prepare your academic records and necessary documents for submission. Guidance is available every step of the way.</p>
              <p className="mt-2 text-base text-white text-shadow font-nunito font-medium text-justify">
                Need help? Our dedicated admissions team is here to assist you, whether you prefer online assistance or a personal consultation. Visit our admissions help desk from 9:30 AM to 4:00 PM or reach us at:
                <a href={`tel:${collegeData?.phoneNumber}`} className="text-blue-200 hover:text-blue-300 hover:underline">
                  {collegeData?.mcaPhoneNumber ? collegeData?.mcaPhoneNumber : collegeData?.phoneNumber}
                </a>
                for any inquiries.
              </p>
              <p className="mt-4 font-nunito text-shadow font-semibold text-white text-lg">We're excited to welcome you to the {collegeData?.collegeName} community!</p>
              {collegeData?.collegeTour && (
                <div className="flex justify-center my-4">
                  <iframe width="560" height="315" src={collegeData?.collegeTour} title="Campus Tour Video Player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NirmalaCollege;
