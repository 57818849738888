import React, { useContext, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Table } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import CustomDropDown from "../../../../myComp/CustomDropDown";

export const exportPdfCollectionReport = async ({ postData }) => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post(`/college/reportGeneration/generateTcCc`, postData, {
      responseType: "blob",
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (e) {
    throw new Error();
  }
};

const getAllMembers = (data) => async () => {
  let { setLoading } = data;
  setLoading(true);

  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/timetable/getTeachersListMaster", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      setLoading(false);

      throw new Error(resp.data.message || "API Error");
    } else {
      setLoading(false);
      return resp?.data?.teachersListMaster;
    }
  } catch (error) {
    setLoading(false);
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const MasterTimeTables = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const { collegeId } = useContext(FirebaseContext);
  const [state, setState] = React.useState({
    startDate: "",
    endDate: "",
  });

  const { data = [], refetch } = useQuery(["aleeeeelTCCC", collegeId, state.year, parseInt(state?.fist), parseInt(state?.second), parseInt(state?.third)], getAllMembers({ collegeId, year: state.year, firstYearSem: parseInt(state?.fist), secondYearSem: parseInt(state?.second), thirdYearSem: parseInt(state?.third), setLoading }), { keepPreviousData: true });

  const aproveTcCc = async (data) => {
    const instance = await getAxiosTokenInstance();
    let resp;
    resp = await instance.post("/college/requestAprovals/addToMessageTcCcRequests", {
      collegeId,

      messageData: "",
      checkRole: "",
      action: "aprove",
      classId: "",
      departmentId: "",
      userId: data?.studentsDetails?.uid,
    });
    if (resp.data.statusCode !== 200) {
      throw new Error(resp.data.message || "API Error");
    } else {
      refetch();
    }
  };

  const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);

  const onTcCc = async (data) => {
    setLoading(false);
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        collegeId,
        startsNumber: data?.tcNumber,
        userId: data?.studentsDetails?.uid,
        applicationDate: data?.appliedOn,
        leavingDate: data?.dateOfLeaving,
        reasonForLeaving: data?.reasonForLeaving,
        exam: data?.exam,
        leaving: data?.leaving,
        joinedSem: data?.joinedSem,
        signatureDate: data?.aprovedOn,
      };
      link.download = data?.studentsDetails?.name;
      const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync({ postData });
      link.href = URL.createObjectURL(new Blob([pdfData], { type: "application/pdf" }));
      link.click();
    } catch (e) {}
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "",
      render: (a, data, i) => <span>{i + 1}</span>,
    },

    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
      columnWidth: "15%",
    },
    { title: "M 1", dataIndex: "m1", key: "m1" },
    { title: "M 2", dataIndex: "m2", key: "m2" },
    { title: "M 3", dataIndex: "m3", key: "m3" },
    { title: "M 4", dataIndex: "m4", key: "m4" },
    { title: "M 5", dataIndex: "m5", key: "m5" },
    { title: "T 1", dataIndex: "t1", key: "t1" },
    { title: "T 2", dataIndex: "t2", key: "t2" },
    { title: "T 3", dataIndex: "t3", key: "t3" },
    { title: "T 4", dataIndex: "t4", key: "t4" },
    { title: "T 5", dataIndex: "t5", key: "t5" },
    { title: "W 1", dataIndex: "w1", key: "w1" },
    { title: "W 2", dataIndex: "w2", key: "w2" },
    { title: "W 3", dataIndex: "w3", key: "w3" },
    { title: "W 4", dataIndex: "w4", key: "w4" },
    { title: "W 5", dataIndex: "w5", key: "w5" },
    { title: "H 1", dataIndex: "h1", key: "h1" },
    { title: "H 2", dataIndex: "h2", key: "h2" },
    { title: "H 3", dataIndex: "h3", key: "h3" },
    { title: "H 4", dataIndex: "h4", key: "h4" },
    { title: "H 5", dataIndex: "h5", key: "h5" },
    { title: "F 1", dataIndex: "f1", key: "f1" },
    { title: "F 2", dataIndex: "f2", key: "f2" },
    { title: "F 3", dataIndex: "f3", key: "f3" },
    { title: "F 4", dataIndex: "f4", key: "f4" },
    { title: "F 5", dataIndex: "f5", key: "f5" },
  ];

  return (
    <div className={classNames.container}>
      <div className={classNames.modal}>
        {loading ? (
          <LoadingAnimation dark />
        ) : (
          <>
            <div className={classNames.listContainer2}>
              <Scrollbars>
                <table className={classNames.userTable} id="emp">
                  <Table dataSource={data || []} pagination={false} columns={columns} />
                </table>
              </Scrollbars>
            </div>
          </>
        )}
      </div>

      <div className={classNames.absoluteDiv}>
        <label className={classNames.heading}>Filter </label>

        <div style={{ width: 200 }}>
          <CustomDropDown keyData={"year"} titleData={"Admn Year"} dropValues={["2020-2021", "2021-2022", "2022-2023", "2023-2024"]} stateData={state} setStateData={setState} />
        </div>

        <div style={{ width: 200 }}>
          <CustomDropDown keyData={"fist"} titleData={"First Year"} dropValues={["1", "2", "3", "4", "5", "6"]} stateData={state} setStateData={setState} />
        </div>

        <div style={{ width: 200 }}>
          <CustomDropDown keyData={"second"} titleData={"Second Year"} dropValues={["1", "2", "3", "4", "5", "6"]} stateData={state} setStateData={setState} />
        </div>

        <div style={{ width: 200 }}>
          <CustomDropDown keyData={"third"} titleData={"Third Year"} dropValues={["1", "2", "3", "4", "5", "6"]} stateData={state} setStateData={setState} />
        </div>

        <Button
          className={classNames.reloadButton}
          onClick={() => {
            refetch();
          }}
        >
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button" table="emp" filename="collectionReport" sheet="tablexls" buttonText="EXCEL" />
        </Button>
      </div>

      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};
