import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../NecttosComp/Table/Table";
import { getAdmissionRegister, getNewClasses } from "../../pages/college/ApiServices";
import moment from "moment";
import ApplicationView from "./ApplicationView";
import { getCollegeDetailsee } from "./RemoveAction";
import { getCollegeDetails } from "../Form/Register";
import AllotmentMemo from "../Form/AllotmentMemo";

interface invoiceManagementProps {
  onClose: () => void;
}

function AdmissionRegister3({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const { collegeId, user } = useContext(FirebaseContext);
  const ref = useRef<any>(null);
  const componentRef2 = useRef();
  const [selectedClass, setSelectClass] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { data: classes } = useQuery("getNewClasses", getNewClasses(collegeId, user?.uid));
  const [filterData, setFilter] = useState<any>([]);
  const [filterKey, setFilterKey] = useState("ALL");
  const [printable, setPritable] = useState<boolean>(false);
  const { data, refetch, status } = useQuery(["getAdmissionRegister", addNew?.startDate, addNew?.endingDate, selectedClass, filterKey], getAdmissionRegister(collegeId, addNew?.startDate, addNew?.endingDate, selectedClass, filterKey));
  const tableData = data?.list;
  const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
  const { data: classesx } = useQuery([`collegeDataddd`, collegeId], getCollegeDetails);
  useEffect(() => {
    if (!tableData) return;
    let filtered = tableData;
    if (filterKey !== "ALL") {
      filtered = filtered.filter((data: any) => (filterKey === "UG" ? ["2024-2028", "2024-2027", "2024-2029"].includes(data.batchName) : ["2024-2026"].includes(data.batchName)));
    }
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter((data: any) => data.name.toLowerCase().includes(lowerCaseQuery) || data?.phoneNumber?.includes(searchQuery) || data.admisionNo.includes(searchQuery));
    }
    setFilter(filtered);
  }, [filterKey, tableData, searchQuery]);
  const { tableItems, requestSort, getIcon } = useSortableData(filterData);

  const handleDelete = async (_id: string) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .delete("/college/action/deleteAccadamicHoliday/" + _id, { params: { collegeId } })
        .then(() => {
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/action/postAccadamicHolidays", { ...addNew, collegeId })
        .then(() => {
          setAddNew({});
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
      closeAnimation();
      setTimeout(() => setShowForm(false), 500);
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const [className, setClassName] = useState<any>("");

  useEffect(() => {
    if (selectedClass && classesx) {
      let classNam = classesx?.find((x: any) => x._id === selectedClass)?.className || "";
      setClassName(classNam);
    } else {
      setClassName("");
    }
  }, [selectedClass, classesx]);

  return (
    <>
      <Main title="Admission Register" width="100%" height="100%">
        <Header>
          <div className="flex">
            <Input fieldName="Start Date *" returnKey="startDate" state={addNew} setState={setAddNew} type="date" width="200px" />
            <Input fieldName="End Date *" returnKey="endingDate" state={addNew} setState={setAddNew} type="date" width="200px" />
            <Input fieldName="Filter by Course" state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay="className" optionKey="_id" options={classes?.list} type="drop" width="15vw" />
            <Input fieldName="Search" state={searchQuery} setState={setSearchQuery} onChange={(event) => setSearchQuery(event.value)} type="text" width="15vw" />
          </div>
          <div className="flex">
            <Button type={printable ? "search" : "update"} onClick={() => setPritable(!printable)}>
              {printable ? "Hide Printable" : "Show Printape"}
            </Button>
            <Button
              type="save"
              onClick={() => {
                setFilterKey("ALL");
                setSelectClass("");
              }}
            >
              Show Both
            </Button>
            <Button
              type="fetch"
              onClick={() => {
                setFilterKey("Aided");
                setSelectClass("");
              }}
            >
              Show Aided Only
            </Button>
            <Button
              type="doc"
              onClick={() => {
                setFilterKey("Self");
                setSelectClass("");
              }}
            >
              Show Self Only
            </Button>
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="printable overflow-auto h-full ">
            {printable && (
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    <td className="p-1 bg-white" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      <img src={collegeData?.dashBoardLink} alt="College Logo" className="h-[100px] w-auto  mx-auto" />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-1 text-center text-black bg-white text-xl font-bold" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      Course: {className}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <Table width={printable ? "100%" : "2600px"}>
              <Thead>
                <Tr>
                  <Th width={printable ? "10%" : "200px"} position={0} onClick={() => requestSort("admisionNo")} icon={getIcon("admisionNo")}>
                    Admission Number
                  </Th>

                  <Th width={printable ? "20%" : "200px"} onClick={() => requestSort("name")} icon={getIcon("name")}>
                    Name & Address
                  </Th>
                  <Th width={printable ? "10%" : "200px"} onClick={() => requestSort("admType")} icon={getIcon("admType")}>
                    Gender
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Religion
                  </Th>

                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Mobile No & Email ID
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Name of Parents & Occupation
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Date of Birth
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    School / College Last Attended
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Highest Exam Passed & Date Passing
                  </Th>

                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Date of Admission & Class
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Class on Leaving & Date of leaving
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    TC No With Date
                  </Th>
                  <Th width="200px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Remarks
                  </Th>
                </Tr>
              </Thead>
              <Tbody height="100vh">
                {tableItems?.map((item, index) => (
                  <Tr key={index}>
                    <Td width={"200px"} index={index} position={0}>
                      {item?.admisionNo}
                    </Td>

                    <Td width={"200px"} index={index} position={0}>
                      {item?.name?.toUpperCase()}, {` Address: ${item?.houseName || ""}, ${item?.place || ""}, ${item?.postOffice || ""}, ${item?.pinCode || ""} ${item?.district || ""}`}
                    </Td>
                    <Td width={"200px"} index={index} position={0}>
                      {item?.gender}
                    </Td>
                    <Td width={"200px"} index={index} position={0}>
                      {item?.category}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {item?.phoneNumber}, {item?.email}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {`${item.guardianName || ""}; ${item.guardianRelation || ""}; ${item.guardianMobile || ""}; ${item.guardianQualification || ""}`}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {item.dob}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {item?.degreeInstitutionName || item.plusTwoInstitutionName}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {item?.degreeCourse ? item?.degreeCourse + " & " + item.degreeYearOfPass : item.plusTwoBoard + " & " + item.plusTwoYearOfPass}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {moment(item?.dateOfAdmission)?.format("DD-MM-YYYY") + " & " + item.shortName}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {item.leavingSem && item.leavingSem + " & " + moment(item?.dateOfLeaving)?.format("DD-MM-YYYY")}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {item.tcNumber && moment(item?.tcDate)?.format("DD-MM-YYYY") + " & " + item.tcNumber}
                    </Td>
                    <Td width="200px" index={index} position={0}>
                      {""}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        {addNew?.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.typeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}
      </Main>

      {addNew?.showConfirmation && (
        <Main width="100%" height="100%" title="Make Decision">
          <Body>
            <div className="p-6 space-y-6">
              <div className="flex justify-between items-center">
                <Button
                  type="close"
                  onClick={() => {
                    closeAnimation();
                    setTimeout(() => setAddNew({ ...addNew, showConfirmation: false }), 500);
                  }}
                >
                  Close
                </Button>
              </div>

              <div className="flex flex-row w-full space-x-6">
                <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 flex flex-col items-center">
                  <h2 className={addNew?.color}>Applicant Decided to:</h2>
                  <h2 className={addNew?.color}>{addNew.status}</h2>
                  <Button type="print" tableRef={componentRef2.current}>
                    Print Allotment Memo
                  </Button>
                  <Body>
                    <AllotmentMemo componentRef={componentRef2} collegeData={collegeData} classId={selectedClass} className={addNew?.classNam} quota={addNew?.admType} classes={classesx} type={"UG"} indexMark={addNew?.index} values={addNew} getQuotaTypes={addNew?.getQuotaTypes} />
                  </Body>
                </div>
              </div>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default AdmissionRegister3;
