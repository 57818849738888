import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Table, Thead, Tbody, Td, Td2, Tfoot, TfootTd, TfootTr, Th, Tr } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import Voucher from './Voucher';
import { Main } from '../../NecttosComp/Layout/Layout';

function RecieptPaymentSubNew({ date, setDate, account }) {
	const { collegeId } = useContext(FirebaseContext);

	const [reciept, setReciept] = useState(0);
	const [payment, setPayment] = useState(0);
	const [deleteModal, setDeleteModal] = useState(false);
	const [printing, setPrinting] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState('');

	const tableRef = useRef(null);

	useEffect(() => {
		let reciept = 0;
		let payment = 0;
		for (let i in date?.data) {
			const item = date?.data[i];
			reciept = reciept + (parseInt(item.debitCredit === 'Credit' ? item.type === 'reciept' && item.amount : item.type === 'payment' && item.amount) || 0);
			payment = payment + (parseInt(item.debitCredit === 'Credit' ? item.type === 'payment' && item.amount : item.type === 'reciept' && item.amount) || 0);
		}
		setReciept(reciept);
		setPayment(payment);
	}, [date]);

	const callPostFunction = async (linkId) => {

		if (!window.confirm('Are you sure you want to delete these entries?')) {
			return;
		}
		const instance = await getAxiosTokenInstance();

		try {
			const resp = await instance.post('/college/action/deleteAccounts', { collegeId, linkId });

			if (resp?.data?.statusCode === 400) {
				throw new Error(resp?.data?.message || 'API Error');
			} else {
				setDate({ ...date, data: [] });
				return resp?.data;
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error?.message || 'API Error');
		}
	};

	const DeleteModal = ({ isOpen, onClose, title, children, data }) => {
		if (!isOpen) return null;
		console.log({ hello: data });

		return (
			<div className='fixed inset-0 bg-gray-600 bg-opacity-70 flex items-center justify-center z-10'>
				<div className='bg-white rounded-lg overflow-hidden shadow-lg max-w-7xl w-full px-4  h-auto max-h-[70vh]'>
					<div className='p-4 border-b flex justify-between'>
						<h3 className='text-lg font-medium text-gray-900'>{title}</h3>
						<button onClick={onClose} className='bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 border-blue-800 shadow'>
							Close
						</button>
					</div>
					<div>
						<Table width='100%' innerWidth={['5%', '15%', '10%', '10%', '10%', '10%', '10%', '30%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={6}>Date</Th>
									<Th position={2}>Paid To</Th>
									<Th position={3}>Particulars</Th>
									<Th position={4}>Amount</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{data ? (
									<>
										<Tr>
											<Td index={1} position={0} textAlign='center'>
												{1}
											</Td>
											<Td index={1} position={6} textAlign='center'>
												{moment(data.date).format('DD MMM YYYY')}
											</Td>
											<Td index={1} position={2} textAlign='center'>
												{data.paidTo}
											</Td>
											<Td index={1} position={3} textAlign='center'>
												{data.naration}
											</Td>
											<Td index={1} position={4} textAlign='center'>
												{data.amount}
											</Td>
										</Tr>
									</>
								) : (
									<Tr>
										<p
											colSpan='8'
											style={{
												textAlign: 'center',
												color: 'red',
												fontSize: '18px',
												padding: '10px',
											}}>
											No bills available. Please add some data.
										</p>
									</Tr>
								)}

								<Tr>
									<p
										colSpan='8'
										style={{
											textAlign: 'center',
											color: 'red',
											fontSize: '18px',
											padding: '10px',
										}}>
										Do you want to delete this <strong>Journal Entry</strong> ?
									</p>
									<div className='flex justify-center'>
										<Button type='delete' onClick={() => callPostFunction(data?.linkId)}>
											Delete
										</Button>
									</div>
								</Tr>
							</Tbody>
						</Table>
					</div>
					<div className='p-4 border-t'></div>
				</div>
			</div>
		);
	};

	return (
		<Main height='90%' width='90%' title={account}>
			<div className='bg-gray-100 p-6 w-[100%] rounded-lg shadow-xl overflow-auto h-[100%]'>
				<div className='flex justify-between z-1'>
					<div className='flex items-center'></div>

					<div className='flex items-center'>
						<Button
							type='save'
							onClick={() => {
								setDate({
									...date,

									showEdit: true,
								});
							}}>
							Post New Journal Entry
						</Button>
						<Button type='excel' tableRef={tableRef?.current} fileName='account'>
							Excel{' '}
						</Button>
						<Button type='pdf' pdfId='emp' fileName='account'>
							PDF{' '}
						</Button>
						<Button type='print'>Print</Button>

						<Button type='close' onClick={() => setDate({ ...date, data: [] })}>
							Close
						</Button>
					</div>
				</div>
				<div className='overflow-x-auto z-1'>
					<Table width={'100%'} id='emp' ref={tableRef}>
						<Thead>
							<Tr>
								<Th width={'5%'}>SN</Th>
								<Th width={'10%'}>Date</Th>
								<Th width={'10%'}>Paid To</Th>
								<Th width={'25%'}>Particulars</Th>
								<Th width={'10%'}>Amount</Th>
								<Th width={'15%'}>Actions</Th>
							</Tr>
						</Thead>
						<Tbody height={'70vh'}>
							{date?.data?.map((item, i) => (
								<Tr index={i}>
									<Td index={i} width={'5%'}>
										{i + 1}
									</Td>
									<Td index={i} width={'10%'}>
										{moment(item.date).format('DD MMM YYYY')}
									</Td>
									<Td index={i} width={'10%'} textAlign={'start'}>
										{item?.holder}
									</Td>
									<Td index={i} width={'25%'} textAlign={'start'}>
										{item.naration}
									</Td>
									<Td index={i} width={'10%'}>
										{item.amount}
									</Td>

									<Td index={i} width={'15%'}>
										<div className='flex'>
											<Button
												type='save'
												disabled={item.disabledEdit}
												onClick={() => {
													setPrinting(true);
													setDate({
														...date,
														printData: {
															...item,
														},
													});
												}}>
												Print
											</Button>
											<Button
												disabled={item.disabledEdit}
												type='edit'
												onClick={() => {
													setDate({
														...date,
														values: {
															date: moment(new Date(item.date)).format('YYYY-MM-DD'),
															from: item.type === 'payment' ? item.accountId : item.wereId,
															fromval: item.type === 'payment' ? item.accountName : item.wereName,
															to: item.type === 'payment' ? item.wereId : item.accountId,
															toval: item.type === 'payment' ? item.wereName : item.accountName,
															linkId: item.linkId,
															amount: item.amount,
															naration: item.naration,
															billNumber: item.recieptNo,
															item: item,
															...item,
														},
														linkId: item.linkId,
														showEdit: true,
													});
												}}>
												Edit
											</Button>
											<Button
												disabled={item.disabledEdit}
												type='delete'
												onClick={() => {
													setDeleteModal(true);
													setDeleteModalData(item);
												}}>
												Delete
											</Button>
										</div>
									</Td>
								</Tr>
							))}
						</Tbody>
						<Tfoot>
							<TfootTr>
								<TfootTd width={'50%'}>Grand Total</TfootTd>
								<TfootTd width={'10%'}>{date.total}</TfootTd>
								<TfootTd width={'15%'}></TfootTd>
							</TfootTr>
						</Tfoot>
					</Table>
				</div>
			</div>
			{printing && <Voucher z={100} data={date.printData} onClose={() => setPrinting(false)} />}
			{deleteModal && (
				<DeleteModal
					isOpen={deleteModal}
					onClose={() => {
						setDeleteModal(false);
						setDeleteModalData('');
					}}
					title='Delete entries'
					data={deleteModalData}></DeleteModal>
			)}
		</Main>
	);
}

export default RecieptPaymentSubNew;
