import React, { useContext, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Select, message } from 'antd';
import { Field, Formik } from 'formik';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import StudentFeeDetail from './components/StudentFeeDetail';
import { getEditData, getFeeDueList } from './feesManagementServices';
import { BillNumberModal } from './components/BillNumberModal';
import SearchableUserInputPhoto from '../../../../myComp/SearchableUserInputPhoto';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { EditDataBills } from './EditDataBills';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { useProfileData } from '../../../../queryHooks';
import { EditBillAmount } from './EditBillAmount';
import Item from 'antd/lib/list/Item';
import { Body, Header, Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../NecttosComp/Table/Table';
import { BillNumberModal2 } from './components/BillNumberModal/PrintReceipt';

const FeesBilling = ({ onClose, keyDataIsStaff, setItemsData, itemsData, studentId }) => {
	const [viewModalOpen, setViewModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [viewModalData, setViewModalData] = useState('');
	const [printModal, setPrintModal] = useState(false);
	const [billNumberModal, setBillNumberModal] = useState(false);
	const [billNumberModal2, setBillNumberModal2] = useState(false);
	const [successData, setSuccessData] = useState();
	const [title, setTitle] = useState({});
	const [state, setState] = React.useState({
		filterValue: '',
		_id: studentId,
		page: 0,
		isBill: false,
		isAction: false,
		isDues: false,
		isBilling: true,
		openBillModal: false,
		openBillModal2: false,
		openBillModal3: false,
	});
	const { collegeId } = useContext(FirebaseContext);
	const { data: EditData = [], refetch } = useQuery(['EditDataSSSS', state.isDues, state.isAction, state.isBill, state.isBilling, studentId], getEditData(collegeId, state?._id));

	console.log({ EditData });
	console.log({ viewModalData });

	const { data: studentData } = useProfileData(EditData?.userId);
	console.log({ studentData });
	const getSubAccounts = async () => {
		try {
			const instance = await getAxiosTokenInstance();

			const value = await instance.get('/college/action/getSubAccounts', {
				params: {
					collegeId,
				},
			});
			setTitle({ value });
		} catch {
			console.log('wrong');
		}
	};

	const handleClickbackButton = (data) => {
		if (data) {
			setBillNumberModal(data && true);
			setBillNumberModal2(data && true);
			setSuccessData(data);
			setState({
				...state,
				collegeId: '',
				admisionNo: '',
				_id: '',
				data: [],
			});
		}
	};
	const { Option } = Select;

	const deleteDues = async (itemData) => {
		if (!window.confirm('Are you sure you want to delete these dues?')) {
			return;
		}
		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/deleteDues', {
				collegeId,
				feeId: itemData._id,
				userId: state._id,
			});

			if (resp.data.statusCode !== 200) {
				refetch();

				throw new Error(resp.data.message || 'API Error');
			}
			refetch();
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			refetch();
		}
	};

	const deleteBill = async (itemData) => {
		console.log({ itemData });
		if (!window.confirm('Are you sure you want to delete these Bill?')) {
			return;
		}
		try {
			const instance = await getAxiosTokenInstance();
			console.log({ itemData });
			let resp;
			resp = await instance.post('/college/action/deleteBill', {
				collegeId,
				billId: itemData._id,
				userId: state._id,
			});

			if (resp.data.statusCode !== 200) {
				refetch();
				throw new Error(resp.data.message || 'API Error');
			}
			setDeleteModalOpen(false);
			refetch();
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			refetch();
		}
	};

	const cancelBill = async (itemsData) => {
		if ('') {
			return;
		}
		if (!window.confirm('Are you sure you want to cancel these Bill?')) {
			return;
		}
		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/cancelBill', {
				collegeId,
				billId: itemsData?._id,
				userId: state?._id,
			});
			if (resp.data.statusCode !== 200) {
				refetch();
				throw new Error(resp.data.message || 'API Error');
			}
			refetch();
			setCancelModalOpen(false)
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			refetch();
		}
	};

	const ViewModal = ({ isOpen, onClose, title, children, data }) => {
		if (!isOpen) return null;
		console.log({ data });

		return (
			<div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-10'>
				<div className='bg-white rounded-lg overflow-hidden shadow-lg max-w-7xl w-full p-2  h-auto min-h-[40vh] max-h-[70vh]'>
					<div className='p-4 border-b flex justify-between'>
						<h3 className='text-lg font-medium text-gray-900'>{title}</h3>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
					<div>
						<Table width='100%' innerWidth={['5%', '15%', '10%', '10%', '10%', '10%', '10%', '30%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={1}>Governed By</Th>
									<Th position={2}>Title</Th>
									<Th position={3}>Receipt Number</Th>
									<Th position={4}>Amount</Th>
									<Th position={5}>Method</Th>
									<Th position={5}>Year</Th>
									<Th position={6}>Date</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{data?.innerElements && data?.innerElements?.length > 0 ? (
									data?.innerElements?.map((item, i) => (
										<Tr>
											<Td index={i} position={0} textAlign='center'>
												{i + 1}
											</Td>
											<Td index={i} position={1} textAlign='center'>
												{item?.feeType}
											</Td>
											<Td index={i} position={2} textAlign='center'>
												{item?.accountName}
											</Td>
											<Td index={i} position={3} textAlign='center'>
												{item?.billNumber}
											</Td>
											<Td index={i} position={4} textAlign='center'>
												{item?.amount}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item?.method}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item?.feeYear?.toUpperCase()}
											</Td>
											<Td index={i} position={6} textAlign='center'>
												{moment(item.date).format('DD-MM-YYYY')}
											</Td>
										</Tr>
									))
								) : (
									<Tr>
										<p
											colSpan='8'
											style={{
												textAlign: 'center',
												color: 'red',
												fontSize: '18px',
												padding: '10px',
											}}>
											No bills available. Please add some data.
										</p>
									</Tr>
								)}
							</Tbody>
						</Table>
					</div>
					<div className='p-4 border-t'></div>
				</div>
			</div>
		);
	};

	const CancelModal = ({ isOpen, onClose, title, children, data }) => {
		if (!isOpen) return null;
		console.log({ data });

		return (
			<div className='fixed inset-0 bg-gray-600 bg-opacity-70 flex items-center justify-center z-10'>
				<div className='bg-white rounded-lg overflow-hidden shadow-lg max-w-7xl w-full px-4  h-auto max-h-[70vh]'>
					<div className='p-4 border-b flex justify-between'>
						<h3 className='text-lg font-medium text-gray-900'>{title}</h3>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
					<div>
						<Table width='100%' innerWidth={['5%', '15%', '10%', '10%', '10%', '10%', '10%', '30%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>

									<Th position={2}>Title</Th>
									<Th position={3}>Receipt Number</Th>
									<Th position={4}>Amount</Th>

									<Th position={5}>Year</Th>
									<Th position={6}>Date</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{data?.collectedData && data?.collectedData?.length > 0 ? (
									data?.collectedData?.map((item, i) => (
										<>
											<Tr>
												<Td index={i} position={0} textAlign='center'>
													{i + 1}
												</Td>

												<Td index={i} position={2} textAlign='center'>
													{item?.title}
												</Td>
												<Td index={i} position={3} textAlign='center'>
													{data?.billNumber}
												</Td>
												<Td index={i} position={4} textAlign='center'>
													{item?.amount}
												</Td>

												<Td index={i} position={5} textAlign='center'>
													{item?.year}
												</Td>
												<Td index={i} position={6} textAlign='center'>
													{moment(item?.date, 'DD-mm-yyy').format('DD-mm-yyyy')}
												</Td>
											</Tr>
										</>
									))
								) : (
									<Tr>
										<p
											colSpan='8'
											style={{
												textAlign: 'center',
												color: 'red',
												fontSize: '18px',
												padding: '10px',
											}}>
											No bills available. Please add some data.
										</p>
									</Tr>
								)}

								<Tr>
									<p
										colSpan='8'
										style={{
											textAlign: 'center',
											color: 'red',
											fontSize: '18px',
											padding: '10px',
										}}>
										Do you want to cancel these bills with Receipt <strong>{data.billNumber}</strong> ?
									</p>
									<div className='flex justify-center'>
										<Button type='delete' onClick={() => cancelBill(data)}>
											Cancel{' '}
										</Button>
									</div>
								</Tr>
							</Tbody>
						</Table>
					</div>
					<div className='p-4 border-t'></div>
				</div>
			</div>
		);
	};
	const DeleteModal = ({ isOpen, onClose, title, children, data }) => {
		if (!isOpen) return null;
		console.log({ data });

		return (
			<div className='fixed inset-0 bg-gray-600 bg-opacity-70 flex items-center justify-center z-10'>
				<div className='bg-white rounded-lg overflow-hidden shadow-lg max-w-7xl w-full px-4  h-auto max-h-[70vh]'>
					<div className='p-4 border-b flex justify-between'>
						<h3 className='text-lg font-medium text-gray-900'>{title}</h3>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
					<div>
						<Table width='100%' innerWidth={['5%', '15%', '10%', '10%', '10%', '10%', '10%', '30%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>

									<Th position={2}>Title</Th>
									<Th position={3}>Receipt Number</Th>
									<Th position={4}>Amount</Th>

									<Th position={5}>Year</Th>
									<Th position={6}>Date</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{data?.collectedData && data?.collectedData?.length > 0 ? (
									data?.collectedData?.map((item, i) => (
										<>
											<Tr>
												<Td index={i} position={0} textAlign='center'>
													{i + 1}
												</Td>

												<Td index={i} position={2} textAlign='center'>
													{item?.title}
												</Td>
												<Td index={i} position={3} textAlign='center'>
													{data?.billNumber}
												</Td>
												<Td index={i} position={4} textAlign='center'>
													{item?.amount}
												</Td>

												<Td index={i} position={5} textAlign='center'>
													{item?.year}
												</Td>
												<Td index={i} position={6} textAlign='center'>
													{moment(item?.date, 'DD-mm-yyy').format('DD-mm-yyyy')}
												</Td>
											</Tr>
										</>
									))
								) : (
									<Tr>
										<p
											colSpan='8'
											style={{
												textAlign: 'center',
												color: 'red',
												fontSize: '18px',
												padding: '10px',
											}}>
											No bills available. Please add some data.
										</p>
									</Tr>
								)}

								<Tr>
									<p
										colSpan='8'
										style={{
											textAlign: 'center',
											color: 'red',
											fontSize: '18px',
											padding: '10px',
										}}>
										Do you want to delete bills with Receipt <strong>{data.billNumber}</strong> ?
									</p>
									<div className='flex justify-center'>
										<Button type='delete' onClick={() => deleteBill(data)}>
											Delete{' '}
										</Button>
									</div>
								</Tr>
							</Tbody>
						</Table>
					</div>
					<div className='p-4 border-t'></div>
				</div>
			</div>
		);
	};

	return (
		<Main width='100vw' height='100vh' title='Fees Management'>
			<Header>
				<div className=' flex w-50%'>
					<SearchableUserInputPhoto primoryKey={'admisionNo'} secondaryKey={'name'} tertiaryKey={'phoneNumber'} collection={'users'} stateData={state} setStateData={setState} titleData={'Search Admision No or Name'} />
				</div>
				{state._id ? (
					<>
						<div className='flex  '>
							<Button
								type='update'
								onClick={() => {
									setItemsData({ ...itemsData, instanceFeeCollection: true });
								}}>
								Add Fees
							</Button>

							<Button
								type='doc'
								onClick={() => {
									setItemsData({ ...itemsData, collectionReports: true });
								}}>
								Reports
							</Button>
							<Button
								type='save'
								onClick={() => {
									setState({ ...state, isBill: false, isAction: false, isDues: true, isBilling: false });
								}}>
								Show Dues
							</Button>

							<Button
								type='fetch'
								onClick={() => {
									setState({ ...state, isBill: false, isAction: true, isDues: false, isBilling: false });
								}}>
								Show Manipulated
							</Button>

							<Button
								type='download'
								onClick={() => {
									setState({ ...state, isBill: true, isAction: false, isDues: false, isBilling: false });
								}}>
								Show Reciepts
							</Button>

							<Button
								type='search'
								onClick={() => {
									setState({ ...state, isBill: false, isAction: false, isDues: false, isBilling: true });
								}}>
								Show Billing
							</Button>

							<Button type='close' onClick={onClose}>
								Close
							</Button>
						</div>
					</>
				) : (
					<>
						{' '}
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</>
				)}
			</Header>
			{state.isBilling && !state.isDues && !state.isBill && !state.isAction && (
				<Body height='90vh'>
					<div className='flex flex-col w-full'>{state._id && <StudentFeeDetail userId={state?._id} handleClickbackButton={handleClickbackButton} feeSummary={{}} keyDataIsStaff={keyDataIsStaff} setStateData={setState} stateData={state} />}</div>
				</Body>
			)}
			{state.isDues && !state.isBill && !state.isBilling && !state.isAction && (
				<Body height='90vh'>
					<Table width='100%' innerWidth={['5%', '15%', '15%', '15%', '10%', '10%', '10%', '20%']}>
						<Thead>
							<Tr>
								<Th position={0}>Sl.No.</Th>
								<Th position={1}>Fee Type</Th>
								<Th position={2}>Title</Th>
								<Th position={3}>Amount</Th>
								<Th position={4}>Paid</Th>
								<Th position={5}>Balance</Th>
								<Th position={6}>Date</Th>
								<Th position={7}>Action</Th>
							</Tr>
						</Thead>
						<Tbody height='auto'>
							{EditData.dues && EditData.dues.length > 0 ? (
								EditData.dues?.map((item, i) => (
									<Tr>
										<Td index={i} position={0} textAlign='center'>
											{i + 1}
										</Td>
										<Td index={i} position={1} textAlign='center'>
											{item?.feeType}
										</Td>
										<Td index={i} position={2} textAlign='center'>
											{item?.title}
										</Td>
										<Td index={i} position={3} textAlign='center'>
											{item?.amount}
										</Td>
										<Td index={i} position={4} textAlign='center'>
											{item?.paid}
										</Td>
										<Td index={i} position={5} textAlign='center'>
											{item?.balance}
										</Td>
										<Td index={i} position={6} textAlign='center'>
											{moment(item?.dueDate).format('DD-MM-YY')}
										</Td>
										<Td index={i} position={7}>
											<div style={{ display: 'flex ', justifyContent: 'space-evenly' }}>
												<Button
													type='save'
													disabled={item?.balance === 0}
													onClick={() => {
														setState({ ...state, itemData: item, editOpen: true });
														getSubAccounts();
													}}>
													{' '}
													Edit
												</Button>
												<Button type='delete' disabled={item?.paid !== 0} onClick={() => deleteDues(item)}>
													{' '}
													Delete{' '}
												</Button>
											</div>
										</Td>
									</Tr>
								))
							) : (
								<Tr>
									<p colSpan='8' style={{ textAlign: 'center', color: 'red', fontSize: '18px', padding: '10px' }}>
										No Dues You Have
									</p>
								</Tr>
							)}
						</Tbody>
					</Table>
				</Body>
			)}

			{!state.isDues && state.isBill && !state.isBilling && !state.isAction && (
				<Body height='90vh'>
					<div>
						<Table width='100%' innerWidth={['5%', '8%', '10%', '8%', '8%', '5%', '9%', '30%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={1}>Governed By</Th>
									<Th position={2}>Title</Th>
									<Th position={2}>Sub Title</Th>
									<Th position={5}>Rt No</Th>
									<Th position={5}>Amount</Th>
									<Th position={5}>Method</Th>
									<Th position={5}>Year</Th>
									<Th position={6}>Date</Th>
									<Th position={7}>Action</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{EditData?.collections && EditData?.collections?.length > 0 ? (
									EditData?.collections?.map((item, i) => (
										<Tr>
											<Td index={i} position={0} textAlign='center'>
												{i + 1}
											</Td>
											<Td index={i} position={1} textAlign='center'>
												{item.feeType}
											</Td>
											<Td index={i} position={2} textAlign='center'>
												{item.title}
											</Td>
											<Td index={i} position={2} textAlign='center'>
												{item.subTitle}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item.billNumber}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item.amount}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item.method}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item.year}
											</Td>
											<Td index={i} position={6} textAlign='center'>
												{item.date}
											</Td>
											<Td index={i} position={7}>
												<div
													style={{
														display: 'flex ',
														justifyContent: 'space-evenly',
														alignItems: 'center',
													}}>
													<Button
														type='fetch'
														onClick={() =>
															setState({
																...state,
																newData: {
																	...item,
																	name: EditData?.name,
																	batchName: EditData?.batchName,
																	longName: EditData?.longName,
																	admisionNo: EditData?.admisionNo,
																},
																openBillModal2: true,
															})
														}>
														C Print
													</Button>
													<Button
														type='fetch'
														onClick={() =>
															setState({
																...state,
																newData: {
																	...item,
																	collectedData: item?.innerElements?.map((x) => ({
																		...x,
																		title: x?.accountName,
																		year: x?.feeYear?.toUpperCase(),
																	})),
																	name: EditData?.name,
																	batchName: EditData?.batchName,
																	longName: EditData?.longName,
																	admisionNo: EditData?.admisionNo,
																},
																openBillModal2: true,
															})
														}>
														Print
													</Button>
													<Button
														type='submit'
														onClick={() => {
															setViewModalOpen(true);
															setViewModalData(item);
														}}>
														View{' '}
													</Button>

													<Button type='search' onClick={() => {
														setCancelModalOpen(true);
														setViewModalData(item);
													}}>
														Cancel{' '}
													</Button>
													<Button
														type='save'
														onClick={() =>
															setState({
																...state,
																itemData: item,
																editBill: true,
															})
														}>
														Edit{' '}
													</Button>
													<Button
														type='delete'
														onClick={() => {
															setDeleteModalOpen(true);
															setViewModalData(item);
														}}>
														Delete{' '}
													</Button>
												</div>
											</Td>
										</Tr>
									))
								) : (
									<Tr>
										<p
											colSpan='8'
											style={{
												textAlign: 'center',
												color: 'red',
												fontSize: '18px',
												padding: '10px',
											}}>
											No bills available. Please add some data.
										</p>
									</Tr>
								)}
							</Tbody>
						</Table>
					</div>
				</Body>
			)}

			{!state.isDues && !state.isBill && !state.isBilling && state.isAction && (
				<Body height='90vh'>
					<Table width='100%' innerWidth={['5%', '15%', '10%', '15%', '15%', '10%', '15%', '15%']}>
						<Thead>
							<Tr>
								<Th position={0}>Sl.No.</Th>
								<Th position={1}>Fee Type</Th>
								<Th position={2}>Title</Th>
								<Th position={3}>Bill Number</Th>
								<Th position={4}>Amount</Th>
								<Th position={5}>Method</Th>
								<Th position={6}>Type</Th>
								<Th position={7}>Date</Th>
							</Tr>
						</Thead>
						<Tbody height='auto'>
							{EditData.manipulated && EditData.manipulated.length > 0 ? (
								EditData.manipulated?.map((item, i) => (
									<Tr>
										<Td index={i} position={0} textAlign='center'>
											{i + 1}
										</Td>
										<Td index={i} position={1} textAlign='center'>
											{item?.feeType}
										</Td>
										<Td index={i} position={2} textAlign='center'>
											{item?.title}
										</Td>
										<Td index={i} position={3} textAlign='center'>
											{item?.billNumber}
										</Td>
										<Td index={i} position={4} textAlign='center'>
											{item?.amount}
										</Td>
										<Td index={i} position={5} textAlign='center'>
											{item?.method}
										</Td>
										<Td index={i} position={7} textAlign='center'>
											{item?.type}
										</Td>
										<Td index={i} position={6} textAlign='center'>
											{moment(item?.date).format('DD-MM-YY')}
										</Td>
									</Tr>
								))
							) : (
								<Tr>
									<p colSpan='8' style={{ textAlign: 'center', color: 'red', fontSize: '18px', padding: '10px' }}>
										Manipulated Data Is Empty,Please add some data.
									</p>
								</Tr>
							)}
						</Tbody>
					</Table>
				</Body>
			)}

			{state.editBill && (
				<EditBillAmount
					itemData={state?.itemData}
					setStateData={setState}
					stateData={state}
					title={title?.value?.data?.list}
					onClose={() => {
						setState({ ...state, editBill: false });
					}}
				/>
			)}

			{state.editOpen && (
				<EditDataBills
					itemData={state?.itemData}
					setStateData={setState}
					stateData={state}
					title={title?.value?.data?.list}
					onClose={() => {
						setState({ ...state, editOpen: false });
					}}
				/>
			)}
			{state.openBillModal && (
				<BillNumberModal
					data={state?.newData}
					date={state?.date}
					setStateData={setState}
					stateData={state}
					onClose={() => {
						setState({ ...state, openBillModal: false, newData: [] });
					}}
				/>
			)}
			{state.openBillModal2 && (
				<BillNumberModal2
					data={state?.newData}
					date={state?.date}
					setStateData={setState}
					stateData={state}
					onClose={() => {
						setState({ ...state, openBillModal2: false, newData: [] });
					}}
				/>
			)}

			{billNumberModal && (
				<BillNumberModal
					data={successData}
					onClose={() => {
						setBillNumberModal(false);
						handleClickbackButton();
					}}
				/>
			)}

			<ViewModal
				isOpen={viewModalOpen}
				onClose={() => {
					setViewModalOpen(false);
					setViewModalData('');
				}}
				title='View Fee Details'
				data={viewModalData}>
				<p>View Details.</p>
			</ViewModal>
			<DeleteModal
				isOpen={deleteModalOpen}
				onClose={() => {
					setDeleteModalOpen(false);
					setViewModalData('');
				}}
				title='Delete Fees'
				data={viewModalData}>
				<p>View Details.</p>
			</DeleteModal>
			<CancelModal
				isOpen={cancelModalOpen}
				onClose={() => {
					setCancelModalOpen(false);
					setViewModalData('');
				}}
				title='Delete Fees'
				data={viewModalData}>
				<p>View Details.</p>
			</CancelModal>
		</Main>
	);
};
export default FeesBilling;
