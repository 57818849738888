import React from 'react';
import Select from 'react-select';



const SelectField = ({ id, name, label, options, value, onChange, placeholder }) => (
  <div className="mb-4 mt-8">
    <label htmlFor={id} className="block text-gray-700 text-sm font-bold mb-2">
      {label}
    </label>
    <Select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      classNamePrefix="react-select"
      isSearchable
      placeholder={placeholder}
    />
  </div>
);

export default SelectField;
