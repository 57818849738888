import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Select } from "antd";
import { Formik } from "formik";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getFeeDueList, getMonthlyAttendanceData, getUserListNew } from "../../ApiServices";
import { useAllClasses } from "../../../../queryHooks";
import CustomInput from "../../../../myComp/CustomInput";
import { Body, Footer, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../NecttosComp/Table/Table";
import Loader from "../../../../NecttosComp/Loaders/Loader";
import moment from "moment";
import Input from "../../../../NecttosComp/Input/Input";

export const AttendanceMonthly = ({ onClose }) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const [state, setState] = useState({
    filterValue: "",
    page: 0,
  });
  const allClasses = useAllClasses({ needAll: false });
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const { data: attendanceData = [], isLoading: loading, refetch } = useQuery(["attendanceData666", state.userId, state?.classDetails?._id, state?.startDate, state?.endDate], getMonthlyAttendanceData(collegeId, state?.startDate, state?.endDate, state?.userId, state?.classDetails?._id));

  const { Option } = Select;

  return (
    <>
      <Main title="Attendance Monthly" height="80vh" width="100vw">
        <Header>
          <div className="flex">
            <Input fieldName="Start Date" returnKey="startDate" state={state} setState={setState} type="date" />
            <Input fieldName="End Date" returnKey="endDate" state={state} setState={setState} type="date" />
            <Input fieldName="Select Class" valueShowKey="className" setState={setState} state={state} returnKey="classDetails" didntShowKey optionDisplay="className" options={allClasses?.data} type="drop" width="15vw" />
          </div>
          <div className="flex">
            <Button
              type="fetch"
              onClick={() => {
                refetch();
              }}
            >
              Load
            </Button>
            <Button type="print" tableRef={componentRef.current}>
              Print
            </Button>
            <Button pdfId="emp" type="excel">
              Excell
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body className={classNames.listContainer2}>
          <div className="h-[60vh] overflow-auto printable" ref={componentRef}>
            {attendanceData?.map((monthItem, i) => (
              <div className="single-break">
                <div class="flex flex-row items-center justify-between p-4 border-b-2">
                  <div class="flex items-center">
                    <img class="w-16 h-16 mr-4" src={selectedCollege?.logo} alt="College Logo" />
                    <div>
                      <h1 class="text-xl font-bold h-4">{selectedCollege?.collegeName}</h1>
                      <p class="text-md font-semibold h-1">{selectedCollege?.accreditedGrade}</p>
                    </div>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl font-bold  h-0"> {monthItem.className}</p>
                    <p class="text-sm font-medium h-1">Tutor of the class: {monthItem.tutor}</p>
                    <p class="text-sm font-medium h-1">Head of the department: {monthItem.hod}</p>
                  </div>

                  <div class="text-right">
                    <p class="text-2xl font-bold  h-0">{moment(`${monthItem?.month}-01-${monthItem?.year}`).format("MMMM YYYY")}</p>
                    <p class="text-sm font-medium h-1">Monthly Attendance Register</p>
                    <p class="text-sm font-medium h-1">Print at {moment(new Date()).format("DD MMM YYYY HH:MM A")}</p>
                  </div>
                </div>

                <div style={{ width: "88%", display: "flex", height: 1, backgroundColor: "grey" }}></div>

                <Table innerWidth={[`${85 / (new Array(monthItem?.totalDays).length + 4)}vw`, "15vw"]} width="100vw">
                  <Thead>
                    <Tr>
                      <Th padding="0" position={0} fontSize="14px">
                        SN
                      </Th>
                      <Th padding="0" position={1} fontSize="14px">
                        Name
                      </Th>
                      {new Array(monthItem?.totalDays).fill(1).map((x, i) => (
                        <>
                          <Th padding="0" fontSize="14px" position={0}>
                            {String(i + 1).padStart(2, "0")}
                          </Th>
                        </>
                      ))}
                      <Th padding="0" position={0} fontSize="14px">
                        Prs
                      </Th>
                      <Th padding="0" position={0} fontSize="14px">
                        Tw
                      </Th>
                      <Th padding="0" position={0} fontSize="14px">
                        %
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {monthItem?.data?.map((users, ii) => (
                      <Tr>
                        <Td padding="0" textAlign={"center"} fontSize="14px" index={ii} position={0}>
                          {users.rollNo}
                        </Td>
                        <Td padding="0px 0px 0px 10px" position={1} index={ii} fontSize={users?.name?.split(" ")[0]?.length > 12 ? "13px" : "15px"}>
                          {users?.name}
                        </Td>
                        {new Array(monthItem?.totalDays).fill(1).map((x, i) => (
                          <>
                            <Td padding="0" index={ii} highlight={users[String(i + 1).padStart(2, "0")].isHoliday} position={0}>
                              <h5 style={{ marginTop: 7, color: ["//", "\\", "XX"].includes(users[String(i + 1).padStart(2, "0")].status) ? "green" : users[String(i + 1).padStart(2, "0")].status === "AA" ? "red" : "white", textAlign: "center", alignSelf: "center", fontSize: 15 }}>{users[String(i + 1).padStart(2, "0")].status.slice(0, 1) || users[String(i + 1).padStart(2, "0")].holidayArray[ii]}</h5>
                            </Td>
                          </>
                        ))}
                        <Td padding="0" index={ii} position={0} fontSize="14px" textAlign={"center"}>
                          {users?.prs}
                        </Td>
                        <Td padding="0" index={ii} position={0} fontSize="14px" textAlign={"center"}>
                          {users?.wl}
                        </Td>
                        <Td padding="0" index={ii} position={0} fontSize="13px" textAlign={"center"}>
                          {users?.percentage?.slice(0, 4)}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                {attendanceData?.length - 1 === i && (
                  <div className=" hidden print:flex w-full justify-center items-center">
                    *<hr style={{ width: "80vw" }} />*
                  </div>
                )}
              </div>
            ))}
          </div>
          {loading && <Loader loading={false} />}
        </Body>

        <Footer />
      </Main>
    </>
  );
};
