import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Footer, Header, Main } from "../../NecttosComp/Layout/Layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import Button from "../../NecttosComp/Button/Button";
import { CSVReader } from "react-papaparse";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import toast, { Toaster } from "react-hot-toast";
import Input from "../../NecttosComp/Input/Input";

function HungryFreeCampus({ onClose }: { onClose: () => void }) {
  const tableRef = useRef<any>(null);
  const { collegeId } = useContext(FirebaseContext);
  const [abbrevation, setAbrevation] = useState(false);
  const [csv, setCsv] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [state, setState] = useState<any>({});
  const [detailedView, setDetailedView] = useState<null | any>();

  const fetchData = async () => {
    const instance = await getAxiosTokenInstance();
    instance
      .get("/college/hungry/getData", { params: { collegeId } })
      .then((res) => {
        console.log(res.data.data);
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteElementFrom = (index: number) => {
    if (index >= 0 && index < csvData.length) {
      let newData = [...csvData];
      newData.splice(index, 1);
      setCsvData([...newData]);
    } else {
      console.log("Index out of bounds");
    }
  };

  const validateCSVFile = (item: any[]) => {
    let tempArray: any[] = [];
    item?.forEach((item) => {
      tempArray.push({ data: item?.data });
    });
    setCsvData(tempArray);
  };

  return (
    <>
      <Toaster />
      <Main height="90vh" width="90vw">
        <Header>
          <div className="flex">
            <Input width="300px" fieldName="Start Date" returnKey="startDate" setState={setState} state={state} type="date" />
            <Input width="300px" fieldName="End Date" returnKey="endDate" setState={setState} state={state} type="date" />
          </div>

          <div className="flex">
            <Button type="print" tableRef={tableRef.current}>
              Print
            </Button>
            <Button type="excel" tableRef={tableRef.current}>
              Excel
            </Button>
            <Button
              type="update"
              onClick={() => {
                setCsv(true);
              }}
            >
              Upload CSV
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <Table width="100%" innerWidth={["5%", "15%", "25%", "10%", "25%", "10%", "10%"]}>
            <Thead>
              <Tr>
                <Th position={0}>D</Th>
                <Th position={1}>Name</Th>
                <Th position={2}>Class</Th>
                <Th position={3}>Adm.No.</Th>
                <Th position={4}>Are You Day Scholar or Hosteler? </Th>
                <Th position={5}>Distance </Th>
                <Th position={6}>Status</Th>
              </Tr>
            </Thead>
            <Tbody height="75vh">
              {data?.map((x: any, i: number) => (
                <Tr
                  onClick={() => {
                    setDetailedView(x);
                  }}
                >
                  <Td position={0} index={i}>
                    {i + 1}
                  </Td>
                  <Td position={1} index={i}>
                    {x?.name}
                  </Td>
                  <Td position={2} index={i}>
                    {x?.class}
                  </Td>
                  <Td position={3} index={i}>
                    {x?.admissionNumber}
                  </Td>
                  <Td position={4} index={i}>
                    {x?.criteria7}
                  </Td>
                  <Td position={5} index={i}>
                    {x?.distance}{" "}
                  </Td>
                  <Td position={6} index={i}>
                    {x?.status}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Body>
        <Footer></Footer>
      </Main>

      {csv ? (
        <Main height="90vh" width="90vw">
          <Header>
            <Button
              type="close"
              onClick={() => {
                setCsv(false);
              }}
            >
              Close
            </Button>
            {csvData?.[0] && (
              <div className="flex">
                <Button type="delete" onClick={() => setCsvData([])}>
                  Remove Data
                </Button>

                <form
                  className="flex justify-center items-center"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const instance = await getAxiosTokenInstance();
                    let validation = false;
                    const keysNeeded = ["name", "class", "admissionNumber", "distance", "timeStamp", "email", "department", "tutor", "criteria1", "criteria2", "criteria3", "criteria4", "criteria5", "criteria6", "criteria7"];
                    for (let i in keysNeeded) {
                      validation = false;
                      if (csvData?.[0]?.data?.includes(keysNeeded[i])) {
                        validation = true;
                      } else {
                        toast.error(keysNeeded[i] + " not Found Please Add");
                        break;
                      }
                    }

                    if (validation) {
                      instance
                        .post("/college/hungry/bulkUploadHungryFreeCampus", { data: csvData, collegeId })
                        .then(() => {
                          setCsv(false);
                          setCsvData([]);
                        })
                        .catch((err: any) => {
                          console.log(err);
                        });
                    }
                  }}
                >
                  <label className="flex text-red-800 w-[400px] text-[13px] h-[20px] items-center justify-center">
                    <input required type="checkbox" name="" id="" className="w-[20px]" />
                    The information presented herein is accurate.
                  </label>
                  <Button type="save">Upload Data</Button>
                </form>
              </div>
            )}
          </Header>
          <Body>
            {csvData?.[0] ? (
              <>
                <Table width="100%" innerWidth={[`${100 / (csvData?.[0]?.data?.length + 1)}%`]}>
                  <Thead>
                    <Tr>
                      {csvData?.[0]?.data?.map((x: string | number, index: any) => (
                        <Th position={0}>
                          <div className="flex">
                            <input
                              type="text"
                              className="text-black"
                              value={x}
                              onChange={(e) => {
                                const temp = [...csvData];
                                temp[0].data[index] = e.target.value;
                                setCsvData([...temp]);
                              }}
                            />
                            <button
                              className="p-1 ms-1 bg-red-500 hover:bg-red-700 transition rounded text-[8px] h-5 w-5"
                              onClick={() => {
                                let temp = [...csvData];

                                for (let i in temp) {
                                  temp[i].data?.splice(index, 1);
                                }
                                setCsvData([...temp]);
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Th>
                      ))}
                      <Th position={0}>
                        <Button
                          type="update"
                          onClick={() => {
                            let temp = [...csvData];

                            for (let i in temp) {
                              temp[i].data?.push("");
                            }
                            setCsvData([...temp]);
                          }}
                        >
                          Add Column
                        </Button>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody height="75vh">
                    {csvData?.map((y: any, index: number) => (
                      <>
                        {index !== 0 && (
                          <Tr>
                            {y?.data?.map((x: any, i: number) => (
                              <Td position={0} index={index}>
                                <input
                                  type="text"
                                  className="text-black"
                                  value={x}
                                  onChange={(e) => {
                                    const temp = [...csvData];
                                    temp[index].data[i] = e.target.value;
                                    setCsvData([...temp]);
                                  }}
                                />
                              </Td>
                            ))}

                            <Td position={0} index={index}>
                              <Button
                                type="delete"
                                onClick={() => {
                                  deleteElementFrom(index);
                                }}
                              >
                                Delete
                              </Button>
                            </Td>
                          </Tr>
                        )}
                      </>
                    ))}
                    <Tr>
                      <Button
                        type="update"
                        onClick={() => {
                          let field: string[] = [];

                          csvData?.[0]?.data?.map(() => {
                            field.push("");
                          });
                          setCsvData([...csvData, { data: field }]);
                        }}
                      >
                        Add New data
                      </Button>
                    </Tr>
                  </Tbody>
                </Table>
              </>
            ) : (
              <CSVReader
                onDrop={(val) => {
                  validateCSVFile(val);
                  setAbrevation(true);
                }}
              >
                <p className="text-slate-800 font-extrabold text-[20px]">Click Here</p>
              </CSVReader>
            )}
          </Body>
          <Footer documentOnClick={() => setAbrevation(!abbrevation)} />
        </Main>
      ) : null}

      {abbrevation && (
        <Main title="Learn Necttos">
          <Header>
            <Button type="close" onClick={() => setAbrevation(false)}>
              Close
            </Button>
          </Header>
          <Body>
            <p>Replace All field with these field</p>
            <p>These Field are Required name,class,admissionNumber,distance,timeStamp,email,department,tutor,criteria1,criteria2,criteria3,criteria4,criteria5,criteria6,criteria7</p>
            <ul>
              <li>
                criteria1 :- Have you lost your father/mother? {"("}നിങ്ങളുടെ അച്ഛനോ അമ്മയോ മരിച്ചതാണോ?{")"}{" "}
              </li>
              <li>
                criteria2 :- Do you have only a single parent {"("}or none{")"}? {"("}നിങ്ങളെ വളര്‍ത്തുന്നത് ഒരു രക്ഷിതാവ് ഒറ്റയ്ക്കാണോ?{")"}{" "}
              </li>
              <li>
                criteria3 :- Is your father and/or mother bedridden due to any serious illness and unable to work? {"("}നിങ്ങളുടെ അച്ഛനോ അമ്മയോ കടുത്ത രോഗിയാണോ / രോഗമായി കിടപ്പിലാണോ?{")"}{" "}
              </li>
              <li>
                criteria4 :- Are you differently abled? {"("}നിങ്ങള്‍ക്ക് ഏതെങ്കിലും തരത്തിലുള്ള അംഗപരിമിതി ഉണ്ടോ?{")"}{" "}
              </li>
              <li>
                criteria5 :- Are you suffering from any serious illness? {"("}നിങ്ങള്‍ക്ക് കടുത്ത രോഗങ്ങള്‍ ഉണ്ടോ?{")"}{" "}
              </li>
              <li>
                criteria6 :- Is your family Below Poverty Line {"("}BPL{")"}? {"("}നിങ്ങളുടെ കുടുംബം ദാരിദ്ര്യരേഖയ്ക്ക് താഴെയാണോ?{")"}{" "}
              </li>
              <li>criteria7 :- Are you a day scholar or a hosteler? </li>
            </ul>
          </Body>
        </Main>
      )}

      {detailedView && (
        <Main>
          <Header>
            <Button type="close" onClick={() => setDetailedView(null)}>
              Close
            </Button>
          </Header>
          <Body>
            <div className="h-[75vh]">
              <img src={detailedView?.img} alt={detailedView?.name} className="mb-4 rounded-full" />
              <div className="text-center">
                <h2 className="text-2xl font-bold mb-2">{detailedView?.name}</h2>
                <p className="text-gray-600">Phone Number: {detailedView?.phoneNumber}</p>
                <p className="text-gray-600">Class: {detailedView?.class}</p>
                <p className="text-gray-600">Distance: {detailedView?.distance}</p>
                <p className="text-gray-600">Admission Number: {detailedView?.admissionNumber}</p>
                <p className="text-gray-600">Time Stamp: {detailedView?.timeStamp}</p>
                <p className="text-gray-600">Email: {detailedView?.email}</p>
                <p className="text-gray-600">Department: {detailedView?.department}</p>
                <p className="text-gray-600">Tutor: {detailedView?.tutor}</p>
                <p className="text-gray-600">Criteria 1: {detailedView?.criteria1}</p>
                <p className="text-gray-600">Criteria 2: {detailedView?.criteria2}</p>
                <p className="text-gray-600">Criteria 3: {detailedView?.criteria3}</p>
                <p className="text-gray-600">Criteria 4: {detailedView?.criteria4}</p>
                <p className="text-gray-600">Criteria 5: {detailedView?.criteria5}</p>
                <p className="text-gray-600">Criteria 6: {detailedView?.criteria6}</p>
                <p className="text-gray-600">Criteria 7: {detailedView?.criteria7}</p>
              </div>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default HungryFreeCampus;
