import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select, Table } from "antd";
import message from "antd/lib/message";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getDayBook } from "../../ApiServices";
import moment from "moment";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";
import SearchableImput from "../../LibraryModals/AddBook/DropDownComponent";

export const exportPdfCollectionReport = async (data) => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post(
      `/college/reportGeneration/generateCollectionReport`,
      data,
      {
        responseType: "blob",
      }
    );
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || "API Error"
    );
  }
};

export const DayBook = ({ onClose }) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const [date, setDate] = useState({
    date: "",
  });

  const {
    data = [],
    isFetching,
    refetch,
  } = useQuery(["getDayBoook", date.date], getDayBook(collegeId, date.date));
  const [isLoading, setIsLoading] = useState(false);

  let fromAccounts = data.mainAccounts;
  let toAccounts = data.mainAccounts;
  let denomsSplitup = data.denomsSplitup;
  let billNumber = data.billNumber;

  console.log({toAccounts});

  const { Option } = Select;

  const [state, setState] = useState({
    date: "",
    from: "",
    to: "",
    amount: "",
    naration: "",
  });

  const exportPdfMutationDateCollectionReport = useMutation(
    exportPdfCollectionReport
  );

  const onDateCollectionReport = async () => {
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        startDate: state?.startDate,
        endDate: state?.endDate,
        startTime: state?.startTime,
        endTime: state?.endTime,
        collegeId,
      };

      link.download = state?.endDate;
      const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync(
        postData
      );
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
    } catch (e) {}
  };
  const makeEntry = async (data, actions) => {
    try {
      data = {
        collegeId,
        type: "payment",
        date: state.date,
        from: state.from,
        receiptNo: state.receiptNo,
        to: state.to,
        amount: state.amount,
        paidTo: state.paidTo,
        naration: state.naration,
      };
      setIsLoading(true);
      const postData = data;
      const instance = await getAxiosTokenInstance();
      let resp = await instance.post("/college/fees/accounts", postData);
      message.success(resp?.message || "Journal Posted");
      setState({
        from: "",
        date: "",
        naration: "",
        to: "",
        amount: "",
        receiptNo: "",
      });
      refetch();
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classNames.container}>
      <div className={classNames.modal}>
        <div className={classNames.listContainer}>
          {isLoading ? (
            <LoadingAnimation dark />
          ) : (
            <>
              <div className={classNames.list}>
                <label className={classNames.heading}>Journal Entry</label>

                <Formik
                  enableReinitialize
                  initialValues={{}}
                  onSubmit={(values, formikActions) => {
                    makeEntry(values, formikActions);
                  }}
                >
                  {({ resetForm }) => (
                    <Form className={classNames.feeForm}>
                      <div
                        className={`${classNames.group} ${classNames.formFullWidth}`}
                      >
                        <Field
                          type="date"
                          maxLength={10}
                          name="date"
                          value={state.date}
                          id="date"
                          required
                          onChange={(e) =>
                            setState({ ...state, date: e.target.value })
                          }
                        />
                        <div className={classNames.error}>
                          <ErrorMessage name="date" />
                        </div>
                      </div>
                      <div
                        className={`${classNames.group} ${classNames.formFullWidth}`}
                      >
                        <Select
                          showSearch
                          id="from"
                          className={classNames.formFullWidth2}
                          style={{ overflow: "hidden" }}
                          placeholder="From Account"
                          onChange={(val) => {
                            setState({
                              ...state,
                              from: fromAccounts?.find(
                                (x, i) => x.name + i === val
                              )?._id,
                            });
                          }}
                        >
                          <label>From Account</label>
                          {fromAccounts?.map((item, i) => (
                            <Option key={i} value={item.name + i}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                        <div className={classNames.error}>
                          <ErrorMessage name="from" />
                        </div>
                      </div>

                      <div
                        className={`${classNames.group} ${classNames.formFullWidth}`}
                      >
                        <Select
                          showSearch
                          id="to"
                          className={classNames.formFullWidth2}
                          style={{ overflow: "hidden" }}
                          placeholder="To Account"
                          onChange={(val) => {
                            setState({
                              ...state,
                              to: toAccounts?.find((x, i) => x.name + i === val)?._id,
                            });
                          }}
                        >
                          <label>To Account</label>
                          {toAccounts?.map((item, i) => (
                            <Option key={i} value={item.name + i}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                        <div className={classNames.error}>
                          <ErrorMessage name="to" />
                        </div>
                      </div>

                      <div
                        className={`${classNames.group} ${classNames.formFullWidth}`}
                      >
                        <Field
                          type="number"
                          name="amount"
                          value={state.amount}
                          id="amount"
                          required
                          onChange={(e) =>
                            setState({ ...state, amount: e.target.value })
                          }
                        />
                        <label>Amount</label>
                        <div className={classNames.error}>
                          <ErrorMessage name="amount" />
                        </div>
                      </div>
                      <div style={{ marginTop: 22, width: 320 }}>
                        <SearchableImput
                          keyData={"paidTo"}
                          titleData={"Paid To"}
                          collection={"accounts"}
                          stateData={state}
                          setStateData={setState}
                        />
                      </div>
                      <div style={{ marginTop: 22, width: 320 }}>
                        <SearchableImput
                          keyData={"naration"}
                          titleData={"Naration"}
                          collection={"accounts"}
                          stateData={state}
                          setStateData={setState}
                        />
                      </div>

                      <div
                        className={`${classNames.group} ${classNames.formFullWidth}`}
                      >
                        <Field
                          type="number"
                          name="receiptNo"
                          value={state.receiptNo}
                          id="receiptNo"
                          onChange={(e) =>
                            setState({ ...state, receiptNo: e.target.value })
                          }
                        />
                        <label>Receipt No {billNumber}</label>
                        <div className={classNames.error}>
                          <ErrorMessage name="receiptNo" />
                        </div>
                      </div>

                      <div className={classNames.btns}>
                        <div
                          className={classNames.btnClear}
                          onClick={() => {
                            setState({
                              from: "",
                              date: "",
                              naration: "",
                              to: "",
                              amount: "",
                            });
                          }}
                        >
                          Cancel
                        </div>
                        <div className={classNames.formButtonWidth}>
                          <button type="primary" value="Submit">
                            Post Entry
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

              <div
                className={classNames.denominations}
                style={{ marginTop: 15 }}
              >
                <label className={classNames.heading}>
                  Date: {moment(data.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                </label>
                <label className={classNames.heading}>
                  Rt No: {data.billsData}
                </label>

                <label className={classNames.heading}>Denominations </label>

                {denomsSplitup?.map((item) => (
                  <div className={classNames.subDiv}>
                    <div>
                      <label className={classNames.subHeads}>
                        {item?.item}
                      </label>
                    </div>
                    <div>
                      <label className={classNames.subAmounts}>X</label>
                    </div>
                    <div>
                      <label className={classNames.subHeads}>
                        {item?.count}
                      </label>
                    </div>
                    <div>
                      <label className={classNames.subAmounts}>=</label>
                    </div>
                    <div>
                      <label className={classNames.subHeads}>
                        {item?.total}
                      </label>
                    </div>
                  </div>
                ))}

                <label className={classNames.heading}>
                  Grand Total: {data.total}
                </label>
              </div>
            </>
          )}
        </div>

        <div className={classNames.listContainer2}>
          <div className={classNames.title}>
            <label className={classNames.heading}>
              Day Book as on {moment(data?.date).format("ddd DD MMM YYYY")}
            </label>
          </div>

          <Scrollbars style={{ height: "90%" }}>
            <div ref={componentRef} className="printable">
              <img
                style={{
                  position: "absolute",
                  width: "80%",
                  marginTop: 350,
                  marginLeft: 100,
                  marginRight: 200,
                  opacity: 0.12,
                  zIndex: -1,
                }}
                src={selectedCollege?.logo}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "25px",
                  padding: "1%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: "25px",
                  }}
                >
                  <img width="12%" height="12%" src={selectedCollege?.logo} />
                  <div
                    style={{
                      width: "80%",
                      alignItems: "center",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{ fontSize: "30px", margin: 0, fontWeight: 900 }}
                    >
                      {selectedCollege?.collegeShortName}
                    </span>
                    <p style={{ fontSize: "25px", margin: 0, fontWeight: 900 }}>
                      {selectedCollege?.accreditedGrade}
                    </p>
                    <p style={{ fontSize: "14px", margin: 0, fontWeight: 900 }}>
                      {selectedCollege?.address}, Phone:{" "}
                      {selectedCollege?.phoneNumber}
                    </p>
                  </div>
                </div>
                <h5
                  style={{
                    margin: 0,
                    fontSize: "30px",
                    width: "100%",
                    alignSelf: "left",
                  }}
                >
                  Issue Register
                </h5>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    paddingRight: 12,
                    flexDirection: "row",
                    height: 30,
                    margin: 0,
                    backgroundColor: "black",
                    alignItems: "center",
                    alignSelf: "center",
                    marginBottom: 12,
                  }}
                >
                  <h5
                    style={{
                      marginTop: 6,
                      color: "white",
                      textAlign: "center",
                      fontSize: 16,
                      width: "10%",
                    }}
                  >
                    Sn
                  </h5>
                  <h5
                    style={{
                      marginTop: 6,
                      color: "white",
                      alignSelf: "left",
                      fontSize: 16,
                      width: "40%",
                    }}
                  >
                    Account
                  </h5>
                  <h5
                    style={{
                      marginTop: 6,
                      color: "white",
                      textAlign: "right",
                      fontSize: 16,
                      width: "10%",
                    }}
                  >
                    Op. Balance
                  </h5>
                  <h5
                    style={{
                      marginTop: 6,
                      color: "white",
                      textAlign: "right",
                      fontSize: 16,
                      width: "10%",
                    }}
                  >
                    Reciept
                  </h5>
                  <h5
                    style={{
                      marginTop: 6,
                      color: "white",
                      textAlign: "right",
                      fontSize: 16,
                      width: "10%",
                    }}
                  >
                    Payments
                  </h5>
                  <h5
                    style={{
                      marginTop: 6,
                      color: "white",
                      textAlign: "right",
                      fontSize: 16,
                      width: "10%",
                    }}
                  >
                    Refund
                  </h5>
                  <h5
                    style={{
                      marginTop: 6,
                      color: "white",
                      textAlign: "right",
                      fontSize: 16,
                      width: "10%",
                    }}
                  >
                    Cl. Balance
                  </h5>
                </div>
                {data?.dayBook?.map((item, i) => (
                  <>
                    <div
                      style={{
                        width: "100%",
                        paddingRight: 12,
                        display: "flex",
                        height: " 40px",
                        flexDirection: "row",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <h5
                        style={{
                          marginRight: 4,
                          color: "black",
                          textAlign: "center",
                          fontSize: 19,
                          width: "10%",
                        }}
                      >
                        {i + 1}
                      </h5>
                      <h5
                        style={{
                          marginRight: 4,
                          color: "black",
                          alignSelf: "left",
                          fontSize: 19,
                          width: "40%",
                        }}
                      >
                        {item.name}
                      </h5>
                      <h5
                        style={{
                          marginRight: 4,
                          color: "black",
                          textAlign: "right",
                          fontSize: 19,
                          width: "10%",
                        }}
                      >
                        {item?.totalActualOpeingBalance?.toFixed(2)}
                      </h5>
                      <h5
                        style={{
                          marginRight: 4,
                          color: "black",
                          textAlign: "right",
                          fontSize: 19,
                          width: "10%",
                        }}
                      >
                        {item?.totalCurrentAmount?.toFixed(2)}
                      </h5>
                      <h5
                        style={{
                          marginRight: 4,
                          color: "black",
                          textAlign: "right",
                          fontSize: 19,
                          width: "10%",
                        }}
                      >
                        {item?.currentPayment?.toFixed(2)}
                      </h5>
                      <h5
                        style={{
                          marginRight: 4,
                          color: "black",
                          textAlign: "right",
                          fontSize: 19,
                          width: "10%",
                        }}
                      >
                        {item?.currentRefund?.toFixed(2)}
                      </h5>
                      <h5
                        style={{
                          marginRight: 4,
                          color: "black",
                          textAlign: "right",
                          fontSize: 19,
                          width: "10%",
                        }}
                      >
                        {item?.totalClossingBalance?.toFixed(2)}
                      </h5>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        height: 1,
                        backgroundColor: "black",
                      }}
                    ></div>
                  </>
                ))}
                <h5
                  style={{
                    marginTop: 12,
                    textAlign: "right",
                    color: "black",
                    fontSize: 20,
                  }}
                >
                  Seal & Signature{" "}
                  {moment(new Date()).format("DD MMM YYYY hh:mm A")}
                </h5>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
      <div className={classNames.subDiv}>
        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`}>
            <Field
              type="date"
              name="date"
              id="date"
              value={date.date || data?.date}
              onChange={(e) => {
                setDate({ ...date, date: e.target.value });
              }}
            />
          </div>
        </Formik>
      </div>

      <Button
        className={classNames?.reloadButton}
        onClick={() => {
          refetch();
        }}
      >
        Reload
      </Button>
      <ReactToPrint
        trigger={() => (
          <button className={classNames?.printBotton}>Print</button>
        )}
        content={() => componentRef.current}
      />
      {!isLoading && (
        <Button className={classNames?.closeButton} onClick={onClose}>
          Close
        </Button>
      )}
    </div>
  );
};
