import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { JournalEntry } from '../../pages/college/Accounts/IncomeExpenditure/DayBook';
import { Table, Tbody, Td, Tfoot, TfootTd, TfootTr, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { Body, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import moment from 'moment';
import { getRecieptPayments } from '../../pages/college/ApiServices';
import { useQuery } from 'react-query';
import RecieptPaymentSubNew from './RecieptPaymentSubNew';
import { DNA, Grid, InfinitySpin } from 'react-loader-spinner';

export const RecieptsPayments = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const componentRef = useRef();
	const [date, setDate] = useState({ date: '', type: 'Government', subType: '' });
	const [autoFetch, setAutoFetch] = useState(false);
	const { data = [], refetch, loading, isLoading, isFetching, isSuccess } = useQuery(['getRecieptPayments', autoFetch], getRecieptPayments(collegeId, date.date, date.type, date.subType, date.endDate));

	useEffect(() => {
		setAutoFetch(true);
		setTimeout(() => {
			setAutoFetch(false);
		}, 100);
	}, [collegeId, date.date, date.type, date.subType, date.endDate]);
	const { tableItems, requestSort, getIcon } = useSortableData(data?.selectedAccountData);
	let toAccounts = data?.types;
	let toSubAccounts = ['All Accounts', ...(data?.subTypes || [])];

	console.log({ isLoading, isFetching, isSuccess });

	console.log({ receipts: data });

	return (
		<>
			<Main title={'Reciepts & Payments A/C'} height={'100%'} width={'100%'}>
				<Header width='100%'>
					<div className='flex flex-wrap items-center'>
						<Input type='date' state={date} fieldName='Start Date' returnKey='date' setState={setDate} />
						<Input type='date' state={date} returnKey='endDate' fieldName='End Date' setState={setDate} />
						<Input type='drop' options={toAccounts} state={date} fieldName='Main Head' returnKey='type' setState={setDate} />
						<Input type='drop' options={toSubAccounts} state={date} fieldName='Sub Head' returnKey='subType' setState={setDate} />
						<Button type='fetch' onClick={refetch}>
							Proceed
						</Button>
					</div>
					<div className='flex flex-wrap items-center'>
						<Button
							type='save'
							onClick={() => {
								setDate({
									...date,

									showEdit: true,
								});
							}}>
							Post New Journal Entry
						</Button>
						<Button type='print' tableRef={componentRef.current}>
							Print
						</Button>
						<Button type='excel' tableRef={componentRef.current}>
							Excel
						</Button>
						<Button type='pdf' tableRef={componentRef.current} pdfId='accounts'>
							PDF
						</Button>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(onClose, 250);
							}}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={componentRef} className='printable '>
						<div class='flex flex-row items-center justify-between p-4 border-b-2'>
							<div class='flex items-center'>
								<img class='w-16 h-16 mr-4' src={selectedCollege?.logo} alt='College Logo' />
								<div>
									<h1 class='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
									<p class='text-md font-semibold h-1'>{selectedCollege?.accreditedGrade}</p>
									<p class='text-md font-semibold h-1'>{selectedCollege?.address}</p>
								</div>
							</div>

							<div class='text-right'>
								<p class='text-xl font-medium h-0'>Income & Expenditure A/C</p>
								<p class='text-md font-medium h-1'>
									{date.type} - {date.subType}
								</p>
								<p class='text-sm font-medium h-1'>
									{moment(date.date).format('DD MMM YYYY')} to {moment(date.endDate).format('DD MMM YYYY')}
								</p>
								<p class='text-sm font-medium h-1'>Print at {moment(new Date()).format('DD MMM YYYY HH:MM A')}</p>
							</div>
						</div>
						<div className='flex justify-center items-center'>
							<Table id={'accounts'} width={'99%'} innerWidth={['10%', '50%', '10%', '10%']}>
								<Thead>
									<Tr>
										<Th position={0} item={'SN'} />
										<Th position={1} onClick={() => requestSort('accountName')} icon={getIcon('accountName')} item={'Heads'} />
										<Th position={3} onClick={() => requestSort('recevedCash')} icon={getIcon('recevedCash')} item={'Cash Reciept'} />
										<Th position={2} onClick={() => requestSort('recievedBank')} icon={getIcon('recievedBank')} item={'Bank Reciept'} />
										<Th position={3} onClick={() => requestSort('paidCash')} icon={getIcon('paidCash')} item={'Cash Payment'} />
										<Th position={2} onClick={() => requestSort('paidBank')} icon={getIcon('paidBank')} item={'Bank Payment'} />
									</Tr>
								</Thead>
								<Tfoot>
									<TfootTr>
										<TfootTd merge={2} position={0} item={'Opening Balance'} />
										<TfootTd position={2} item={data?.openingCashBalance?.toFixed(2) || 0} />
										<TfootTd position={3} item={data?.openingBankBalance?.toFixed(2) || 0} />
										<TfootTd position={2} item='' />
										<TfootTd position={3} item='' />
									</TfootTr>
								</Tfoot>
								<Tbody height={'50vh'}>
									{isFetching ? (
										<div className='m-5 p-5 flex justify-center items-center'>
											<Grid height={'250'} width='250' color='#0083f5' visible={isFetching} />
										</div>
									) : (
										<>
											{tableItems?.map((item, i) => (
												<Tr key={i} style={{ cursor: 'pointer', backgroundColor: item.elective === 'Discountined' ? '#ff8f87' : '' }}>
													<Td position={0} index={i} item={i + 1} />
													<Td position={1} textAlign={'left'} fontSize={'16px'} index={i} item={item?.accountName} />
													<Td
														position={2}
														textAlign={'right'}
														fontSize={'16px'}
														index={i}
														item={item?.recevedCash === 0 ? '' : item?.recevedCash?.toFixed(2)}
														onClick={() => {
															setDate({ ...date, data: item.recevedCashDetails, total: item?.recevedCash?.toFixed(2), itemName: item?.accountName + ' Recievable @ Cash A/C' });
															refetch();
														}}
													/>
													<Td
														position={3}
														textAlign={'right'}
														fontSize={'16px'}
														index={i}
														item={item?.recievedBank === 0 ? '' : item?.recievedBank?.toFixed(2)}
														onClick={() => {
															setDate({ ...date, data: item.recievedBankDetails, total: item?.recievedBank?.toFixed(2), itemName: item?.accountName + ' Recievable @ Bank A/C' });
															refetch();
														}}
													/>
													<Td
														position={2}
														textAlign={'right'}
														fontSize={'16px'}
														index={i}
														item={item?.paidCash === 0 ? '' : item?.paidCash?.toFixed(2)}
														onClick={() => {
															setDate({ ...date, data: item.paidCashDetails, total: item?.paidCash?.toFixed(2), itemName: item?.accountName + ' Payable @ Cash A/C' });
															refetch();
														}}
													/>
													<Td
														position={3}
														textAlign={'right'}
														fontSize={'16px'}
														index={i}
														item={item?.paidBank === 0 ? '' : item?.paidBank?.toFixed(2)}
														onClick={() => {
															setDate({ ...date, data: item.paidBankDetails, total: item?.paidBank?.toFixed(2), itemName: item?.accountName + ' Payable @ Bank A/C' });
															refetch();
														}}
													/>
												</Tr>
											))}
										</>
									)}
								</Tbody>
								<Tfoot>
									<TfootTr>
										<TfootTd merge={2} position={0} item={'Clossing Balance'} />
										<TfootTd position={2} item='' />
										<TfootTd position={3} item='' />
										<TfootTd position={2} item={data?.clossingBalanceCash?.toFixed(2) || 0} />
										<TfootTd position={3} item={data?.clossingBalanceBank?.toFixed(2) || 0} />
									</TfootTr>
								</Tfoot>
							</Table>
						</div>
					</div>
				</Body>
			</Main>
			{date.showEdit && <JournalEntry onClose={() => setDate({ ...date, showEdit: false, values: {} })} datavalues={date.values} setStateData={setDate} stateData={date} />}
			{date?.data?.length > 0 && <RecieptPaymentSubNew date={date} setDate={setDate} account={date.itemName} />}
		</>
	);
};
