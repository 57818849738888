import React, { useContext, useEffect } from "react";
import { Switch, Route, BrowserRouter, useHistory } from "react-router-dom";
import MasterTable from "./pages/MasterTable";
import AddUserPage from "./pages/AddUserPage";

import { FirebaseContext } from "./context/FirebaseContext";
import LoadingAnimation from "./components/LoadingAnimation";
import TimeTablePage from "./pages/TimeTablePage";

import FeeManagement from "./pages/FeeManagement";

import LibraryReports from "./NecttosApp/windows";
import SupperVision from "./pages/supperVission";
import Loader from "./pages/Login/Loader";
import Input from "./NecttosComp/Input/Input";

function DashRoutes() {
  const { userLoading, user } = useContext(FirebaseContext);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      window.location.href = "/";
    }
  }, []);

  if (userLoading) {
    return <LoadingAnimation />;
  } else if (!user) {
    history.push("/");
  }
  return (
    <BrowserRouter basename="/app">
      {userLoading ? (
        <Loader />
      ) : (
        <Switch>
          <Route exact path="/" component={LibraryReports} />
          <Route exact path="/supperVission" component={SupperVision} />
          <Route exact path="/master" component={MasterTable} />
          <Route exact path="/addUser" component={AddUserPage} />
          {/* <Route exact path="/admission" component={AdmissionPage} /> */}
          {/* <Route exact path="/libraryReports" component={LibraryReports} /> */}
          <Route exact path="/timetable" component={TimeTablePage} />

          {/* <Route exact path="/library" component={LibraryHomePage} /> */}
          {/* <Route
          exact
          path="/library/:userId/userDetails"
          component={LibraryUserPage}
        />
        <Route
          exact
          path="/library/:userId/userCollect"
          component={LibraryUserCollect}
        />
        <Route
          exact
          path="/library/:userId/userSell"
          component={LibraryUserSell}
        />
        <Route
          exact
          path="/library/:userId/collectBook/:bookStocksId/user"
          component={LibraryCollectBook}
        />
        <Route
          exact
          path="/library/:userId/sellBook/:bookStocksId/user"
          component={LibrarySellBook}
        /> */}
          <Route exact path="/feeManagement" component={FeeManagement} />
        </Switch>
      )}
    </BrowserRouter>
  );
}

export default DashRoutes;
