import React, { useEffect, useState } from "react";
import classNames from "./addUserPage.module.scss";
import AddUserForm from "../../components/AddUserForm";
import AddStudentTable from "../../components/AddStudentTable";
import { Body, Footer, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";

function AddUserPage({ onClose }) {
  const [refreshStatus, setRefreshStatus] = useState(false);

  const [userType, setUserType] = useState("");
  const [classId, setClassId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [admisionNo, setAdmisionNo] = useState("");
  const [id, setId] = useState("");

  const selectUser = (data) => {
    setUserType("student");
    setClassId(data.classId);
    setDepartmentId(data.departmentId);
    setName(data.name);
    setPhoneNumber(data.phoneNumber?.replace("+91", ""));
    setAdmisionNo(data.admisionNo);
    setId(data.id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Main width="30vw" title="Add New">
        <Header>
          <div className="flex flex-wrap items-center"></div>
          <div className="flex flex-wrap windows-center">
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body>
          {/* <AddStudentTable selectUser={selectUser} toggleRefresh={() => setRefreshStatus(!refreshStatus)} refreshStatus={refreshStatus} /> */}
          <AddUserForm classId={classId} setClassId={(e) => setClassId(e)} departmentId={departmentId} userType={userType} setUserType={(e) => setUserType(e)} setDepartmentId={(e) => setDepartmentId(e)} name={name} setName={(e) => setName(e)} phoneNumber={phoneNumber} setPhoneNumber={(e) => setPhoneNumber(e)} admisionNo={admisionNo} setAdmisionNo={(e) => setAdmisionNo(e)} setId={(e) => setId(e)} id={id} toggleRefresh={() => setRefreshStatus(!refreshStatus)} />
          <div style={{ height: "5vh" }}></div>
        </Body>
        <Footer />
      </Main>
    </>
  );
}

export default AddUserPage;
