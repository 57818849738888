import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import TimeTableTeacherSelectModal from "../TimeTableTeacherSelectModal";
import ClassRow from "./ClassRow";
import DayPeriodComponent from "./DayPeriodComponent";
import classNames from "./masterTimetable.module.scss";
import { SettingOutlined } from "@ant-design/icons";
import TimeShedulePage from "../../pages/TimeShedulePage";
import message from "antd/lib/message";

const getTeachers = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_, { collegeId }] = queryKey;
  const instance = await getAxiosTokenInstance();
  let resp;
  if (collegeId)
    try {
      resp = await instance.get("college/timetable/getTeachers", {
        params: { collegeId },
      });

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error) {
      throw new Error(error?.response?.data?.message || error.message || "API Error");
    }

  return resp?.data;
};

function MasterTimetable({ data, refetch }) {
  const [classNamesArray, setClassNames] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const { collegeId } = useContext(FirebaseContext);
  const [days] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
  const teachersQuery = useQuery(["teachers", { collegeId: collegeId }], getTeachers);
  useEffect(() => {
    setClassNames(data?.list?.map((cls) => cls.className));
  }, [data]);

  const resetFromMasterTable = () => {
    getAxiosTokenInstance().then((instance) => {
      instance
        .post("/college/timetable/resetTimetable", {
          collegeId: collegeId,
        })
        .then(({ data }) => {
          message.success({
            content: data.message,
            key: "status",
          });
          refetch();
        })
        .catch((error) => {
          message.error({
            content: error?.response?.data?.message || error?.message || "API Error",
            key: "status",
          });
        });
    });
  };

  const loadToMasterTable = () => {
    getAxiosTokenInstance().then((instance) => {
      instance
        .post("/college/timetable/setMasterTimetable", {
          collegeId: collegeId,
        })
        .then(({ data }) => {
          message.success({
            content: data.message,
            key: "status",
          });
          refetch();
        })
        .catch((error) => {
          message.error({
            content: error?.response?.data?.message || error?.message || "API Error",
            key: "status",
          });
        });
    });
  };

  return (
    <>
      <div className="flex items-center space-x-5">
        <div className="px-2.5 py-2 bg-blue-900 text-white font-bold rounded-lg cursor-pointer" onClick={() => loadToMasterTable()}>
          Load To Master Table
        </div>
        <div className="px-2.5 py-2 bg-blue-900 text-white font-bold rounded-lg cursor-pointer" onClick={() => resetFromMasterTable()}>
          Reset From Master Table
        </div>
        <div className="px-2.5 py-2 bg-blue-900 text-white font-bold rounded-lg cursor-pointer" onClick={() => setOpenSettings(true)}>
          Period Management
        </div>
      </div>

      <div className={`${classNames.masterTableWrap}`}>
        <table>
          <DayPeriodComponent classes={classNamesArray} periods={data?.maxLength} />
          {days?.map((day, i) => (
            <ClassRow key={i} day={day} classes={classNamesArray} setModalOpen={setModalOpen} maxPeriods={data?.maxLength} list={data?.list} />
          ))}
        </table>
      </div>
      {modalOpen && <TimeTableTeacherSelectModal data={teachersQuery?.data} loading={teachersQuery?.isLoading} modalOpen={modalOpen} onClose={() => setModalOpen(false)} refetch={refetch} />}
      {openSettings && <TimeShedulePage setOpenSettings={setOpenSettings} />}
    </>
  );
}

export default MasterTimetable;
