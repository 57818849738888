import React, { useEffect, useState } from "react";
import { getAxiosTokenInstance } from "../../../../../../utils/axiosInstance";
import CustomDropDown from "../../../../../../myComp/CustomDropDown";
import Button from "../../../../../../NecttosComp/Button/Button";

function EditOrAdd({ tutor, refetch, collegeId, classId, semester, data, setAssign }) {
  const convertDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const [edit, setEdit] = useState({});

  useEffect(() => {
    setEdit({
      hodName: data?.hodName,
      hod: { name: data?.hodName, _id: data?.hodId },
      classTeacher: { name: data?.classTeacherName, _id: data?.classTeacherId },
      classTeacher2: {
        name: data?.classTeacherName,
        _id: data?.classTeacherId,
      },
      startingDate: data?.startingDate ? convertDate(data?.startingDate) : "",
      closingDate: data?.closingDate ? convertDate(data?.closingDate) : "",
    });
  }, [data]);

  const updateSemester = async () => {
    const instance = await getAxiosTokenInstance();
    instance
      .post(
        "/college/semester/updateSemester",
        {
          hod: edit?.hod._id,
          classTeacher: edit?.classTeacher?._id,
          classTeacher2: edit?.classTeacher2?._id,
          startingDate: edit?.startingDate,
          closingDate: edit?.closingDate,
        },
        {
          params: {
            collegeId,
            classId,
            semester: semester - 1,
          },
        }
      )
      .then((res) => {
        refetch();
        setAssign();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col space-y-4 p-4 bg-white shadow rounded-lg max-w-lg mx-auto md:max-w-2xl">
      <div className="flex justify-end">
        <button type="button" className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2" onClick={() => setAssign()}>
          Back
        </button>
      </div>

      <select
        className="p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
        name="hod"
        value={edit?.hod?._id}
        onChange={(e) => {
          setEdit({
            ...edit,
            hod: tutor.filter((x) => x?._id === e.target.value)[0],
          });
        }}
      >
        <option value="">Select HOD</option>
        {tutor?.map((x) => (
          <option key={x._id} value={x._id}>
            {x.name}
          </option>
        ))}
      </select>

      <select
        className="p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
        name="classTeacher"
        value={edit?.classTeacher?._id}
        onChange={(e) =>
          setEdit({
            ...edit,
            classTeacher: tutor.filter((x) => x._id === e.target.value)[0],
          })
        }
      >
        <option value="">Select Class Teacher</option>
        {tutor?.map((x) => (
          <option key={x._id} value={x._id}>
            {x.name}
          </option>
        ))}
      </select>

      <select
        className="p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
        name="classTeacher2"
        value={edit?.classTeacher2?._id}
        onChange={(e) =>
          setEdit({
            ...edit,
            classTeacher2: tutor.filter((x) => x._id === e.target.value)[0],
          })
        }
      >
        <option value="">Select 2nd Tutor</option>
        {tutor?.map((x) => (
          <option key={x._id} value={x._id}>
            {x.name}
          </option>
        ))}
      </select>

      <input className="p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out" type="date" name="startingDate" value={edit.startingDate} onChange={(e) => setEdit({ ...edit, startingDate: e.target.value })} />

      <input className="p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out" type="date" name="closingDate" value={edit.closingDate} onChange={(e) => setEdit({ ...edit, closingDate: e.target.value })} />

      <button className="mt-4 px-4 py-2 w-full bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out" onClick={updateSemester}>
        Submit
      </button>
    </div>
  );
}

export default EditOrAdd;
