import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getAxiosInstance } from "../utils/axiosInstance";
import toast, { Toaster } from "react-hot-toast";
import { FirebaseContext } from "../context/FirebaseContext";
import Loader from "../NecttosComp/Loaders/Loader";

function CollegeLogin() {
  const param: { id: any } = useParams();
  const [isSendOtp, setSendOtp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [confirm, setConfirm] = useState<any>();
  const [filledData, setFilledData] = useState({ mobile: "" });
  const [collegeData, setCollegeData] = useState<any>();
  const { collegeList, setCollegeId } = useContext(FirebaseContext);
  const history = useHistory();

  const inputRefs: any = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleInputChange = (index: any, e: any) => {
    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1]?.current?.focus();
     
    }
    let temp = [...otp];
    temp[index] = e.target.value;
    setOtp([...temp]);
    if (index === 5) {
      verfiyOtp(temp.join(""));
    }
  };

  const getCollege = async () => {
    setIsLoading(true);
    const instance = await getAxiosInstance();
    instance
      .get("/website/getCollegeName", { params: { collegeId: param.id } })
      .then((res) => {
        if (res?.data?.data?.[0]) {
          setCollegeData(res.data.data[0]);
        } else {
          history.push("/");
        }
        setIsLoading(false);
      })
      .catch(() => {
        console.log("something went wrong");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const user = localStorage.getItem("userDetails");
      if (user) {
        setCollegeId(param.id);
        history.push("/app");
      }
    }
  }, []);

  const verfiyOtp = async (otp: string) => {
    setIsLoading(true);
    const instance = await getAxiosInstance();
    instance
      .post(`/auth/verify-otp`, { token: confirm, otp })
      .then(async (response) => {
        localStorage.setItem("userToken", response.data.jwtToken);

        console.log(response.data);

        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            phoneNumber: response.data.phoneNumber,
            username: response.data.userName,
            displayName: response.data.userName,
            uid: response.data.uid,
          })
        );
        setIsLoading(false);
        setCollegeId(param.id);
        history.push("/app");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        setIsLoading(false);
      });
  };

  const login = async () => {
    const instance = await getAxiosInstance();
    if (filledData.mobile) {
      instance
        .post(`/auth/sent-otp`, {
          phoneNumber: `+91${filledData.mobile}`,
          collegeId: param.id,
        })
        .then((response) => {
          const elem = document.getElementById("loginForm");
          if (elem) {
            elem.style.height = "350px";
            const otp = document.getElementById("otp");
            if (otp) {
              otp.style.display = "flex";
            }
          }
          let second = 300;
          let intervalId = setInterval(() => {
            second = second - 1;
            setSendOtp(second);
            if (second <= 0) {
              clearInterval(intervalId);
            }
          }, 1000);
          setConfirm(response.data.token);
          console.log(response);
          toast.success("OTP Sended");
        })
        .catch((err) => {
          toast.error("Your Not authorized ");
        });
    } else {
      toast.error("Please give Phone number");
    }
  };

  useEffect(() => {
    getCollege();
  }, []);
  return (
    <>
      <div
        className="w-screen bg-violet-600 h-screen flex justify-center items-center"
        style={{
          backgroundImage:
            "url(https://github.com/bedimcode/login-form/blob/main/assets/img/login-bg.png?raw=true)",
          backgroundPosition: "center",
        }}
      >
        <h3 className="absolute text-white top-4 w-full text-center">
          Welcome to {collegeData?.collegeName || "Necttos"}
        </h3>
        <Toaster />
        <div
          id="loginForm"
          className="w-[500px] flex flex-col h-[250px] transition-all duration-100 border border-white justify-between bg-[#f1f1f12c] backdrop-blur-2xl rounded-2xl"
          style={{ backdropFilter: "blur(8px)" }}
        >
          <h1 className="text-white text-center pt-4">Login</h1>
          <div>
            <label
              htmlFor=""
              className="border border-white flex justify-between items-center m-3 py-3 px-3 rounded-3xl"
            >
              <input
                type="text"
                value={filledData.mobile}
                placeholder="Phone Number"
                onChange={(e) => {
                  setFilledData({ ...filledData, mobile: e.target.value });
                }}
                className="bg-none text-white outline-none border-none placeholder:text-white"
              />
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-telephone-fill"
                viewBox="0 0 16 16"
              >
                
                <path
                  fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  fill="white"
                />
              </svg>
            </label>
            <h6
              className={`text-end px-3 ${
                isSendOtp < 1
                  ? "text-white  cursor-pointer"
                  : "text-gray-400 cursor-default"
              }  `}
              onClick={() => {
                if (isSendOtp < 1) {
                  login();
                }
              }}
            >
              Send OTP
            </h6>
            <label
              htmlFor=""
              id="otp"
              className="border border-white transition-all duration-150 hidden justify-between items-center m-3 py-3 px-3 rounded-3xl"
            >
              <div className="flex items-center justify-between w-3/4">
                {inputRefs.map((inputRef: any, index: any) => (
                  <input
                    key={index}
                    type="text"
                    className="bg-none text-white outline-none border border-white h-10 w-10 text-center placeholder:text-white"
                    maxLength={1}
                    value={otp[index]}
                    onChange={(e) => handleInputChange(index, e)}
                    onKeyDown={(e) => {
                      const keyPressed = e.key;
                      if (keyPressed === "Backspace") {
                        if (index > 0) {
                          inputRefs[index - 1]?.current?.focus();
                        }
                      }
                      console.log(keyPressed, "hello");
                    }}
                    ref={inputRef}
                  />
                ))}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="#ffffff"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zm-7 7.732V18h2v-2.268a2 2 0 1 0-2 0zM16 8V7a4 4 0 1 0-8 0v1h8z" />
                </g>
              </svg>
            </label>
            {isSendOtp > 0 && (
              <div className="flex px-3 justify-between">
                <p className="text-white text-end ">{isSendOtp}</p>
              </div>
            )}
          </div>
        </div>
          
        <h6 className="absolute right-3 bottom-3 text-end text-white">
          Powered by Necttos
        </h6>
      </div>
      {isLoading && <Loader loading={false} />}
    </>
  );
}

export default CollegeLogin;
