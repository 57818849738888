import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';


class Contact_Info extends Component {
    render() {
        return <div>
            <div className="map-area pd-top-120">
                <div className="container">
                    <div className="map-area-wrap">
                        <div className="row no-gutters">
                            <div className="col-lg-8">
                                <iframe title="necttos-map" width="1075" height="440" src="https://maps.google.com/maps?q=10.785054464070333, 76.2277079018439&t=&z=12&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                            </div>
                            <div className="col-lg-4 desktop-center-item">
                                <div>
                                    <div className="contact-info">
                                        <h4 className="title">{sectiondata.contactinfo.title}</h4>
                                        <p className="sub-title">{sectiondata.contactinfo.description}</p>
                                        <p><span>Address:</span> {parse(sectiondata.contactinfo.address)}</p>
                                        <p><span>Mobile:</span> {sectiondata.contactinfo.mobile}</p>
                                        <p><span>E-mail:</span> {sectiondata.contactinfo.mail}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    }
}

export default Contact_Info