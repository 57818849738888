import React, { useContext, useState } from "react";
import DashLayout from "../../layout/DashLayout";
import classNames from "./feeManagement.module.scss";
import { FOOTER_ICONS_MAIN } from "../../config/constants";
import FeesTable from "./components/FeesTable";
import FastCash from "./components/FastCash";
import StudentFeeDetail from "./components/StudentFeeDetail";
import FeeAdd from "./components/FeesAdd";
import { FirebaseContext } from "../../context/FirebaseContext";
import FeesPrintTable from "./components/FeesPrintTable";
import { FeesReportModal } from "./components/FeesReportModal";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useMutation } from "react-query";
import RefundFeeDetails from "./components/RefundFeeDetails";
import AddFeesForm from "./components/FeesAdd";
import { BillNumberModal } from "./components/BillNumberModal";
import { Reports } from "./components/Reports";
import { DuesReport } from "./components/DuesReport";



export const exportPdfCollectionReport = async (data) => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post(`/college/reportGeneration/generateCollectionReport`, data, {
      responseType: "blob",
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || "API Error"
    );
  }
};



export default function FeeManagement({onClose}) {
  const { collegeId } = useContext(FirebaseContext);

  const [search, setSearch] = useState("");
  const [feesListShow, setFeesListShow] = useState(false);
  const [studentDetails, setstudentDetails] = useState({});
  const [billNumberModal, setBillNumberModal] = useState(false)
  const [successData, setSuccessData] = useState()

  const [printTable, setPrintTable] = useState(true);
  const [feeSummary, setFeeSummary] = useState({});
  const [feeReportModal, setFeeReportModal] = useState(false);
  const [feeDueReportModal, setFeeDueReportModal] = useState(false);
  const [dayBook, setDayBook] = useState(false);
  const [cashBook, setCashBook] = useState(false);
  const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);


  const handleCollect = (studentData) => {
    setFeeSummary({});
    setstudentDetails({ ...studentData });
  };

  const handleFastCashCollect = (studentData) => {
    let totalFee = {};
    studentData?.groups?.forEach((grp) => {
      grp.dues?.forEach((due) => {
        totalFee = {
          ...totalFee,
          [grp.feeType]: [
            ...(totalFee[grp.feeType] || []),
            {
              dueId: due.dueId,
              title: due.title,
              fee: due.amount,
            },
          ],
        };
      });
    });
    setstudentDetails({ ...studentData });
    setFeeSummary(totalFee);
  };

  const handleClickbackButton = (data) => {


    setstudentDetails({});
    if (data) {
      setBillNumberModal(data && true)
      setSuccessData(data)
    }

  };

  const showAddFees = () => {
    setFeesListShow(true);
  };

  const showFastCashList = () => {
    setFeesListShow(false);
  };


  const onDateCollectionReport = async (data) => {
    setFeeReportModal(false);
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        startDate: data?.startDate,
        endDate: data?.endDate,
        startTime: data?.startTime,
        endTime: data?.endTime,
        collegeId,
      };

      link.download = data?.endDate;
      const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync(postData);
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
    } catch (e) { }
  };
  return (
    <DashLayout
      footers={FOOTER_ICONS_MAIN}
      active="user"
      className={classNames.feeManagement}
    >
      <div
        className={classNames.tableView}
        style={{ backgroundColor: "#fffff" }}
      >
        <div className={classNames.tableHeader}>
          <div className={classNames.searchWrap}>
            <button
              onClick={() => setFeeReportModal(true)}
              className={classNames.buttonFont}
            >
              Fees
            </button>
          </div>



          <div className={classNames.searchWrap}>
            <button
              onClick={() => setFeeReportModal(true)}
              className={classNames.buttonFont}
            >
              Reports
            </button>
          </div>
          <div className={classNames.searchWrap}>
            <button
              onClick={() => setFeeDueReportModal(true)}
              className={classNames.buttonFont}
            >
              Due Report
            </button>
          </div>







          <div className={classNames.searchWrap}>
            <button
              onClick={() => setPrintTable(!printTable)}
              className={classNames.buttonFont}
            >
              {printTable ? "View Collections" : "View Dues"}
            </button>
          </div>
          {!feesListShow ? (
            <div className={classNames.searchWrap}>
              <button
                onClick={showAddFees}
                className={classNames.buttonFont}
              >
                Add Fees
              </button>
            </div>
          ) : (
            <div className={classNames.searchWrap}>
              <button
                onClick={showFastCashList}
                className={classNames.buttonFont}
              >
                Fast Cash
              </button>
            </div>
          )}
          <div className={classNames.searchWrap}>
            <input
              type="text"
              placeholder="Search Student"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className={classNames.searchWrap}>
            <button
              onClick={() => setDayBook(true)}
              className={classNames.buttonFont}
            >
              Day Book
            </button>
          </div>

         
        </div>
        {Object.keys(studentDetails)?.length ? (
          studentDetails?.collectionId ?
            <RefundFeeDetails
              data={studentDetails}
              handleClickbackButton={handleClickbackButton}
              feeSummary={feeSummary}
            />
            :
            <StudentFeeDetail
              data={studentDetails}
              handleClickbackButton={handleClickbackButton}
              feeSummary={feeSummary}
            />
        ) : printTable ? (
          <FeesTable search={search} handleCollect={handleCollect} />
        ) : (
          <FeesPrintTable search={search} handleCollect={handleCollect} />

        )}
      </div>
      {feesListShow ? (
        <FeeAdd toggleView={showFastCashList} />
      ) : (
        <FastCash handleCollect={handleFastCashCollect} />
      )}
      {feeReportModal && (
        <Reports
          onExport={onDateCollectionReport}
          onClose={() => setFeeReportModal(false)}
        />
      )}

      {feeDueReportModal && (
        <DuesReport
          onExport={onDateCollectionReport}
          onClose={() => setFeeDueReportModal(false)}
        />
      )}



     
      {feesListShow && (
        <AddFeesForm
          onExport={onDateCollectionReport}
          onClose={() => setFeesListShow(false)}
        />

      )}
      {
        billNumberModal && (
          <BillNumberModal
            data={successData}
            onClose={() => { setBillNumberModal(false); handleClickbackButton() }}
          />
        )
      }

<button className={classNames.closeButton} onClick={onClose}>
        Close
      </button>
    </DashLayout>
  );
}
