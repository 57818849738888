import React, { useContext, useEffect, useState } from "react";
import {
  Body,
  Header,
  Main,
  closeAnimation,
} from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import {
  getAllPhoto,
  getDropdownData,
  getNewClasses,
} from "../../pages/college/ApiServices";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";

interface InvoiceManagementProps {
  onClose: () => void;
}

function Numbering({ onClose }: InvoiceManagementProps) {
  const [showForm, setShowForm] = useState(false);
  const [dropDowns, setDropDowns] = useState<any[]>([]);
  const { collegeId, user } = useContext(FirebaseContext);
  const [selectedClass, setSelectClass] = useState("");
  const [values, setValues] = useState({
    activeStatus: "active",
    prefix: "",
    suffix: "",
    startNo: 1,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [tableData, setTableData] = useState<any[]>([]);

  const keyData = "applicationStated";

  const { data: tableDataOld = [] } = useQuery(
    [
      "getAlleeeessddseeeEnquiry",
      selectedClass,
      searchTerm,
      values.activeStatus,
    ],
    getAllPhoto(
      collegeId,
      selectedClass,
      searchTerm,
      keyData,
      values.activeStatus
    )
  );

  const { data: classes } = useQuery(
    "getNeeeewCladfdsfsdsses",
    getNewClasses(collegeId, user?.uid)
  );

  useEffect(() => {
    if (selectedClass) {
      setTableData(tableDataOld);
    }
  }, [tableDataOld]);

  const sortData = (key: any) => {
    setSortKey(key);
    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] && b[key]) {
        return a[key].localeCompare(b[key]);
      } else if (a[key]) {
        return -1;
      } else if (b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
    setTableData(sortedData);
  };

  useEffect(() => {
    const getDropDowns = async () => {
      const val = await getDropdownData();
      setDropDowns(val);
      console.log(val);
    };

    getDropDowns();
  }, [collegeId]);

  const generateData = () => {
    const { prefix, suffix, startNo } = values;

    const newTableData = tableData.map((ote, index) => {
      const rollNo = `${prefix}${parseInt(startNo + "") + index}${suffix}`;
      return {
        ...ote,
        rollNo: rollNo,
      };
    });

    setTableData(newTableData);
  };


  

  const handleChange = (e: any, i: number) => {
    const { name, value } = e.target;
    const temp = [...tableData];
    temp[i][name] = value;
    setTableData([...temp]);
  };

  const handleSave = async () => {
    const instance = await getAxiosTokenInstance();
    instance
      .post("/college/users/rollNumberUpdater", { data: tableData, collegeId })
      .then((res: any) => {
        console.log(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <>
      <Main title="Applications Initiated" width="100%" height="100%">
        <Header>
          <div className="flex">
            <Input
              fieldName="Filter by Course"
              state={selectedClass}
              setState={setSelectClass}
              didntShowKey
              optionDisplay="className"
              optionKey="_id"
              options={classes?.list}
              type="drop"
              width="400px"
            />
            <Input
              width="200px"
              fieldName="Active Status *"
              returnKey="activeStatus"
              state={values}
              setState={setValues}
              options={["Active", "In Active"]}
              type="drop"
            />
            <Input
              fieldName="Search"
              state={searchTerm}
              setState={setSearchTerm}
              type="text"
              width="200px"
            />
            <Input
              width="200px"
              fieldName="Pre Fix *"
              returnKey="prefix"
              state={values}
              setState={setValues}
              type="text"
            />
            <Input
              width="200px"
              fieldName="Start No *"
              returnKey="startNo"
              state={values}
              setState={setValues}
              type="number"
            />
            <Input
              width="200px"
              fieldName="Suffix *"
              returnKey="suffix"
              state={values}
              setState={setValues}
              type="text"
            />
            <div className="h-[50px] rounded-md p-3 m-2 px-2 bg-white">
              <h5> Total No: ''</h5>
            </div>
          </div>
          <div className="flex">
            <Button type="save" onClick={handleSave}>
              SAVE CHANGES
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div className="flex">
            <Button type="fetch" onClick={() => sortData("name")}>
              Sort by Name
            </Button>
            <Button type="fetch" onClick={() => sortData("gender")}>
              Sort by Gender
            </Button>
            <Button type="fetch" onClick={() => sortData("secondLanguage")}>
              Sort by Language
            </Button>
            <Button type="fetch" onClick={generateData}>
              Generate
            </Button>
          </div>
          <Table width="100%" innerWidth={["5%", "15%", "10%"]}>
            <Thead>
              <Tr>
                <Th position={0}>Sl.No.</Th>
                <Th position={1}>Name</Th>
                <Th position={2}>Gender</Th>
                <Th position={2}>Language</Th>
                <Th position={2}>dsc_b_name</Th>
                <Th position={2}>dsc_c_name</Th>
                <Th position={2}>mdc_name</Th>
                <Th position={2}>Roll No</Th>
              </Tr>
            </Thead>
            <Tbody height="auto">
              {tableData?.map((x: any, i: number) => (
                <Tr key={i}>
                  <Td index={i} position={0}>
                    {i + 1}
                  </Td>
                  <Td index={i} position={1}>
                    {x?.name?.toUpperCase()}
                  </Td>

                  <Td position={2} padding={"1px"} index={i}>
                    <select
                      value={x?.gender || ""}
                      name="gender"
                      onChange={(e) => handleChange(e, i)}
                    >
                      {["............", ...dropDowns[0]?.gender]?.map(
                        (z: any) => (
                          <option value={z}>{z}</option>
                        )
                      )}
                    </select>
                  </Td>
                  <Td position={2} padding={"1px"} index={i}>
                    <select
                      value={x?.secondLanguage || ""}
                      name="secondLanguage"
                      onChange={(e) => handleChange(e, i)}
                    >
                      {[
                        "...........",
                        ...(dropDowns[11]?.[collegeId || "secondLanguage"] ||
                          dropDowns[11]?.["secondLanguage"]),
                      ]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>

                  <Td position={2} padding={"1px"} index={i}>
                    <select
                      value={x?.dsc_b_name || ""}
                      name="dsc_b_name"
                      onChange={(e) => handleChange(e, i)}
                    >
                      {[
                        "...........",
                        ...(dropDowns[11]?.["minora"] || [""]),
                      ]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>
                  <Td position={2} padding={"1px"} index={i}>
                    <select
                      value={x?.dsc_c_name || ""}
                      name="dsc_c_name"
                      onChange={(e) => handleChange(e, i)}
                    >
                      {[
                        "...........",
                        ...(dropDowns[11]?.["minorb"] || [""]),
                      ]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>
                  <Td position={2} padding={"1px"} index={i}>
                    <select
                      value={x?.mdc_name || ""}
                      name="mdc_name"
                      onChange={(e) => handleChange(e, i)}
                    >
                      {[
                        "...........",
                        ...(dropDowns[11]?.["mdc"] || [""]),
                      ]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>

                  <Td position={2} padding={"1px"} index={i}>
                    <input
                      type="text"
                      value={x?.rollNo || ""}
                      name="rollNo"
                      onChange={(e) => handleChange(e, i)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Body>
      </Main>
    </>
  );
}

export default Numbering;
