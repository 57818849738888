import React, { useContext, useRef, useState } from "react";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getCashBook } from "../../pages/college/ApiServices";
import moment from "moment";
import { Body, Footer, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { Table, Tbody, Td, Tfoot, TfootTd, TfootTr, Th, Thead, Tr } from "../../NecttosComp/Table/Table";

const exportPdf = async ({ olddata, setLoading }: any) => {
  try {
    const instance = await getAxiosTokenInstance();
    const { collegeId, date, type, subType, endDate } = olddata;
    const resp = await instance.post(
      "/college/reportGeneration/cashBooks",
      { collegeId, date, type, subType, endDate },
      {
        responseType: "blob",
      }
    );
    if (resp.data.statusCode === 400) {
      setLoading(false);
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    throw new Error("API Error");
  }
};

export const CashBook = ({ onClose }: any) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef<any>();
  const selectedCollege = collegeList?.find((x: any) => x.collegeId === collegeId);

  const [date, setDate] = useState<any>({
    date: "",
    type: "Government",
    subType: "",
  });

  console.log(date);

  const { data = [], isFetching, refetch } = useQuery(["getCashBoook", date.type, date.subType], getCashBook(collegeId, date.date, date.type, date.subType, date.endDate));
  const [isLoading, setIsLoading] = useState(false);

  let toAccounts = data.types;
  let toSubAccounts = ["All Accounts", ...(data?.subTypes || [])];

  let dayWiseCashBook = data?.dayWiseCashBook || [];

  let heads = data?.subType ? data?.subType : data?.type;

  const exportPdfMutation = useMutation(exportPdf);
  const onExportExam = async () => {
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `CashBook ${moment(new Date()).format("DD MM YYYY hh mm A")}`;
      const pdfData = await exportPdfMutation.mutateAsync({ olddata: { collegeId, date: date.date, type: date.type, subType: date.subType, endDate: date.endDate } });
      link.href = URL.createObjectURL(new Blob([pdfData], { type: "application/pdf" }));
      link.click();
    } catch (e) {}
  };

  return (
    <Main width="90vw" height="90vh" title="Cash Book">
      <Header>
        <div className="flex items-center">
          <Input fieldName="Main Head" options={toAccounts} returnKey="type" state={date} setState={setDate} type="drop" />
          <Input fieldName="Sub Head" options={toSubAccounts} returnKey="subType" state={date} setState={setDate} type="drop" />
          <Input fieldName="Start Date" returnKey="date" state={date} setState={setDate} type="date" />
          <Input fieldName="End Date" returnKey="endDate" state={date} setState={setDate} type="date" />
          <Button type="fetch" onClick={() => refetch()}>
            Reload
          </Button>

          <Button type="download" onClick={() => onExportExam()}>
            Download
          </Button>
          <Button type="print" tableRef={componentRef.current}>
            Print
          </Button>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>
      <Body height="60vh">
        <div ref={componentRef} id="printable" className="h-[75vh] overflow-auto printable">
          <div style={{ width: "100%", display: "flex", flexDirection: "column", borderRadius: "25px", padding: "1%" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", borderRadius: "25px" }}>
              <img width="12%" height="12%" src={selectedCollege?.logo} />
              <div style={{ width: "80%", alignItems: "center", margin: 0, textAlign: "center" }}>
                <span style={{ fontSize: "30px", margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
                <p style={{ fontSize: "25px", margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
                <p style={{ fontSize: "14px", margin: 0, fontWeight: 900 }}>
                  {selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}
                </p>
              </div>
            </div>

            {dayWiseCashBook.map((dayWise: any) => (
              <>
                <span className="text-2xl font-bold text-gray-800 text-center mb-2 mt-5 block leading-normal">
                  {heads} Cash Book as on {moment(dayWise.date).format("ddd DD MMM YYYY hh:mm A")}
                </span>

                <div className="flex justify-start gap-2">
                  <div className="grid gap-2 w-[48%]">
                    <Table width="100%" innerWidth={["8%", "70.5%", "22.5%"]}>
                      <Thead>
                        <Tr>
                          <Th position={0}>SN</Th>
                          <Th position={1}>Opening Balance:-</Th>
                          <Th position={2}>Amount</Th>
                        </Tr>
                      </Thead>
                      <Tbody height="auto">
                        {dayWise?.opeingBalance?.map((item: any, i: number) => (
                          <Tr>
                            <Td index={i} position={0}>
                              {i + 1}
                            </Td>
                            <Td index={i} fontSize="15px" position={1}>
                              {item.item}
                            </Td>
                            <Td index={i} position={2} fontSize="15px" textAlign="right">
                              {item.amount.toFixed(2)}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <TfootTr>
                          <TfootTd position={0} merge={2}>
                            Total
                          </TfootTd>
                          <TfootTd position={2}>{dayWise?.totals?.opeingBalance.toFixed(2)}</TfootTd>
                        </TfootTr>
                      </Tfoot>
                    </Table>

                    <Table width="100%" innerWidth={["8%", "25%", "42%", "25%"]}>
                      <Thead>
                        <Tr>
                          <Th position={0}>SN</Th>
                          <Th position={1}>Collection:- </Th>
                          <Th position={2}>Naration</Th>
                          <Th position={3}>Amount</Th>
                        </Tr>
                      </Thead>
                      <Tbody height="auto">
                        {dayWise?.reciept?.map((item: any, i: any) => (
                          <Tr>
                            <Td index={i} position={0}>
                              {i + 1}
                            </Td>
                            <Td index={i} fontSize="15px" position={1}>
                              {item?.item}
                            </Td>
                            <Td index={i} fontSize="15px" position={2}>
                              {item?.naration}
                            </Td>
                            <Td index={i} fontSize="15px" position={3} textAlign="right">
                              {item?.amount?.toFixed(2)}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <TfootTr>
                          <TfootTd position={0} merge={2}>
                            Total
                          </TfootTd>
                          <TfootTd position={2}>{dayWise?.totals?.reciept.toFixed(2)}</TfootTd>
                        </TfootTr>
                      </Tfoot>
                    </Table>
                  </div>
                  <div className="grid gap-2 w-[48%]">
                    <Table width="100%" innerWidth={["8%", "25%", "42%", "25%"]}>
                      <Thead>
                        <Tr>
                          <Th position={0}>SN</Th>
                          <Th position={1}>Payments:- </Th>
                          <Th position={2}>Naration</Th>
                          <Th position={3}>Amount</Th>
                        </Tr>
                      </Thead>
                      <Tbody height="auto">
                        {dayWise?.payments?.map((item: any, i: any) => (
                          <Tr>
                            <Td index={i} fontSize="15px" position={0}>
                              {i + 1}
                            </Td>
                            <Td index={i} fontSize="15px" position={1}>
                              {item?.item}
                            </Td>
                            <Td index={i} fontSize="15px" position={2}>
                              {item?.naration}
                            </Td>
                            <Td index={i} fontSize="15px" position={3} textAlign="right">
                              {item?.amount?.toFixed(2)}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <TfootTr>
                          <TfootTd position={0} merge={2}>
                            Total
                          </TfootTd>
                          <TfootTd position={2}>{dayWise?.totals?.payments.toFixed(2)}</TfootTd>
                        </TfootTr>
                      </Tfoot>
                    </Table>
                    <Table width="100%" innerWidth={["8%", "70.5%", "22.5%"]}>
                      <Thead>
                        <Tr>
                          <Th position={0}>SN</Th>
                          <Th position={1}>Closing Balance:- </Th>
                          <Th position={2}>Amount</Th>
                        </Tr>
                      </Thead>
                      <Tbody height="auto">
                        {dayWise?.closingBalance?.map((item: any, i: any) => (
                          <Tr>
                            <Td index={i} fontSize="15px" position={0}>
                              {i + 1}
                            </Td>
                            <Td index={i} fontSize="15px" position={1}>
                              {item.item}
                            </Td>
                            <Td index={i} fontSize="15px" position={2} textAlign="right">
                              {item.amount.toFixed(2)}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <TfootTr>
                          <TfootTd position={0} merge={2}>
                            Total
                          </TfootTd>
                          <TfootTd position={2}>{dayWise?.totals?.closingBalance.toFixed(2)}</TfootTd>
                        </TfootTr>
                      </Tfoot>
                    </Table>

                    {dayWise?.refund?.length > 0 && (
                      <Table width="100%" innerWidth={["8%", "70.5%", "22.5%"]}>
                        <Thead>
                          <Tr>
                            <Th position={0}>SN</Th>
                            <Th position={1}>Refund:- </Th>
                            <Th position={2}>Amount</Th>
                          </Tr>
                        </Thead>
                        <Tbody height="auto">
                          {dayWise?.refund?.map((item: any, i: any) => (
                            <Tr>
                              <Td index={i} position={0}>
                                {i + 1}
                              </Td>
                              <Td index={i} position={1}>
                                {item.item}
                              </Td>
                              <Td index={i} position={2} textAlign="right">
                                {item.amount.toFixed(2)}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                        <Tfoot>
                          <TfootTr>
                            <TfootTd position={0} merge={2}>
                              Total
                            </TfootTd>
                            <TfootTd position={2}>{dayWise?.totals?.refund.toFixed(2)}</TfootTd>
                          </TfootTr>
                        </Tfoot>
                      </Table>
                    )}
                  </div>
                </div>

                <span className="text-xl font-bold text-[#00000087] text-left block leading-normal">Grand Total: {dayWise?.totals?.grandTotalRecipt.toFixed(2)}</span>
              </>
            ))}
          </div>
        </div>
      </Body>
      <Footer />
    </Main>
  );
};
