import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { useQuery } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import moment from 'moment';
import { getAdmissionNumber, getClassName, getDepartmentName, getMinorName } from '../Form/Component';
import { getCollegeDetails } from '../Form/Register';
import StaticAllotment from '../Admission/StaticAllotment';
import ApplicationView from '../Admission/ApplicationView';
import RemoveAction from '../Admission/RemoveAction';
import { getAllMinor } from '../../pages/college/ApiServices';

const getCollegeDetailsee = async ({ queryKey }: any) => {
	const [_key, collegeId] = queryKey;

	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});

		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

function RealTimeAllotment({ onClose }: { onClose: () => void }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const [addNew, setAddNew] = useState<any>({});
	const [tableData, setTableData] = useState<any>([]);
	const [showAdvance, setAdvance] = useState<boolean>(false);
	const [needWl, setWl] = useState<boolean>(false);
	const [subjectId, setSelectClass] = useState('');
	const [date, setDate] = useState('--:--');
	const [type, setType] = useState('');
	const [newAll, setNewAll] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [catogory, setCatogory] = useState('');
	const [isLoading, setLoading] = useState<boolean>(false);
	const { data: subjectList = [] } = useQuery('getAllMinor', getAllMinor(collegeId));

	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const [publishState, setPublish] = useState<any>({});

	const [table, setTable] = useState<any>([]);
	const ref = useRef<any>(null);

	const getAutoIndexdddd = async (uptodated: boolean | null) => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/fyugp/getRealTimeAllotmentFYUGP', {
				params: {
					collegeId,
					showAdvance,
					needWl,
					uptodated,
					catogory,
					type,
					subjectId,
				},
			});
			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				if (resp?.data?.list?.length > 0) {
					setTableData(resp?.data.list);
					setDate(moment(resp?.data?.date).format('hh:mm A (MMM DD)'));
					setNewAll(false);
				} else {
					setTableData([]);
					setNewAll(false);
					setDate(moment(resp?.data?.date).format('hh:mm A (MMM DD)'));
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (subjectId) {
			getAutoIndexdddd(null);
		}
	}, [subjectId, showAdvance, needWl]);

	useEffect(() => {
		if (catogory.length > 1) {
			getAutoIndexdddd(null);
		}
	}, [catogory]);

	useEffect(() => {
		if (tableData) {
			let data = tableData;
			if (searchTerm) {
				data = data?.filter((item: any) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
			}
			setTable(data);
		}
	}, [tableData, searchTerm]);

	const { tableItems, requestSort, getIcon } = useSortableData(table);
	const [progress, setProgress] = useState(0);
	const [remainingTime, setRemainingTime] = useState(5 * 60);

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((prev: any) => {
				if (prev < 1000) {
					return prev + 1;
				} else {
					clearInterval(interval);
					return 100;
				}
			});

			setRemainingTime((prev: any) => {
				if (prev > 0) {
					return prev - 1;
				} else {
					clearInterval(interval);
					return 0;
				}
			});
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${minutes} minute(s) and ${secs} second(s)`;
	};
	return isLoading ? (
		<Main title='Real-Time Allotment' height='100%' width='100%'>
			<Header>
				<div className='flex'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>

			{!newAll ? (
				<Body>
					<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white overflow-hidden font-sans'>
						<p className='text-6xl font-bold my-10 animate-scroll'>Getting FYUGP allotment List...</p>
						<p className='text-4xl my-10 animate-scroll animation-delay-2000'>Please wait, this process may take some time.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We appreciate your patience as we generate accurate and up-to-date rankings for you.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We strive to save you time and improve your life balance.</p>
					</div>
				</Body>
			) : (
				<Body>
					<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white overflow-hidden font-sans'>
						<p className='text-6xl font-bold my-10 animate-scroll'>Calculating new FYUGP real-time allotment...</p>
						<p className='text-4xl my-10 animate-scroll animation-delay-2000'>Please wait, this process may take some time. Approximately 1,72,34,000 loops are being processed.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We appreciate your patience as we generate accurate and up-to-date rankings for you.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>This may take approximately 2 to 5 minutes.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We strive to save you time and improve your life balance.</p>
						<div className='w-full h-4 bg-gray-300 mt-8 rounded'>
							<div className='h-4 bg-green-500 rounded' style={{ width: `${progress}%` }}></div>
						</div>
						<p className='text-xl mt-4'>Estimated time remaining: {formatTime(remainingTime)}</p>
					</div>
				</Body>
			)}
		</Main>
	) : (
		<>
			{publishState?.publish ? (
				<Main title='Real-Time Allotment' height='100%' width='100%'>
					<Header>
						<div className='flex justify-end p-4'>
							<Button type='close' onClick={() => setPublish({ ...publishState, publish: false })}>
								Close
							</Button>
						</div>
					</Header>
					{publishState?.static ? (
						<Body>
							<StaticAllotment ids='sdfnkdjfhw32323sdfsdf' />
						</Body>
					) : (
						<Body>
							<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white font-sans overflow-hidden'>
								<p className='text-6xl font-bold my-4 animate-scroll'>Publishing new Real-Time or Static Allotment...</p>
								<p className='text-4xl my-4 animate-scroll animation-delay-2000'>Please ensure all data is accurate before publishing.</p>
								<p className='text-3xl my-4 animate-scroll animation-delay-4000'>Remember, this is a system-generated report. Any errors in the student mark list will appear here as well.</p>
								<div className='flex space-x-4 mt-8'>
									<Button type='save' width='300px' onClick={() => setPublish({ ...publishState, dynamic: false })}>
										Dynamic Publish
									</Button>
									<Button type='fetch' width='300px' onClick={() => setPublish({ ...publishState, static: true })}>
										Static Publish
									</Button>
								</div>
							</div>
						</Body>
					)}
				</Main>
			) : (
				<Main title={'FYUGP Real-Time Allotment: Total Applications: ' + tableItems?.length} height='100%' width='100%'>
					<Header>
						<div className='flex'>
							<Input fieldName='Filter by Catogory' state={type} setState={setType} options={['DSC_B', 'DSC_C', 'MDC']} type='drop' width='10vw' />
							<Input fieldName='Filter by Course' state={subjectId} setState={setSelectClass} didntShowKey optionDisplay='subjectName' optionKey='_id' options={subjectList.filter((x: any) => x.type === type)} type='drop' width='35vw' />
							<Input fieldName='Filter by Catogory' state={catogory} setState={setCatogory} options={['SC', 'ST', 'MU', 'MUSLIM', 'MGMT', 'MGT', 'MANAGEMENT', 'EWS', 'PWD', 'COMT', 'COMMUNITY', 'SP', 'SPORTS', 'CU', 'CULTURAL']} type='drop' width='15vw' />
							<Input fieldName='Search' state={searchTerm} setState={setSearchTerm} type='text' width='15vw' />
						</div>
						<div className='flex'>
							<Button
								type='fetch'
								onClick={() => {
									setPublish({ ...publishState, publish: true });
								}}>
								Publish
							</Button>
							<Button
								type='save'
								onClick={() => {
									getAutoIndexdddd(true);
									setNewAll(true);
								}}>
								Last Updated {date}
							</Button>
							<Button
								type={needWl ? 'save' : 'fetch'}
								onClick={() => {
									setWl(!needWl);
								}}>
								{!needWl ? 'Show WL' : 'Show Seat Aloted'}
							</Button>
							<Button
								type={showAdvance ? 'save' : 'fetch'}
								onClick={() => {
									setAdvance(!showAdvance);
								}}>
								{showAdvance ? 'Show Normal Mode' : 'Show Advance Mode'}
							</Button>
							<Button fileName={`${tableItems?.[0]?.departmentName.slice(13)}_${type.toUpperCase()}_${tableItems?.[0]?.subjectName}`} type='print' tableRef={ref.current}>
								Print
							</Button>
							<Button type='excel' tableRef={ref.current}>
								Excel
							</Button>
							<Button type='close' onClick={onClose}>
								Close
							</Button>
						</div>
					</Header>
					{showAdvance && (
						<>
							<Thead>
								<Tr>
									<Th textAlign='center' position={0}>
										Admn.No.
									</Th>
									<Th position={1} onClick={() => requestSort('name')} icon={getIcon('name')}>
										Name of the Candidate
									</Th>

									{showAdvance && (
										<>
											{/* <Th position={4}>Action</Th> */}
											<Th position={4}>Mobile</Th>
										</>
									)}
									<Th textAlign='center' position={0} onClick={() => requestSort('rank')} icon={getIcon('rank')}>
										Rank
									</Th>
									<Th textAlign='center' position={0} onClick={() => requestSort('quotaType')} icon={getIcon('quotaType')}>
										Alloted Quota
									</Th>
									<Th textAlign='center' position={0} onClick={() => requestSort('index')} icon={getIcon('index')}>
										Index
									</Th>
									<Th textAlign='center' position={3} onClick={() => requestSort('priority')} icon={getIcon('priority')}>
										Priority
									</Th>
									{showAdvance && (
										<>
											<Th textAlign='center' position={0} onClick={() => requestSort('className')} icon={getIcon('className')}>
												Class
											</Th>
											<Th position={0} onClick={() => requestSort('allotedAdmnType')} icon={getIcon('allotedAdmnType')}>
												Quota
											</Th>
											<Th position={0} onClick={() => requestSort('allotedIndex')} icon={getIcon('allotedIndex')}>
												Index
											</Th>
											<Th position={0} onClick={() => requestSort('allotedPriority')} icon={getIcon('allotedPriority')}>
												Priority
											</Th>
											<Th position={2} onClick={() => requestSort('allotedTo')} icon={getIcon('allotedTo')}>
												Course
											</Th>
											<Th textAlign='center' position={0} onClick={() => requestSort('className')} icon={getIcon('className')}>
												Reserved
											</Th>
										</>
									)}
								</Tr>
							</Thead>
						</>
					)}
					<Body>
						<div ref={ref} className='printable overflow-auto '>
							<Table width='100%' innerWidth={!showAdvance ? ['10%', '30%', '6%', '10%', '10%', '10%'] : ['5%', '8%', '7%', '5%', '6%']}>
								{!showAdvance && (
									<>
										<table className='min-w-full table-auto'>
											<thead>
												<tr>
													<td
														className='p-1 bg-white'
														style={{
															width: '70%',
															fontFamily: 'Times New Roman',
														}}>
														<img src={collegeData?.dashBoardLink} alt='College Logo' className='h-[100px] w-auto  mx-auto' />
													</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td
														className='p-1 text-center text-black bg-white text-xl font-bold'
														style={{
															width: '70%',
															fontFamily: 'Times New Roman',
														}}>
														{!needWl ? `FYUGP 2024 ${type} Allotment List` : `FYUGP 2024-25 ${type} Allotment Waiting List`}
													</td>
												</tr>
												<tr>
													<td
														className='p-1 text-center text-black  bg-white text-lg'
														style={{
															width: '70%',
															fontFamily: 'Times New Roman',
														}}>
														Paper: {getMinorName(subjectId, subjectList)}
													</td>
												</tr>
												<tr>
													<td
														className='p-1 text-center text-black  bg-white text-lg'
														style={{
															width: '70%',
															fontFamily: 'Times New Roman',
														}}>
														{getDepartmentName(subjectId, subjectList)}
													</td>
												</tr>
												<tr>
													<td
														className='p-1 text-center text-black bg-white text-lg'
														style={{
															width: '70%',
															fontFamily: 'Times New Roman',
														}}>
														For any queries, please contact Department
														<br />
													</td>
												</tr>
											</tbody>
										</table>
										<Thead>
											<Tr>
												<Th textAlign='center' position={0}>
													Admn.No.
												</Th>
												<Th position={1} onClick={() => requestSort('name')} icon={getIcon('name')}>
													Name of the Candidate
												</Th>
												{showAdvance && (
													<>
														<Th position={4}>Action</Th>
														<Th position={4}>Mobile</Th>
													</>
												)}
												<Th textAlign='center' position={2} onClick={() => requestSort('rank')} icon={getIcon('rank')}>
													Rank
												</Th>
												<Th textAlign='left' position={3} onClick={() => requestSort('quotaType')} icon={getIcon('quotaType')}>
													OG Quota
												</Th>
												<Th textAlign='left' position={3} onClick={() => requestSort('quotaType')} icon={getIcon('quotaType')}>
													Alloted
												</Th>
												<Th textAlign='center' position={2} onClick={() => requestSort('index')} icon={getIcon('index')}>
													Index
												</Th>
												<Th textAlign='center' position={3} onClick={() => requestSort('priority')} icon={getIcon('priority')}>
													Priority
												</Th>
												<Th textAlign='center' position={0} onClick={() => requestSort('className')} icon={getIcon('className')}>
													Dept
												</Th>
												{showAdvance && (
													<>
														<Th position={0} onClick={() => requestSort('allotedAdmnType')} icon={getIcon('allotedAdmnType')}>
															Quota
														</Th>
														<Th position={0} onClick={() => requestSort('allotedIndex')} icon={getIcon('allotedIndex')}>
															Index
														</Th>
														<Th position={0} onClick={() => requestSort('allotedPriority')} icon={getIcon('allotedPriority')}>
															Priority
														</Th>
														<Th position={2} onClick={() => requestSort('allotedTo')} icon={getIcon('allotedTo')}>
															Dept
														</Th>
														<Th textAlign='center' position={0} onClick={() => requestSort('className')} icon={getIcon('className')}>
															OG Quata
														</Th>
													</>
												)}
											</Tr>
										</Thead>
									</>
								)}
								<Tbody height='auto'>
									{tableItems?.map((x: any, i: number) => (
										<>
											<Tr>
												<Td textAlign='center' index={i} position={0}>
													{x?.admisionNo}
												</Td>
												<Td index={i} position={1}>
													{x?.name?.toUpperCase()}
												</Td>

												{showAdvance && (
													<>
														<Td index={i} position={4}>
															<Button
																type='doc'
																onClick={() => {
																	setAddNew({
																		...addNew,
																		viewMode: true,
																		_id: x.userId,
																		tyeUG: x.batchName === '2024-2026' ? 'PG' : 'UG',
																	});
																}}>
																ViewApp
															</Button>
														</Td>
														<Td index={i} position={4}>
															{x?.phoneNumber?.slice(-10)}
														</Td>
													</>
												)}
												<Td textAlign='center' index={i} position={2}>
													{x?.rank}
												</Td>
												<Td textAlign='left' index={i} position={3}>
													{x?.ogQuoata}
												</Td>
												<Td textAlign='left' index={i} position={3}>
													{x?.quota}
												</Td>
												<Td textAlign='center' index={i} position={2}>
													{String(x.index).slice(0, 7)}
												</Td>
												<Td textAlign='center' index={i} position={3}>
													{x?.priority}
												</Td>
												<Td textAlign='center' index={i} position={0}>
													{x?.courseName}
												</Td>
												{showAdvance && (
													<>
														<Td highlight='#abf5bf' index={i} position={0}>
															{x?.quotaTypeAloted}
														</Td>
														<Td highlight='#abf5bf' index={i} position={0}>
															{x?.indexAloted}
														</Td>
														<Td highlight='#abf5bf' index={i} position={0}>
															{x?.priorityAloted}
														</Td>
														<Td highlight='#abf5bf' index={i} position={2}>
															{x?.classNameAloted}
														</Td>
														<Td textAlign='center' index={i} position={0}>
															{x?.ogQuoata}
														</Td>
													</>
												)}
											</Tr>
										</>
									))}
								</Tbody>
							</Table>
						</div>
						{addNew?.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}
						{addNew?.permanent && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, permanent: false })} />}
						{addNew?.temparary && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, temparary: false })} />}
						{addNew?.remove && <RemoveAction userId={addNew?._id} completedApplication={false} classId={subjectId} indexMark={addNew?.indexMark} quota={addNew.quota} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, remove: false })} />}
					</Body>
				</Main>
			)}
		</>
	);
}

export default RealTimeAllotment;
