import React, { useEffect, useState } from "react";
import Register from "./Register";
import { getAxiosInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import ApplicationOpen from "./ApplicationOpen";
import Button from "../../NecttosComp/Button/Button";
import { useParams } from "react-router-dom";

const getCollegeDetails = async ({ queryKey }: any) => {
  const [_key, collegeId] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get("/college/admissionProcess/getCollegeDetails", {
      params: { collegeId },
    });

    if (data?.statusCode === 200) {
      return data.collegeData;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

const BasicCollege: React.FC = () => {
  const params: { collegeName: string; type: string } = useParams();

  const [initCollegeDetails, setInitCollegeDetails] = useState({
    collegeId: "",
    bashBordLink: "",
  });

  useEffect(() => {
    let initCollegeData = { ...initCollegeDetails };
    if (params.collegeName === "nest") {
      initCollegeData.collegeId = "COL-13A8DDE1337D-477B-BB7A-FFB2825C5056";
    }
    if (params.collegeName === "amc") {
      initCollegeData.collegeId = "COL-BB2631888FF3-4B9E-8DD2-59FF3CA88846";
    }
    if (params.collegeName === "snc") {
      initCollegeData.collegeId = "COL-5F9623C234F8-45AA-A2A3-C5F5734E1F77";
    }
    if (params.collegeName === "moulana") {
      initCollegeData.collegeId = "COL-BB271658898E-4DE0-BC30-B58B89594F80";
    }
    if (params.collegeName === "sgc") {
      initCollegeData.collegeId = "COL-E67980529419-4EFA-BAF8-ABD89D79284D";
    }
    if (params.collegeName === "smc_aided") {
      initCollegeData.collegeId = "COL-D209492D4FB7-4E07-848E-A317A1BC9196";
    }
    if (params.collegeName === "smc_self") {
      initCollegeData.collegeId = "COL-3FAA19D25E23-4558-AE0C-D89C4D37D418";
    }
    if (params.collegeName === "mpmmsn") {
      initCollegeData.collegeId = "COL-A611B639AA92-4288-81DB-BA777E497A59";
    }
    if (params.collegeName === "tkmmc") {
      initCollegeData.collegeId = "COL-9E8AF41AB347-4BD6-8A1D-43D02D39738E";
    }
    if (params.collegeName === "md_aided") {
      initCollegeData.collegeId = "COL-F0AF67159D59-4FDF-9915-25CFD728DCA6";
    }
    if (params.collegeName === "md_self") {
      initCollegeData.collegeId = "COL-62A06826B305-4AAA-AD96-2DA8F547C914";
    }
    if (params.collegeName === "gvc") {
      initCollegeData.collegeId = "COL-4E56593776BE-41C3-982A-0B6DC20E44A0";
    }
    if (params.collegeName === "stc") {
      initCollegeData.collegeId = "COL-32A6A87924CF-41FE-A825-843CC7A155B4";
    }
    if (params.collegeName === "scc") {
      initCollegeData.collegeId = "COL-85A6CCA7E82D-48CF-A333-8D3DF7F88E32";
    }
    if (params.collegeName === "lement") {
      initCollegeData.collegeId = "COL-561D56C8DDB9-49B0-A38D-E96AC25F8171";
    }
    if (params.collegeName === "gpe") {
      initCollegeData.collegeId = "COL-77A21941F69C-457B-9E4D-18BDE5E13233";
    }
    if (params.collegeName === "scam") {
      initCollegeData.collegeId = "COL-6E04F4B93209-4E82-8F04-887FA909E570";
    }
    if (params.collegeName === "sdc") {
      initCollegeData.collegeId = "COL-AF7BBD488D81-439A-BC17-53A7FC0DC496";
    }
    if (params.collegeName === "wmo") {
      initCollegeData.collegeId = "COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0";
    }

    setInitCollegeDetails(initCollegeData);
  }, [params.collegeName]);

  const [successLogin, setLogin] = useState<boolean>(false);
  const [internet, setInternet] = useState<boolean>(navigator.onLine);
  const [apply, setApply] = useState<boolean>(false);
  const { data: collegeData } = useQuery([`collegeData`, initCollegeDetails?.collegeId], getCollegeDetails);
  const [login, setLoginAll] = useState<boolean>(false);

  useEffect(() => {
    const goOnline = () => {
      setInternet(true);
    };
    const goOffline = () => {
      setInternet(false);
    };
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) setLogin(true);
    else setLogin(false);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleLogout3 = () => {
    setApply(false);
  };

  const CommonHeader = ({ iconColor, headerText }: any) => (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" className={`h-16 w-16 text-${iconColor}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <h1 className="text-xl font-semibold text-gray-800 mt-4">{headerText}</h1>
    </div>
  );

  const CommonMessages = () => (
    <>
      <p className="text-center text-gray-600 mt-2">Thank you for your interest in advancing your education with us!</p>
      <p className="text-center text-gray-600 mt-2">"Education is not the filling of a pail, but the lighting of a fire." — William Butler Yeats</p>
      <p className="text-center text-gray-600 mt-2">Your journey towards academic excellence starts now. Fill out the application form to take the next step in your educational career.</p>
      <p className="text-center text-gray-600 mt-4 font-medium">We appreciate your eagerness to join our community. Together, we'll strive for success in your academic pursuits!</p>
    </>
  );

  const ApplicationForm = ({ type }: any) => (
    <div className="flex flex-col items-center p-6 max-w-2xl mx-auto bg-white rounded-lg shadow-md">
      <CommonHeader iconColor={type === "UG" ? "green-500" : "green-500"} headerText={type === "UG" ? "UG Application Form Is Now Open!" : "PG Application Form will be Available from Result Publishing"} />
      <CommonMessages />
      <div className="flex flex-wrap justify-around mt-4">
        {type === "UG" ? <Button width="100%" type="save" onClick={() => setApply(true)} children="UG Open Application Form" /> : <Button width="100%" type="save" onClick={() => setApply(true)} children="PG Application Form" />}
        <Button
          type="close"
          onClick={() => {
            handleLogout();
          }}
          children="Logout"
        />
      </div>
    </div>
  );

  return !internet ? (
    <div className="text-gray-900 bg-gradient-to-r from-blue-500 to-blue-300 p-5 rounded-lg m-5 text-center shadow-md transition-all ease-in-out duration-300 font-nunito font-sans" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
      <strong className="block text-lg mb-2">You are offline.</strong>
      Please check your internet connection.
      <br />
      Thank you for your attempt.
      <br />
      Sincerely,
      <br />
      Nirmala College Muvatuppuzha!
    </div>
  ) : successLogin ? (
    <>
      <ApplicationOpen handleLogout={handleLogout} initCollegeId={initCollegeDetails?.collegeId} collegeData={collegeData} />
    </>
  ) : (
    <div className="flex flex-col justify-center items-center lg:w-full lg:h-screen bg-no-repeat bg-cover bg-center">
      <div className="w-full max-w-8xl mx-auto bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg overflow-scroll">
        <div className="lg:flex">
          <div className="lg:p-4 overflow-y-auto lg:w-1/3 bg-gray-700 rounded-l-lg">
            <Register collegeData={collegeData} login={login} initCollegeId={initCollegeDetails?.collegeId} setLogin={setLogin} />
          </div>
          <div className="p-4 bg-gray-800 lg:w-2/3 text-gray-100 rounded-r-lg">
            <h1 className="text-2xl lg:text-3xl text-slate-400 font-nunito font-bold mb-4 text-center">Embark on Your Academic Journey</h1>
            <p className="font-nunito text-slate-400 font-bold text-lg">{collegeData?.collegeName} Welcomes You!</p>
            <p className="mt-2 text-slate-400 font-bold text-base text-justify">
              Your path to academic excellence and holistic development starts here. Explore our diverse range of programs and find one that aligns with your aspirations. Learn more about our application process, scholarships, and the vibrant campus life by visiting our website:
              <br />
              <a href={collegeData?.website} className="text-teal-300 hover:text-teal-400 hover:underline" target="_blank" rel="noopener noreferrer">
                {collegeData?.website}
              </a>
              .
            </p>
            <p className="mt-2 text-base text-slate-400 font-bold text-justify">Please prepare your academic records and necessary documents for submission. Guidance is available every step of the way.</p>
            <p className="mt-2 text-base text-slate-400 font-nunito font-medium text-justify">
              Need help? Our dedicated admissions team is here to assist you, whether you prefer online assistance or a personal consultation. Visit our admissions help desk from 9:00 AM to 3:00 PM or reach us at:
              <a href={`tel:${collegeData?.phoneNumber}`} className="text-teal-300 hover:text-teal-400 hover:underline">
                {collegeData?.phoneNumber}
              </a>
              for any inquiries.
            </p>
            <div className="mt-6">
              <h2 className="text-xl lg:text-2xl text-slate-400 font-nunito font-bold mb-3 text-center">Admission Steps</h2>
              <ol className="list-decimal pl-4 text-slate-400 font-nunito font-medium text-lg text-justify">
                <li>Register your account first.</li>
                <li>Fill up the application form.</li>
                <li>Pay the application fee.</li>
                <li>Finalize and submit your application.</li>
              </ol>
            </div>
            <p className="mt-4 font-nunito text-slate-400 font-semibold text-lg">We're excited to welcome you to the {collegeData?.collegeName} community!</p>
            {collegeData?.collegeTour && (
              <div className="flex justify-center my-4">
                <iframe width="560" height="315" src={collegeData?.collegeTour} title="Campus Tour Video Player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicCollege;
