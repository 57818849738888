import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select, Table } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getDCBReports, getReports } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Alert from 'react-popup-alert'
import { useAccountsHeads, useAllClasses } from "../../../../queryHooks";
import Scrollbars from "react-custom-scrollbars";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import ReactToPrint from "react-to-print";

export const exportPdfCollectionReport = async ({ postData, setLoading, setAlert }) => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.post(`/college/reportGeneration/generateCollectionReport`, postData, {
      responseType: "blob",
    });


    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (e) {
    throw new Error(
      setLoading(false),
      setAlert({
        type: 'Message',
        text: 'Chack Date / Time / Main account / Sub account',
        show: true
      }),
    );
  }
};


export const FDCB = ({ onClose }) => {
  const allClasses = useAllClasses({ needAll: false, classOnly: false });
  const [state, setState] = React.useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    mainAccount: "",
    subAccount: "",
    activeStatus: 'active',
    lactive: true

  });
  const [alert, setAlert] = React.useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const accounts = useAccountsHeads();
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const onCloseAlert = () => {
    setAlert({
      type: '',
      text: '',
      show: false
    })
  }
  let quaryData = {
    startDate: state?.startDate,
    endDate: state?.endDate,
    mainAccount: state?.mainAccount || '',
    subAccount: state?.subAccount || '',
    method: state?.method || '',
    classId: state?.classId || '',
    activeStatus: state?.activeStatus || '',
    collegeId,
    state
  }
  const { data = [], isLoading, refetch } = useQuery(['getDCBReports', state.mainAccount, state?.activeStatus, state?.subAccount, state?.method, state?.startDate, state?.endDate, state?.classId, state], getDCBReports(quaryData));


  const [loading, setLoading] = useState(false);

  let subAccount = accounts?.data?.subAccounts || []
  let mainAccount = accounts?.data?.mainAccounts || []
  if (state?.mainAccount) {
    subAccount = subAccount.filter(x => x.mainId === state?.mainAccount)
  }
  const { Option } = Select;



  const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);

  const onDateCollectionReport = async () => {
    setLoading(true)
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        startDate: state?.startDate,
        endDate: state?.endDate,
        startTime: state?.startTime,
        endTime: state?.endTime,
        mainAccount: state?.mainAccount || '',
        subAccount: state?.subAccount || '',
        method: state?.method || '',
        activeStatus: state?.activeStatus || '',
        collegeId,
      };
      link.download = state?.endDate;
      const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync({ postData, setLoading, setAlert });
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
      setLoading(false)

    } catch (e) { }
  };



  const columns = [
    {
      title: 'Class Name',
      dataIndex: 'className',
      key: 'className',
    },
    {
      title: 'Student Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Admn No',
      dataIndex: 'admisionNo',
      key: 'admisionNo',
    },
    {
      title: 'Opening Balance',
      dataIndex: 'openingBalance',
      key: 'openingBalance',
    },
    {
      title: 'Current Collection',
      dataIndex: 'currentCollection',
      key: 'currentCollection',
    },
    {
      title: 'Clossing Balance',
      dataIndex: 'clossingBalance',
      key: 'clossingBalance',
    }
  ];

  subAccount = subAccount.concat({ label: 'All', value: '' })
  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >

        <div className={classNames.listContainer2}>
          <div className={classNames.headerPanel}>
            <div className={classNames.paginationController}>

            </div>
          </div>

          <label className={classNames.heading}>Language</label>
          <div style={{ flexDirection: 'row', textAlign: 'center' }}>
            {['active', 'Discontinued', 'Suspended', 'Dismissed', 'Long Leave', 'TC Issue', 'College Transfer', 'Irregular'].map((categoryKey) => (
              <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                <input type="checkbox"
                  style={{ height: 12, width: 12, marginRight: 10 }}
                  defaultChecked={state['l' + categoryKey]}
                  onChange={() => setState({ ...state, ['l' + categoryKey]: !state['l' + categoryKey] })}
                />
                {categoryKey}
              </label>
            ))}
          </div>
          {isLoading ? <LoadingAnimation customHeight={1050} customWidth={1050} /> :
            <Scrollbars style={{ height: '90%' }} >
              <div ref={componentRef} className="printable">
                <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                    <img width='6%' height='6%' src={selectedCollege?.logo} />
                    <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                      <span style={{ fontSize: '20px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>
                      <p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }} >{selectedCollege?.accreditedGrade}</p>
                      <p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}> {state.mainAccountName} DCB  Report as on {moment(new Date()).format('DD MMM YYYY hh:mm A')}</p>
                    </div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 20, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 3 }}>
                    <h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%', }}>Sn</h5>
                    <h5 style={{ marginTop: 2, color: 'white', alignSelf: 'center', fontSize: 14, width: '60%' }}>Class Name</h5>
                    <h5 style={{ marginTop: 2, color: 'white', textAlign: 'right', fontSize: 14, width: '10%' }}>Demand</h5>
                    <h5 style={{ marginTop: 2, color: 'white', textAlign: 'right', fontSize: 14, width: '10%' }}>Collection</h5>
                    <h5 style={{ marginTop: 2, color: 'white', textAlign: 'right', fontSize: 14, width: '10%' }}>Balance</h5>

                  </div>
                  {data?.collections?.map((item, i) => (
                    <>
                      <div style={{ width: '100%', display: 'flex', height: '40px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: 'white' }}>
                        <h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{i + 1}</h5>
                        {item?.className && <h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '60%' }}>{item?.className}</h5>}
                        {item?.userName && <h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '60%' }}>{item?.userName}</h5>}
                        <h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'right', fontSize: 14, width: '10%' }}>{item?.openingBalance.toFixed(2)}</h5>
                        <h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'right', fontSize: 14, width: '10%' }}>{item?.currentCollection.toFixed(2)}</h5>
                        <h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'right', fontSize: 14, width: '10%' }}>{item?.clossingBalance.toFixed(2)}</h5>

                      </div>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        height: 1,
                        backgroundColor: 'black',
                      }}></div>
                    </>
                  ))}
                  <h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 10 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
                </div>
              </div>
            </Scrollbars>}

          <div>
            <Scrollbars >
              <table className={classNames.userTable} id="emp">
                <Table dataSource={data.collections} pagination={false} columns={columns} />
              </table>
            </Scrollbars>
          </div>
        </div>


      </div>


      <div className={classNames.absoluteDiv}  >
        <label className={classNames.heading}>DCB Report</label>

        <Formik
          enableReinitialize
        >
          <div className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="date"
              name="startDate"
              id="startDate"
              value={state.startDate}
              onChange={(e) => {
                setState({ ...state, startDate: e.target.value })
              }
              }
            />
            <label>Start Date</label>

          </div>

        </Formik>

        {/* <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="time"
              name="startTime"
              id="startTime"
              value={state.startTime}
              onChange={(e) => {
                setState({ ...state, startTime: e.target.value })
              }
              }
            />
            <label>Start Time</label>
          </div>
        </Formik> */}
        <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="date"
              name="endDate"
              id="endDate"
              value={state.endDate}
              onChange={(e) => {
                setState({ ...state, endDate: e.target.value })
              }
              }
            />
            <label>End Date</label>

          </div>

        </Formik>

        {/* <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="time"
              name="endTime"
              id="endTime"
              value={state.endTime}
              onChange={(e) => {
                setState({ ...state, endTime: e.target.value })
              }
              }
            />
            <label>End Time</label>
          </div>
        </Formik> */}




        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 180 }}>
            <Select
              showSearch
              className={classNames.formFullWidth2}
              style={{ overflow: "hidden" }}
              onChange={(val) => {
                setState({ ...state, classId: (allClasses?.data?.find((x, i) => (x.className + i) === val)).classId, className: (allClasses?.data?.find((x, i) => (x.className + i) === val)).className });
              }}
            >
              {allClasses?.data?.map((item, i) => (
                <Option key={i} value={item.className + i}>
                  {item.className}
                </Option>
              ))}
            </Select>
            <label>{state?.filterType}</label>
          </div>
        </Formik>


        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            id="from"
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}


            onChange={(val) => {
              setState({ ...state, mainAccountName: val, mainAccount: mainAccount?.find(x => x.label === val).value });
            }}
          >
            {mainAccount?.map((item) => (
              <Option key={item.label} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
          <label>Main Account</label>

        </div>
        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            id="from"
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}


            onChange={(val) => {
              setState({ ...state, subAccount: subAccount?.find(x => x.label === val).value });
            }}
          >
            {subAccount?.map((item) => (
              <Option key={item.label} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
          <label>Sub Account</label>

        </div>
        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}
            onChange={(val) => {
              setState({ ...state, method: val });
            }}
          >
            {['Online', 'Normal']?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <label>Methods</label>

        </div>
        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}
            onChange={(val) => {
              setState({ ...state, activeStatus: val });
            }}
          >
            {['active', 'Discontinued', 'Suspended', 'Dismissed', 'Long Leave', 'TC Issue', 'College Transfer', 'Irregular']?.map((item) => (
              <Option key={item} value={item}>
                {item.toUpperCase()}
              </Option>
            ))}
          </Select>
          <label>Active Status</label>

        </div>
        <Button className={classNames.reloadButton} onClick={() => refetch()}>
          View
        </Button>
        <ReactToPrint
          trigger={() => <button className={classNames.reloadButton}>Print</button>}
          content={() => componentRef.current}
        />
        <Button className={classNames.reloadButton} onClick={() => { refetch() }}>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="emp"
            filename="collectionReport"
            sheet="tablexls"
            buttonText="EXCEL"
          />
        </Button>
        {loading ? <LoadingAnimation dark /> :
          <Button className={classNames.reloadButton} onClick={() => { onDateCollectionReport() }}>
            PDF
          </Button>}
      </div>


      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};
