import React, { useContext, useRef } from 'react';
import Button from '../../NecttosComp/Button/Button';
import { Main } from '../../NecttosComp/Layout/Layout';
import { NumInWords } from '../../pages/college/Accounts/FeesBilling/components/BillNumberModal/PrintReceipt';
import { FirebaseContext } from '../../context/FirebaseContext';

const Voucher = ({ data, onClose }) => {
	const componentRef = useRef();
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const tableContent2 = 'px-3 py-0 bg-none border-1 h-12 whitespace-wrap text-left fs-6  text-black';
	console.log({ data });

	return (
		<Main height='95%' width='80%'>
			<div className='z-100 p-4'>
				<div className='flex justify-end space-x-2 mb-4'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
					<Button type='print' tableRef={componentRef.current}>
						Print
					</Button>
				</div>
				<div ref={componentRef} id='printable' className='printable relative'>
					<div className='bg-white p-6 shadow-lg rounded-lg w-full h-[150mm] border border-gray-300 relative overflow-hidden'>
						<div className='flex justify-between items-center mb-4'>
							<div className='flex items-center'>
								{selectedCollege?.logo && <img src={selectedCollege.logo} alt='College Logo' className='max-w-[80px] mr-4' />}
								<div>
									<h3 className='text-xl font-bold'>{selectedCollege?.collegeName}</h3>
									<p className='text-sm'>{selectedCollege?.address}</p>
								</div>
							</div>
							<div className='text-right'>
								<p className='text-sm font-bold'>Date: 2024-06-20</p>
								<p className='text-sm font-bold'>Vr. No.: {data.rtNumber || '_._._._._._'}</p>
							</div>
						</div>

						{selectedCollege?.logo && (
							<div className='absolute inset-0 flex justify-center items-center opacity-10'>
								<img src={selectedCollege.logo} alt='College Logo' className='max-w-[300px]' />
							</div>
						)}

						<h3 className='text-xl font-bold self-center text-center underline'>VOUCHER</h3>

						<div className='relative z-10'>
							<table className='min-w-full m-2'>
								<tbody>
									<tr>
										<td className={tableContent2} style={{ width: '20%', fontFamily: 'Times New Roman' }}>
											<p className='fs-4 p-0 m-0 '>Paid to:</p>
										</td>
										<td className={tableContent2} style={{ width: '80%', fontFamily: 'Times New Roman' }}>
											<p className='fs-3 p-0 m-0 font-bold'>{data?.paidTo?.toUpperCase() || 'Guest Person'}</p>
										</td>
									</tr>
								</tbody>
							</table>
							<table className='min-w-full  m-2'>
								<tbody>
									<tr>
										<td className={tableContent2} style={{ width: '20%', fontSize: '12px', fontFamily: 'Times New Roman' }}>
											<p className='fs-4 p-0 m-0 '>Amount:</p>
										</td>
										<td className={tableContent2} style={{ width: '80%', fontSize: '16px', fontFamily: 'Times New Roman' }}>
											<p className='fs-3 p-0 m-0 font-bold'> Rs.{data?.amount}/-</p>
										</td>
									</tr>
								</tbody>
							</table>
							<table className='min-w-full  m-2'>
								<tbody>
									<tr>
										<td className={tableContent2} style={{ width: '100%', fontSize: '12px', fontFamily: 'Times New Roman' }}>
											Amount in words : ({NumInWords(data?.amount)})
										</td>
									</tr>
								</tbody>
							</table>
							<table className='min-w-full  m-2'>
								<tbody>
									<tr>
										<td className={tableContent2} style={{ width: '5%', backgroundColor: '#c2c2c2', fontSize: '12px', fontFamily: 'Times New Roman' }}>
											From:
										</td>
										<td className={tableContent2} style={{ width: '45%', fontSize: '12px', fontFamily: 'Times New Roman' }}>
											{data?.accountName}
										</td>
										<td className={tableContent2} style={{ width: '5%', backgroundColor: '#c2c2c2', fontSize: '16px', fontFamily: 'Times New Roman' }}>
											To:
										</td>
										<td className={tableContent2} style={{ width: '45%', fontSize: '16px', fontFamily: 'Times New Roman' }}>
											{data?.wereName}
										</td>
									</tr>
								</tbody>
							</table>

							<p className='fs-6 px-3'>Narration: {data?.naration.toUpperCase()}</p>
							<table className='min-w-full'>
								<tbody>
									<tr>
										<td className={tableContent2} style={{ width: '20%', fontSize: '12px', fontFamily: 'Times New Roman' }}>
											Prepared By
										</td>

										<td className={tableContent2} style={{ width: '20%', fontSize: '16px', fontFamily: 'Times New Roman' }}>
											Checked By
										</td>
										<td className={tableContent2} style={{ width: '20%', fontSize: '16px', fontFamily: 'Times New Roman' }}>
											Passed By
										</td>
										<td className={tableContent2} style={{ width: '40%', fontSize: '16px', fontFamily: 'Times New Roman' }}>
											Signature of the Payee
										</td>
									</tr>
								</tbody>
							</table>
							<table className='min-w-full  '>
								<tbody>
									<tr>
										<td className={tableContent2} style={{ width: '20%', fontSize: '12px', fontFamily: 'Times New Roman' }}>
											{data?.name}
										</td>
										<td className={tableContent2} style={{ width: '20%', fontSize: '16px', fontFamily: 'Times New Roman' }}></td>
										<td className={tableContent2} style={{ width: '20%', fontSize: '16px', fontFamily: 'Times New Roman' }}>
											Principal
										</td>
										<td className={tableContent2} style={{ width: '40%', fontSize: '16px', fontFamily: 'Times New Roman' }}></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Main>
	);
};

export default Voucher;
