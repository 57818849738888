import React, { useState } from "react";
import classNames from "./feesCategories.module.scss";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import Input from "../../../../../../NecttosComp/Input/Input";
import Button from "../../../../../../NecttosComp/Button/Button";

export default function FeesCategories({ options, feeAmounts, setFeeAmounts, feeReciept, setFeeReciept }) {
  const [feee, setFeee] = useState();
  const updateFees = async () => {
    let newFeeCopy = feee || 100000;
    if (newFeeCopy > 0) {
      for (const element of options) {
        if (element.key !== "Others") {
          if (element.currentDue < newFeeCopy) {
            await setFeeAmounts((prev) => ({ ...prev, [element.key]: element.currentDue }));
            newFeeCopy -= element.currentDue;
            setFeee(newFeeCopy);
          } else {
            await setFeeAmounts((prev) => ({ ...prev, [element.key]: newFeeCopy }));
            newFeeCopy = 0;
            setFeee(newFeeCopy);
          }
        }
      }
    }
    if (newFeeCopy > 0) {
      for (const element of options) {
        if (element.key === "Others") {
          for (const subElement of element?.dues || []) {
            if (subElement.currentDue < newFeeCopy) {
              await setFeeAmounts((prev) => ({ ...prev, [subElement.key]: subElement.currentDue }));
              newFeeCopy -= subElement.currentDue;
              setFeee(newFeeCopy);
            } else {
              await setFeeAmounts((prev) => ({ ...prev, [subElement.key]: newFeeCopy }));
              newFeeCopy = 0;
              setFeee(newFeeCopy);
            }
          }
        }
      }
    }
    setFeee(0);
  };
  //   const clreaFee = async () => {
  //     setFeeAmounts({});
  //   };
  return (
    <div className={classNames.feeCategories}>
      <Formik enableReinitialize initialValues={{}}>
        {({ resetForm }) => (
          <Form className={classNames.feeForm}>
            <div className="flex">
              <Input width="100%" fieldName={`Enter Full`} state={feee} setState={setFeee} type="number" />
              <Button
                width="100%"
                type="save"
                onClick={() => {
                  updateFees();
                }}
              >
                Load Fee
              </Button>
              {/* <Button
                width="100%"
                type="close"
                onClick={() => {
                    clreaFee();
                }}
              >
                Clear Fee
              </Button> */}
            </div>
            {options
              ?.filter((x) => x.key !== "Others")
              ?.map(
                (x) =>
                  x?.futureDue + x?.currentDue > 0 && (
                    <div className={`${classNames.group} ${classNames.formFullWidth}`}>
                      <Field
                        type="text"
                        name={x.key}
                        placeholder={x?.currentDue}
                        style={{ color: "green" }}
                        value={feeAmounts[x.key]}
                        id={x.key}
                        onChange={async (e) => {
                          await setFeeAmounts({ ...feeAmounts, [x.key]: e.target.value });
                        }}
                      />
                      <Field
                        type="text"
                        name={x.key}
                        placeholder={"Rt.No:" + x?.recieptNo}
                        style={{ color: "red", fontSize: 20 }}
                        value={feeReciept[x.key]}
                        id={x.key}
                        onChange={(e) => {
                          setFeeReciept({ ...feeReciept, [x.key]: e.target.value });
                        }}
                      />
                      <label>{x.name}</label>
                    </div>
                  )
              )}
            {options
              ?.find((x) => x.key === "Others")
              ?.dues?.map(
                (x) =>
                  moment(new Date()).diff(moment(x.dueDate)) >= 0 && (
                    <div className={`${classNames.group} ${classNames.formFullWidth}`}>
                      <Field
                        type="text"
                        name={x.key}
                        style={{ color: "green" }}
                        placeholder={x?.currentDue}
                        placeholderStyle={{ color: "green" }}
                        value={feeAmounts[x.key]}
                        id={x.key}
                        onChange={async (e) => {
                          await setFeeAmounts({ ...feeAmounts, [x.key]: e.target.value });
                        }}
                      />
                      <Field
                        type="text"
                        name={x.key}
                        style={{ color: "red", fontSize: 20 }}
                        placeholder={"Rt.No:" + x?.recieptNo}
                        value={feeReciept[x.key]}
                        id={x.key}
                        onChange={(e) => {
                          setFeeReciept({ ...feeReciept, [x.key]: e.target.value });
                        }}
                      />
                      <label>{x.key}</label>
                    </div>
                  )
              )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
