import React, { useContext, useRef, useState } from "react";
import classNames from "./addFeesForm.module.scss";
import Select from "antd/lib/select";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { Form, Formik } from "formik";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { ArrowRightOutlined } from "@ant-design/icons";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useAccountsHeads, useAllClasses } from "../../../../queryHooks";
import { Button } from "antd";
import CustomDropDownKey from "../../../../myComp/CustomDropDownKey";
import CustomInput from "../../../../myComp/CustomInput";

const getUserList = async ({ queryKey }) => {
  const [_, { type, collegeId, classId }] = queryKey;
  try {
    const instance = await getAxiosTokenInstance();
    const resp = await instance.get(`college/users/get?collegeId=${collegeId}${type === "all" ? "" : `&type=${type}`}${classId ? `&classId=${classId}` : ""}`);
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data;
  } catch (err) {
    throw new Error(err || "API Error");
  }
};

const { Option } = Select;

const feesTypess = [
  { name: "Tution Fees", key: "TuitionFee" },
  { name: "Examination Fees", key: "ExamFee" },
  { name: "Other Fees", key: "Others" },
];

function InstanceFeeCollection({ onClose }) {
  const ref = useRef();

  const { collegeId } = useContext(FirebaseContext);

  const { data: userList } = useQuery([`userListstudent`, { type: "student", classId: "", collegeId }], getUserList);

  const allClasses = useAllClasses({ needAll: false, classOnly: false });

  const classList = allClasses?.data || [];
  const accounts = useAccountsHeads();

  const mapFeeAcounts = accounts?.data?.subAccounts || [];

  const [isLoading, setIsLoading] = useState(false);
  const [tutionFee, setTutionFee] = useState(false);

  const [state, setState] = useState({
    feesType: "",
    titleOfFee: "",
    description: "",
    dueId: "",
    isStudent: true,
    payee: "",
    amount: "",
    dueDate: "",
    page: 1,
    date: "",
    recieptNo: "",
  });

  const onAddFees = async (data, actions) => {
    try {
      setIsLoading(true);
      const postData = state;
      const instance = await getAxiosTokenInstance();
      let params = { collegeId };
      if (state.isStudent) {
        params["userId"] = state.payee._id;
      } else {
        params["classId"] = state.payee._id;
      }
      let resp = await instance.post("/college/fees/addDueNew", postData, { params });

      if (resp?.status !== 202) {
        throw new Error(resp?.data?.message || "API Error");
      }
      message.success(data?.message || "Fees Added");
      setState({
        feesType: "",
        titleOfFee: "",
        description: "",
        dueId: "",
        isStudent: true,
        payee: "",
        amount: "",
        dueDate: "",
        date: "",
        recieptNo: "",
      });
      onClose();
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message || "API Error");
    } finally {
      setIsLoading(false);
    }
  };

  let collectFees = Array(parseInt(state?.page) || 1).fill(1);

  let totalAmount = 0;
  collectFees.forEach((item, i) => {
    totalAmount += parseInt(state[1 + i + "amount"]);
  });

  return (
    <div className={classNames.container}>
      <div className={classNames.modal}>
        <div className={classNames.addFeeForm}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {tutionFee && <button onClick={() => setTutionFee(false)}>Back</button>}
            <div className={classNames.title}>ADD NEW</div>
          </div>

          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values, formikActions) => {
              onAddFees(values, formikActions);
            }}
          >
            {({ resetForm }) => (
              <Form ref={ref} className={classNames.feeForm}>
                <div className={classNames.radioGroup}>
                  <div
                    className={classNames.radioItem}
                    onClick={() => {
                      setState({ ...state, isClass: false, isStudent: true, isNew: false, isEDL: false });
                    }}
                  >
                    <div className={classNames.radioCircle}>{!state.isClass && !state.isNew && !state.isEDL && <div className={classNames.radioInCircle}></div>}</div>
                    <span>Student</span>
                  </div>

                  <div
                    className={classNames.radioItem}
                    onClick={() => {
                      setState({ ...state, isClass: true, isStudent: false, isNew: false, isEDL: false });
                    }}
                  >
                    <div className={classNames.radioCircle}>{!state.isStudent && !state.isNew && !state.isEDL && <div className={classNames.radioInCircle}></div>}</div>
                    <span>Class</span>
                  </div>

                  <div
                    className={classNames.radioItem}
                    onClick={() => {
                      setState({ ...state, isClass: false, isStudent: false, isNew: true, isEDL: false });
                    }}
                  >
                    <div className={classNames.radioCircle}>{!state.isClass && !state.isStudent && !state.isEDL && <div className={classNames.radioInCircle}></div>}</div>
                    <span>Create A Person</span>
                  </div>

                  <div
                    className={classNames.radioItem}
                    onClick={() => {
                      setState({ ...state, isEDL: true, isClass: false, isStudent: false, isNew: false });
                    }}
                  >
                    <div className={classNames.radioCircle}>{!state.isClass && !state.isStudent && !state.isNew && <div className={classNames.radioInCircle}></div>}</div>
                    <span>EDL</span>
                  </div>
                </div>
                {state.isStudent && (
                  <Select
                    showSearch
                    value={state.payee?.name && state.payee?.name + " [" + state?.payee?.admisionNo + "]"}
                    id="payee"
                    className={classNames.formFullWidth}
                    style={{ overflow: "hidden" }}
                    placeholder="Select student to pay this fees?"
                    onChange={(val) => {
                      setState({ ...state, payee: userList?.list?.find((x) => x.name + " [" + x.admisionNo + "]" === val) });
                    }}
                  >
                    {userList?.list?.map((item) => (
                      <Option key={item._id} value={item.name + " [" + item.admisionNo + "]"}>
                        {item.name + " [" + item.admisionNo + "]"}
                      </Option>
                    ))}
                  </Select>
                )}

                {state.isClass && (
                  <Select
                    showSearch
                    value={state.payee?.className}
                    id="payee"
                    className={classNames.formFullWidth}
                    style={{ overflow: "hidden" }}
                    placeholder="Select class to pay this fees?"
                    onChange={(val) => {
                      setState({ ...state, payee: classList?.find((x) => x.className === val) });
                    }}
                  >
                    <label>Select class to pay this fees?</label>
                    {classList?.map((item) => (
                      <Option key={item?.className} value={item?.className}>
                        {item.className}
                      </Option>
                    ))}
                  </Select>
                )}

                {state.isNew && (
                  <>
                    <CustomInput keyData={"name"} titleData={"Name of the Student"} typeData={"text"} stateData={state} setStateData={setState} />
                    <CustomInput keyData={"phoneNumber"} titleData={"Mobile Number"} typeData={"number"} stateData={state} setStateData={setState} />
                    <CustomInput keyData={"admisionNo"} titleData={"Admission Number"} typeData={"text"} stateData={state} setStateData={setState} />

                    <Select
                      showSearch
                      value={state.payee?.className}
                      id="payee"
                      className={classNames.formFullWidth}
                      style={{ overflow: "hidden" }}
                      placeholder="Select Class"
                      onChange={(val) => {
                        setState({ ...state, payee: classList?.find((x) => x.className === val) });
                      }}
                    >
                      <label>Select class to pay this fees?</label>
                      {classList?.map((item) => (
                        <Option key={item?.className} value={item?.className}>
                          {item.className}
                        </Option>
                      ))}
                    </Select>
                  </>
                )}

                {state.isEDL && (
                  <>
                    <CustomInput keyData={"name"} titleData={"Name of the Bill"} typeData={"text"} stateData={state} setStateData={setState} />
                  </>
                )}

                <view style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <view style={{ width: 350 }}>
                    <CustomDropDownKey keyData={"feesType"} keyObject={"name"} keyReturn={"key"} titleData={"Select Fees Type"} dropValues={feesTypess} stateData={state} setStateData={setState} />
                  </view>
                  <CustomInput keyData={"date"} titleData={"Due Date"} typeData={"date"} stateData={state} setStateData={setState} />
                  <CustomInput keyData={"recieptNo"} titleData={"Reciept No."} typeData={"number"} stateData={state} setStateData={setState} />
                </view>

                {collectFees.map((x, i) => (
                  <view style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <view style={{ width: 550 }}>
                      <CustomDropDownKey keyData={i + 1 + "dueId"} keyObject={"label"} keyReturn={"value"} titleData={"Select Account " + (i + 1)} dropValues={mapFeeAcounts} stateData={state} setStateData={setState} />
                    </view>
                    <CustomInput keyData={i + 1 + "amount"} titleData={"Amount " + (i + 1)} typeData={"number"} stateData={state} setStateData={setState} />
                  </view>
                ))}

                <Button
                  className={classNames.closeButton}
                  disabled={parseInt(state.page) < 1}
                  onClick={() => {
                    setState({ ...state, page: parseInt(state.page) - 1 });
                  }}
                >
                  -
                </Button>

                <Button
                  className={classNames.closeButton}
                  disabled={parseInt(state.page) > 20}
                  onClick={() => {
                    setState({ ...state, page: parseInt(state.page) + 1 });
                  }}
                >
                  +
                </Button>
                <>
                  <div className={classNames.btns}>
                    <div
                      className={classNames.btnClear}
                      onClick={() => {
                        setState({
                          ...state,
                          feesType: "",
                          feesTypeval: "",
                          dueId: "",
                          dueIdval: "",
                          description: "",
                          payee: "",
                          amount: "",
                          dueDate: "",
                          date: "",
                          recieptNo: "",
                        });
                        resetForm();
                      }}
                    >
                      Clear
                    </div>

                    <div
                      className={classNames.radioItem}
                      onClick={() => {
                        setState({ ...state, isCollection: !state.isCollection });
                      }}
                    >
                      <div className={classNames.radioCircle}>{state.isCollection && <div className={classNames.radioInCircle}></div>}</div>
                      <span>Collect Fee</span>
                    </div>

                    <div className={classNames.formButtonWidth}>
                      <button type="primary" value="Submit">
                        Submit = {totalAmount}
                        <ArrowRightOutlined style={{ fontSize: 22, margin: "auto 0 auto 5px" }} />
                      </button>
                    </div>
                  </div>
                </>
              </Form>
            )}
          </Formik>

          {isLoading && (
            <div className={classNames.loading}>
              <LoadingAnimation />
            </div>
          )}
        </div>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
}
export default InstanceFeeCollection;
