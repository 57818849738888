import React, { useContext, useRef } from "react";
import classNames from "./exportModal.module.scss";
import { Button, message, Select } from "antd";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import Scrollbars from "react-custom-scrollbars";

import CustomInput from "../../../../../myComp/CustomInput";


export const EditRecieptNo = ({ item, onClose, setStateData, isBillDelete, stateData, refetchDemo }) => {




  console.log({ isBillDelete });

  const [state, setState] = React.useState({
    filterValue: '',
    page: 0,
  });

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  console.log('===>', { item });

  const editRecieptNumber = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/action/editBillNo", {
        collegeId,
        feeId: item?.feeId,
        billNo: state?.billNo,
        amount: state?.amount,
        oldAmount: item?.amount,
        accountId: item?.accountId,
        dueId: item?.dueId,
        isBillDelete,
        _id: item?._id,
        keyType: item?.keyType
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setState({
          ...state,
          feeId: '',
          billNo: '',
        })
        refetchDemo()
        setStateData({ ...stateData, openModal: false });
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };



  let issuedData = {}

  const { Option } = Select;

  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
        <div className={classNames.listContainer2}>

          <Scrollbars style={{ height: '100%' }} >
            <div ref={componentRef} style={{ overflow: 'scroll' }}>
              <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                  <img width='8%' height='8%' src={selectedCollege?.logo} />
                  <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>

                    <h5 style={{ marginTop: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Edit Reciept Number</h5>
                    <h5 style={{ marginTop: -10, fontSize: '20px', width: '100%', alignSelf: 'center' }}> {item.userName}</h5>
                    <h5 style={{ marginTop: -10, fontSize: '20px', width: '100%', alignSelf: 'center' }}>AdmisionNo :  {item.admisionNo}</h5>
                    <h5 style={{ marginTop: -10, fontSize: '20px', width: '100%', alignSelf: 'center' }}>Bill Amount : {item.totalAmount}</h5>
                    <h5 style={{ marginTop: -10, fontSize: '20px', width: '100%', alignSelf: 'center' }}> Bill Date : {item.date}</h5>




                  </div>
                  {isBillDelete ?
                    <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                      <Button className={classNames.submitBotton2} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button className={classNames.submitBotton} onClick={() => { editRecieptNumber() }}>
                        Delete
                      </Button>
                    </div>
                    :
                    <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                      {item.keyType === 'amount' ?
                        <>
                          <h5 style={{ marginTop: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}> Bill No: {item?.billNo}</h5>
                          <h5 style={{ marginTop: 0, fontSize: '20px', width: '100%', alignSelf: 'center' }}>Old {item?.keyData} Amt: {item?.amount}</h5>
                          < CustomInput
                            keyData={'amount'}
                            titleData={`New ${item?.keyData} Amount`}
                            typeData={'number'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </>
                        :
                        <>
                          <h5 style={{ marginTop: 0, fontSize: '40px', width: '100%', alignSelf: 'center' }}>Old Amount: {item?.amount}</h5>
                          < CustomInput
                            keyData={'billNo'}
                            titleData={'New Reciept No'}
                            typeData={'number'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </>
                      }
                      <Button className={classNames.submitBotton2} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button className={classNames.submitBotton} onClick={() => { editRecieptNumber() }}>
                        Submit
                      </Button>
                    </div>}
                </div>



              </div>
            </div>
          </Scrollbars>
        </div >

      </div>


    </div >
  );
};
