import React, { useEffect, useState, useMemo, ReactNode, CSSProperties, useRef } from 'react';
import './table.css';

let gWidth: any = [];

function isScrollable(div: any) {
	return div?.scrollHeight > div?.clientHeight;
}

const adjustWidth = (originalWidth: string, adjustment: number, merge?: number): string => {
	const numericWidth = parseFloat(originalWidth);
	if (isNaN(numericWidth)) {
		console.error(`Invalid originalWidth: "${originalWidth}"`);
		return '100%';
	}
	const adjustedWidth = numericWidth + (merge ? adjustment * merge : adjustment);
	return `${adjustedWidth}%`;
};

interface TableProps {
	innerWidth?: string[];
	width: string;
	id?: string;
	children: ReactNode;
}

export const Table = React.forwardRef<HTMLTableElement, TableProps>(({ innerWidth, width, id, children }, ref) => {
	gWidth = innerWidth;
	const tableStyle: CSSProperties = {
		width: width,
		display: 'table',
		color: 'white',
		border: 'none',
	};
	return (
		<table ref={ref} style={tableStyle} id={id}>
			{children}
		</table>
	);
});

interface TheadProps {
	children: ReactNode;
	width?: string;
}

export const Thead = ({ children, width }: TheadProps) => {
	const theadStyle: CSSProperties = {
		border: 'none',
		backgroundColor: '#495057',
		display: 'table',
		width: width || '100%',
	};

	return <thead style={theadStyle}>{children}</thead>;
};

interface ThProps {
	children: ReactNode;
	width?: string;
	position?: number;
	onClick?: () => void;
	icon?: 'ascending' | 'descending';
	item?: ReactNode;
	fontSize?: string;
	textAlign?: any;
	merge?: number;
	padding?: string;
}

export const Th = ({ children, width, textAlign, position, onClick, icon, item, merge, fontSize, padding }: ThProps) => {
	const [colSpan, setColSpan] = useState<string | null>(null);
	useEffect(() => {
		if (merge) {
			let val = 0;
			if (position) {
				for (let i = position; i < merge; i++) {
					const number = parseInt(gWidth[i]);
					console.log(number);

					if (!isNaN(number)) {
						val += number;
					} else {
						console.warn(`Invalid value at gWidth[${i}]: "${gWidth[i]}"`);
					}
				}
			}
			setColSpan(`${val}%`);
		}
	}, [merge, position]);
	const thStyle: CSSProperties = {
		position: 'sticky',
		top: 0,
		textAlign: textAlign || 'left',
		width: width || colSpan || gWidth?.[position || 0] || 'auto',
		padding: padding || '0.5vw',
		backgroundColor: '#495057',
		color: 'white',
		border: 'none',
		borderRight: '1px solid white',
		fontSize: fontSize ? fontSize : '12px',
		fontWeight: '900',
	};
	return (
		<th onClick={onClick} style={thStyle}>
			{item ? item : children} {icon && (icon === 'ascending' ? '🔼' : '🔽')}
		</th>
	);
};

interface TbodyProps {
	children: ReactNode;
	height: string;
}

export const Tbody = ({ children, height }: TbodyProps) => {
	const tbodyStyle: CSSProperties = {
		border: 'none',
		display: 'block',
		maxHeight: height,
		overflowY: 'auto',
	};

	return (
		<tbody id='scrollBarEnabletable ' style={tbodyStyle}>
			{children}
		</tbody>
	);
};

interface TrProps {
	children: ReactNode;
	index?: number;
	onClick?: () => void;
	height?: string;
}

export const Tr = ({ children, index, onClick, height }: TrProps) => {
	const trStyle: CSSProperties = {
		border: 'none',
		display: 'table',
		borderBottom: '1px solid white',
		width: '100%',
		cursor: onClick ? 'pointer' : 'default',
		height,
	};

	return (
		<tr style={trStyle} onClick={onClick}>
			{children}
		</tr>
	);
};

interface TdProps {
	children: ReactNode;
	width?: string;
	index: number;
	textAlign?: any;
	position?: number;
	item?: ReactNode;
	fontSize?: string;
	padding?: string;
	highlight?: string;
	color?: string;
	onClick?: () => void;
}

export const Td = ({ children, width, index, textAlign, position, item, onClick, color, fontSize, padding, highlight }: TdProps) => {
	const tdStyle1: CSSProperties = {
		padding: padding || '0.5vw',
		fontWeight: '500',
		fontSize: fontSize ? fontSize : '12px',
		textAlign: textAlign || 'left',
		width: width || gWidth?.[position || 0],
		border: 'none',
		borderRight: '1px solid white',
		backgroundColor: index % 2 === 0 ? '#F8F9FA' : '#E9ECEF',

		...(highlight ? { backgroundColor: highlight } : {}),
		color: color || 'black',
		overflowWrap: 'break-word',
	};

	return (
		<td className='td-hover-highlight' onClick={onClick} style={tdStyle1}>
			{item ? item : children}
		</td>
	);
};

export const Tfoot = ({ children }: { children: ReactNode }) => {
	const tfootStyle: CSSProperties = {
		border: 'none',
		backgroundColor: '#6C757D',
		display: 'table',
		width: '100%',
	};

	return <tfoot style={tfootStyle}>{children}</tfoot>;
};

export const TfootTr = ({ children }: { children: ReactNode }) => {
	const tfootTrStyle: CSSProperties = {
		border: 'none',
		position: 'sticky',
		bottom: 0,

		backgroundColor: '#6C757D',
	};

	return <tr style={tfootTrStyle}>{children}</tr>;
};

interface TfootTdProps {
	children: ReactNode;
	width?: string;
	position?: number;
	merge?: number;
	item?: ReactNode;
	textAlign?: string;
}

export const TfootTd = ({ children, width, textAlign, position = 1, merge, item }: TfootTdProps) => {
	const [colSpan, setColSpan] = useState<string | null>(null);
	useEffect(() => {
		if (merge) {
			let val = 0;
			for (let i = position; i < merge; i++) {
				const number = parseInt(gWidth[i]);
				console.log(number);

				if (!isNaN(number)) {
					val += number;
				} else {
					console.warn(`Invalid value at gWidth[${i}]: "${gWidth[i]}"`);
				}
			}
			setColSpan(`${val}%`);
		}
	}, [merge, position]);

	const tfootTdStyle: CSSProperties = {
		border: 'none',
		borderRight: '1px solid white',
		padding: '0.5vw',
		width: width || colSpan || gWidth[position],
		backgroundColor: '#6C757D',
		fontSize: '1vw',
		textAlign: 'right',
		fontWeight: 'bold',
	};
	return <td style={tfootTdStyle}>{item ? item : children}</td>;
};

interface SortableDataConfig {
	key: string;
	direction: 'ascending' | 'descending';
}

export const useSortableData = (items: any[], config: SortableDataConfig = { key: '', direction: 'ascending' }) => {
	const [sortConfig, setSortConfig] = useState<SortableDataConfig>(config);

	const requestSort = (key: string) => {
		if (!key) return;

		let direction: 'ascending' | 'descending' = 'ascending';
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		} else {
			direction = 'ascending';
		}
		setSortConfig({ key, direction });
	};

	const getIcon = (key: string): 'ascending' | 'descending' | undefined => {
		if (!sortConfig || sortConfig.key !== key) return undefined;
		return sortConfig.direction;
	};

	const sortedItems = useMemo(() => {
		let sortableItems: any[] = Array.isArray(items) ? [...items] : [];

		if (sortConfig && sortConfig.key) {
			sortableItems.sort((a, b) => {
				const aValue = a[sortConfig.key];
				const bValue = b[sortConfig.key];

				// Check if both values are numbers
				if (!isNaN(Number(aValue)) && !isNaN(Number(bValue))) {
					// Numeric comparison
					return sortConfig.direction === 'ascending' ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
				}

				const strAValue = String(aValue);
				const strBValue = String(bValue);

				if (strAValue < strBValue) return sortConfig.direction === 'ascending' ? -1 : 1;
				if (strAValue > strBValue) return sortConfig.direction === 'ascending' ? 1 : -1;
				return 0;
			});
		}

		return sortableItems;
	}, [items, sortConfig]);

	return { tableItems: sortedItems, requestSort, getIcon };
};
